import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter, useMenu } from 'common/utils/hooks';
import { zIndex, shadows } from 'common/styles/mixins';
import logo from 'assets/common/logo-extendeal.svg';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import { MobileDrawer } from './mobile-drawer';
import { Menu } from 'components';
import { MenuProps } from './menu';
import { userNameSelector } from 'common/redux/global/selectors';
import * as GlobalActions from 'common/redux/global/actions';
import { AccountCircleOutline, HelpOutline } from 'assets/icons';

declare var process: {
  env: {
    [key: string]: string;
  };
};

export interface UserPanelAction {
  label: string;
  onClick(): void;
}

export interface UserPanelMenuProps extends Omit<MenuProps, 'children'> {
  actions: Array<UserPanelAction>;
}

export interface HeaderIconProps {
  color: string;
  SvgComponent: React.FC<React.SVGProps<SVGSVGElement>>;
  width: number;
  height: number;
}

const useStyles = makeStyles(({ palette }) => ({
  appBar: {
    backgroundColor: palette.B.A100,
    zIndex: zIndex.sidebar + 1,
    height: '48px',
    boxShadow: shadows[1]
  },
  userRole: {
    marginTop: '4px',
    fontSize: '16px',
    lineHeight: '22px',
    color: 'white'
  },
  toolbar: {
    paddingLeft: '16px',
    height: '48px',
    minHeight: '48px',
    paddingRight: 0
  },
  toolbarButtonsContainer: {
    marginLeft: 'auto'
  },
  logo: {
    width: '114px',
    height: '19px',
    cursor: 'pointer'
  }
}));

function MobileMenu() {
  const isMobile = useMediaQuery('(max-width:640px)');
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = () => setOpen(prev => !prev);

  if (!isMobile) {
    return null;
  }

  return (
    <>
      <IconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer} edge="start">
        <MenuIcon />
      </IconButton>
      <MobileDrawer {...{ open }} onOpen={toggleDrawer} onClose={toggleDrawer} />
    </>
  );
}

function UserPanelMenu({ actions, ...props }: UserPanelMenuProps) {
  const userName = useSelector(userNameSelector);

  return (
    <Menu {...props}>
      <Menu.Item>
        <b>{userName}</b>
      </Menu.Item>
      <Menu.Divider />
      {actions.map(action => (
        <Menu.Item key={action.label} onClick={action.onClick}>
          {action.label}
        </Menu.Item>
      ))}
    </Menu>
  );
}

function HeaderIcon({ SvgComponent, color, width, height }: HeaderIconProps) {
  return <SvgComponent fill={color} {...{ width, height }} />;
}

export function Header() {
  const classes = useStyles();
  const { push } = useRouter();
  const profileMenu = useMenu();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleLogoClick = () => push('/');

  const onOpenChangePasswordModal = () => {
    dispatch(GlobalActions.toggleChangePasswordModal());
    profileMenu.handleClose();
  };

  const signOut = () => dispatch(GlobalActions.userSignout());

  return (
    <AppBar className={classes.appBar} position="absolute">
      <Toolbar className={classes.toolbar}>
        <MobileMenu />
        <img className={classes.logo} src={logo} onClick={handleLogoClick} />
        <div className={classes.toolbarButtonsContainer}>
          <IconButton
            target="_blank"
            href={process.env.REACT_APP_PORTAL_SUPPORT_URL}
            color="inherit"
          >
            <HeaderIcon color="white" SvgComponent={HelpOutline} width={24} height={24} />
          </IconButton>
          <IconButton title={t('shared:user_panel')} onClick={profileMenu.handleMenu}>
            <HeaderIcon
              color="white"
              SvgComponent={AccountCircleOutline}
              width={24}
              height={24}
            />
          </IconButton>
        </div>
        <UserPanelMenu
          anchorEl={profileMenu.anchorEl}
          open={profileMenu.open}
          onClose={profileMenu.handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          getContentAnchorEl={null}
          style={{ top: 15 }}
          actions={[
            { label: t('shared:change_password'), onClick: onOpenChangePasswordModal },
            { label: t('shared:actions.exit'), onClick: signOut }
          ]}
        />
      </Toolbar>
    </AppBar>
  );
}
