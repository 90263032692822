import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface SnackbarMessage {
  message: string;
  error?: boolean;
}

type SnackbarState = {
  visible: boolean;
} & SnackbarMessage;

const initialState: SnackbarState = {
  visible: false,
  message: '',
  error: false
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSnackbar: (state, { payload }: PayloadAction<SnackbarMessage>) => {
      const { message, error } = payload;
      state.visible = true;
      state.message = message;
      state.error = error;
    },
    hideSnackbar: state => {
      state.visible = false;
      state.message = '';
      state.error = false;
    }
  }
});

export const { hideSnackbar, showSnackbar } = snackbarSlice.actions;

export const { reducer: snackbarReducer } = snackbarSlice;
