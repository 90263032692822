import React from 'react';
import styled from "styled-components";
import { rem, rgba } from "polished";
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { Box } from 'components';

export interface Item {
  key: string;
  label: string;
}

export interface AlertContainerProps {
  title: string;
  items: Item[];
}

const Container = styled.div`
  display: flex;
  border: ${rem('1px')} solid ${props => props.theme.colors.G[900]};
  border-radius: ${rem('4px')};
  padding: ${rem('16px')};
  background-color: ${props => rgba(props.theme.colors.G[900], 0.15)};
  margin-top: ${rem('20px')};
`;

const ErrorOutlineIcon = styled(ErrorOutline)`
  display: flex;
  color: ${props => props.theme.colors.G[900]};
  font-size: ${rem('18px')};
`;

const AlertText = styled.span<{ bold?: boolean }>`
  display: inline-block;
  color: ${props => props.theme.colors.G[900]};
  font-weight: ${props => (props.bold ? '600' : '400')};
  white-space: pre-line;
  font-size: ${rem('14px')};
`;

export function AlertContainer({ title, items }: AlertContainerProps) {
  return (
    <Container>
      <Box marginTop="12px" marginRight="12px">
        <ErrorOutlineIcon />
      </Box>
      <AlertText>
        <AlertText>{title}</AlertText>
        {'\n'}
        <AlertText bold style={{ marginTop: '8px' }}>
          {items.map(({ key, label }) => (
            <>
              <AlertText bold key={key}>
                - {label}
              </AlertText>
              {'\n'}
            </>
          ))}
        </AlertText>
      </AlertText>
    </Container>
  );
}
