import { call, put, takeEvery, select, all } from 'redux-saga/effects';
import get from 'lodash/get';
import i18n from 'i18next';
import { PO_STATUS_CONFIRMED, PO_STATUS_REJECTED } from 'common/utils/constants';
import * as Selectors from 'common/redux/global/selectors';
import * as Actions from '../actions';
import {
  getPurchaseOrder,
  getDeliveryLogs,
  markAsRead,
  updateItemBonus,
  getModerationReasonsByStatus
} from './PurchaseOrderDetailAPI';
import { productItemsSelector } from '../selectors';
import { getApiDownloadUrl } from "../../../common/utils/axios";

function* getPurchaseOrderWorker(action) {
  try {
    const response = yield call(getPurchaseOrder, action.payload);
    const { data, meta } = response.data;
    const { token: emailReceiptToken, read: isMarkedAsRead } = data.read ?? {};
    const currentUserId = yield select(Selectors.userIdSelector);
    yield put(Actions.detailResponse({ data, meta }));
    if (emailReceiptToken && !isMarkedAsRead) {
      yield call(markAsRead, { token: emailReceiptToken, userId: currentUserId });
    }
  } catch (error) {
    console.log(error);
  }
}

function* downloadFileWorker(action) {
  try {
      window.location = yield call(getApiDownloadUrl, action.payload.id, action.payload.fileType);
  } catch (error) {
    yield put(Actions.downloadFileResponse(error));
  }
}

function* updateProductWorker(action) {
  try {
    const item = action.payload;
    const { product_id } = item;

    const actualProducts = yield select(productItemsSelector);
    const updatedProducts = actualProducts.map(product =>
      product.product_id === product_id ? { ...item } : { ...product }
    );

    yield put(Actions.updateProductSuccess(updatedProducts));
  } catch (error) {
    console.log(error);
  }
}

function* deliveryLogWorker(action) {
  try {
    const response = yield call(getDeliveryLogs, action.payload);
    if (response.data) {
      const { data } = response.data.data;
      const statuses = [PO_STATUS_CONFIRMED, PO_STATUS_REJECTED];
      const res = data.map(log => {
        const entityName = statuses.indexOf(log.status.id) < 0 ? `(${log.entity.name})` : '';
        const partnerName = get(log, 'delivery.partner.name');
        let name;

        switch (log.action_type) {
          case 'user':
            name = `${get(log, 'user.name')} ${entityName}`;
            break;
          case 'partner':
            name = `${get(log, 'user.name', partnerName)}`;
            break;
          case 'system':
            name = i18n.t('po-detail:tabs.activity.extendeal_process');
            break;
          default:
            break;
        }

        return {
          name,
          partnerName,
          ...log
        };
      });

      yield put(Actions.deliveryLogResponse(res));
    }
  } catch (error) {
    console.log(error);
  }
}

function* updateItemBonusRules(action) {
  try {
    const partnerId = yield select(Selectors.partnerIdSelector);
    const items = action.payload;
    const responses = yield all(
      items
        .filter(item => item.merchandiseBonus && item.merchandiseBonus.merchandiseBonusRules)
        .map(item => {
          const {
            partner_price_list_id: partnerPriceListId,
            id: merchandiseBonusId
          } = item.merchandiseBonus;
          const {
            id: merchandiseBonusRuleOrderItemId,
            bonus,
            merchandise_bonus_rule_id: merchandiseBonusRuleId
          } = item.merchandiseBonus.merchandiseBonusRules.new;

          return call(updateItemBonus, {
            merchandiseBonusRuleOrderItemId,
            bonus,
            merchandiseBonusId,
            merchandiseBonusRuleId,
            partnerPriceListId,
            partnerId
          });
        })
    );
  } catch (error) {
    console.log(error);
  }
}

function* getRejectModerationReasonsWorker(action) {
  try {
    const response = yield call(getModerationReasonsByStatus, action.payload);
    const { data, meta } = response.data;
    yield put(Actions.rejectModerationReasonsResponse({ data, meta }));
  } catch (error) {
    console.log(error);
  }
}

function* getModifiedConfirmedModerationReasonsWorker(action) {
  try {
    const response = yield call(getModerationReasonsByStatus, action.payload);
    const { data, meta } = response.data;
    yield put(Actions.modifiedConfirmedModerationReasonsResponse({ data, meta }));
  } catch (error) {
    console.log(error);
  }
}


function* purchaseOrderDetailSaga() {
  yield takeEvery(Actions.detailRequest.toString(), getPurchaseOrderWorker);
  yield takeEvery(Actions.downloadFileRequest.toString(), downloadFileWorker);
  yield takeEvery(Actions.updateProductRequest.toString(), updateProductWorker);
  yield takeEvery(Actions.deliveryLogRequest.toString(), deliveryLogWorker);
  yield takeEvery(Actions.updateItemBonus.toString(), updateItemBonusRules);
  yield takeEvery(Actions.rejectModerationReasonsRequest.toString(), getRejectModerationReasonsWorker);
  yield takeEvery(Actions.modifiedConfirmedModerationReasonsRequest.toString(), getModifiedConfirmedModerationReasonsWorker);
}

export default purchaseOrderDetailSaga;
