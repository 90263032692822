import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';
import { rem } from 'polished';
import { useDispatch, useSelector } from 'react-redux';
import {
  snackbarErrorSelector,
  snackbarMessageSelector,
  snackbarVisibleSelector
} from 'common/redux/global/selectors';
import { hideSnackbar } from 'common/redux/global/reducer/snackbarSlice';
import {
  Snackbar as MuiSnackbar,
  SnackbarProps as MuiSnackbarProps,
  Slide
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';

export const StyledSnackbar = styled(({ color, ...props }: MuiSnackbarProps) => (
  <MuiSnackbar {...props} ContentProps={{ classes: { root: 'paper', message: 'message' } }} />
))`
  & .paper {
    min-width: ${rem('504px')};
    background-color: ${props => props.color ?? props.theme.colors.B[900]};
    padding-right: ${rem('8px')};
    box-shadow: ${props => props.theme.shadows['2']};
  }

  & .message {
    font-size: ${rem('16px')};
  }

  @media screen AND (max-width:640px){
    & .paper {
        min-width: initial;
      }
  }
`;

function SlideTransition(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}

export function Snackbar() {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const visible = useSelector(snackbarVisibleSelector);
  const message = useSelector(snackbarMessageSelector);
  const error = useSelector(snackbarErrorSelector);

  const onClose = () => dispatch(hideSnackbar());

  return (
    <StyledSnackbar
      open={visible}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      TransitionComponent={SlideTransition}
      onClose={onClose}
      color={error ? theme.colors.Ma[400] : undefined}
      message={message}
      autoHideDuration={3000}
    />
  );
}
