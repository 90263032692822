export const findBy = (data = [], findKey, compareValue) =>
  data.find(d => d[findKey] === compareValue);

export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item
    };
  }, initialValue);
};
