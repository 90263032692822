import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Spinner } from 'components';
import { PoHeaderExtract } from './po-header-extract';

const Label = styled.span`
  color: ${props => props.theme.colors.S[800]};
  font-size: ${rem('16px')};
`;

export interface ConfirmModalProps {
  visible: boolean;
  success: boolean;
  onConfirm(): void;
  onClose(): void;
  extract: any;
  loading: boolean;
}

export function ConfirmModal({
  visible,
  onClose,
  onConfirm,
  extract,
  loading,
  success
}: ConfirmModalProps) {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);

  const SuccessContent = () => (
    <Label>{t('summary:modals.confirm.success.message', { po: extract[0].value })}</Label>
  );

  const SuccessActions = () => (
    <Button colorName={theme.colors.Mi} onClick={onClose}>
      {t('summary:modals.close_button')}
    </Button>
  );

  const MainContent = () => <PoHeaderExtract extract={extract} />;

  const MainActions = () => (
    <>
      {' '}
      <Button colorName={theme.colors.S[700]} onClick={onClose}>
        {t('summary:modals.cancel_button')}
      </Button>
      <Button colorName={theme.colors.Lu} onClick={onConfirm}>
        {t('summary:modals.confirm.button')}
      </Button>
    </>
  );

  return (
    <Modal
      fullWidth
      maxWidth={success ? 'sm' : 'md'}
      open={visible}
      onClose={onClose}
      loading={loading}
      LoadingComponent={<Spinner label={t('summary:modals.confirm.loading_message')} />}
    >
      <Modal.Title>{t('summary:modals.confirm.title')}</Modal.Title>
      <Modal.Content>{success ? <SuccessContent /> : <MainContent />}</Modal.Content>
      <Modal.Actions>{success ? <SuccessActions /> : <MainActions />}</Modal.Actions>
    </Modal>
  );
}
