import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { rem } from 'polished';
import { Breadcrumbs as MuiBreadcrumbs } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { emphasize, withStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

export interface LabelProps {
  to?: string;
  children: React.ReactNode;
  active?: boolean;
  select?:boolean;
  onclick?: any
  id?:any
}

export interface BreadcrumbsProps {
  children: React.ReactNode;
  Label?: React.FC<LabelProps>;
}

const StyledBreadcrumbSelect = withStyles((theme: Theme) => ({
  root: {
    padding:"0px",
    justifyContent: "end",
    backgroundColor: "white",
    height: "40px",
    width: "max-content",
    borderRadius: "4px",
    color: "#0B2033",
    font: "normal normal 600 20px/17px Source Sans Pro",
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
    "& .MuiChip-label":{
      height: "24px",
      position: "relative",
      top: "2px"
    }
  },
}))(Chip) as typeof Chip;

const StyledBreadcrumbs = styled(MuiBreadcrumbs)`
  position: relative;
  box-shadow: ${props => props.theme.shadows['1']};
  height: ${rem('72px')};
  z-index: 1000;
  padding: 0 ${rem('24px')};
  display: flex;
  align-items: center;

  .MuiBreadcrumbs-separator {
    margin: 0 ${rem('8px')};
  }
`;

const StyledSpan = styled.span<{ active: boolean }>`
  color: ${props => (props.active ? props.theme.colors.B['900'] : props.theme.colors.S['800'])};
  font-size: ${rem('20px')};
  font-weight: ${props => (props.active ? 400 : 200)};
`;

function Label({ to, children, active = false, select = false, onclick, id}: LabelProps) {
  if (to) {
    return (
      <Link to={to}>
        <StyledSpan active={active}>{children}</StyledSpan>
      </Link>
    );
  }
  if (select){
    return (
     <StyledBreadcrumbSelect 
      id={id} 
      label={children}
      deleteIcon={<ExpandMoreIcon/>}
      onClick={onclick}
      onDelete={onclick}
      />)
  }else{
    return <StyledSpan active={active}>{children}</StyledSpan>;
  }

}

export function Breadcrumbs({ children }: BreadcrumbsProps) {
    return <StyledBreadcrumbs >{children}</StyledBreadcrumbs>;
}

Breadcrumbs.Label = React.memo(Label);
