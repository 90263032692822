import { apiInstance, sciInstance } from 'common/utils/axios';

export const getUser = () => apiInstance.get('/users');

export const getEntity = () => sciInstance.get('/me/entity');

export const changePassword = request => sciInstance({
  method: 'PUT',
  url: '/user/change-password',
  data: request,
});
