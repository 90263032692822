import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Badge, BadgeProps } from '@material-ui/core';

export type NotificationStyleType = 'extended' | 'smaller' | 'square' | 'default';

export interface NotificationBadgeProps extends BadgeProps {
  type: NotificationStyleType;
  placement: 'default' | 'top-left';
  children: React.ReactNode;
}

const StyledBadge = styled(({ type, placement, ...props }: NotificationBadgeProps) => (
  <Badge {...props} classes={{ badge: 'badge' }} />
))`
  & .badge {
    background-color: ${props => props.theme.colors.G['800']};
    width: ${rem('32px')};
    height: ${rem('32px')};
    border-radius: ${rem('16px')};
    color: white;
    font-size: ${rem('16px')};

    ${props => {
      const fontSize = `font-size: ${rem('14px')}`;
      switch (props.type) {
        case 'extended':
          return css`
            width: ${rem('64px')};
            ${fontSize}
          `;
        case 'smaller':
          return css`
            width: ${rem('24px')};
            height: ${rem('24px')};
            font-size: ${rem('14px')};
            ${fontSize}
          `;
        case 'square':
          return css`
            width: ${rem('16px')};
            height: ${rem('18px')};
            border-radius: ${rem('3px')};
            font-size: ${rem('14px')};
            ${fontSize}
          `;
        default:
          return '';
      }
    }}

  ${props =>
    props.variant === 'dot' &&
    css`
      opacity: 0.85;
      width: ${rem('8px')};
      height: ${rem('8px')};
    `}

  ${props =>
    props.placement === 'top-left' &&
    css`
      top: 0;
      left: 0;
      transform: scale(1) translate(-50%, -50%);
    `}
  }
`;

export function NotificationBadge({ type, children, ...props }: NotificationBadgeProps) {
  return (
    <StyledBadge type={type} max={type === 'smaller' || type === 'square' ? 9 : 99} {...props}>
      {children}
    </StyledBadge>
  );
}

NotificationBadge.defaultProps = {
  placement: 'top-right',
  type: undefined
};
