import get from 'lodash/get';

export const authTokenSelector = state => state.global.token;
export const currentUserSelector = state => state.global.currentUser;
export const partnerIdSelector = state => get(currentUserSelector(state), 'id');
export const userNameSelector = state => get(currentUserSelector(state), 'user.name');
export const userEmailSelector = state => get(currentUserSelector(state), 'user.email');
export const roleIdSelector = state => get(currentUserSelector(state), 'user.role_id');
export const roleIdentifierSelector = state => get(currentUserSelector(state), 'user.role_identifier');
export const userIdSelector = state => get(currentUserSelector(state), 'user.id');
export const deliveryCountSelector = state => get(currentUserSelector(state), 'deliveryCount');
export const isLoggedInSelector = state => state.global.token !== undefined;
export const isChangingPasswordSelector = state => get(state.global.changePassword, 'isChanging');
export const finishedChangePasswordSelector = state => get(state.global.changePassword, 'finished');
export const errorChangePasswordSelector = state => get(state.global.changePassword, 'error');
export const visibleChangePasswordSelector = state => get(state.global.changePassword, 'visible');
export const snackbarMessageSelector = state => state.snackbar.message;
export const snackbarVisibleSelector = state => state.snackbar.visible;
export const snackbarErrorSelector = state => state.snackbar.error;
