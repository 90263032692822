import axios from 'axios';
import store from 'common/redux/store';
import * as Actions from 'common/redux/global/actions';

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST_URL
});

const sciInstance = axios.create({
  baseURL: process.env.REACT_APP_SCI_API_HOST_URL,
  withCredentials: true
});

apiInstance.interceptors.request.use(config => {
  const { token } = store.getState().global;
  config.headers.common.Authorization = `Bearer ${token}`;

  return config;
});

apiInstance.interceptors.response.use(undefined, error => {
  if (error.response.status === 401) {
    store.dispatch(Actions.userSignout());
  }
});

const apiFetcher = url => apiInstance.get(url).then(res => res.data);

function objectToFormData(object) {
  const formData = new FormData();
  Object.keys(object).forEach(key => formData.append(key, object[key]));
  return formData;
}

function getApiDownloadUrl(deliveryId, type) {
  const { token } = store.getState().global;

  return `${process.env.REACT_APP_API_HOST_URL}/deliveries/${deliveryId}/${type}--download?token=${token}`;
}

export { sciInstance, apiInstance, apiFetcher, objectToFormData, getApiDownloadUrl };
