import { createReducer } from '@reduxjs/toolkit';
import * as GlobalActions from 'common/redux/global/actions';
import * as Actions from '../actions';

const initialState = {
  isFetching: false,
  detail: {},
  productItems: [],
  isChangingStatus: false,
  statusChangeSuccess: false,
  deliveryLogs: [],
  snackbar: {
    visible: false,
    error: false
  },
  isEditing: false,
  rejectModerationReasons: [],
  modifiedConfirmedModerationReasons: [],
};

const purchaseOrderDetail = createReducer(initialState, {
  [Actions.detailRequest]: (state, _action) => {
    state.isFetching = true;
    state.detail = {};
    state.productItems = [];
  },
  [Actions.detailResponse]: (state, action) => {
    state.isFetching = false;
    state.detail = action.payload.data;
    state.productItems = action.payload.data.items;
  },
  [Actions.updateProductSuccess]: (state, action) => {
    state.productItems = action.payload
  },
  [GlobalActions.changePurchaseOrderStatusRequest]: (state, action) => {
    if (action.payload.context === 'purchase-order-detail') {
      state.isChangingStatus = true;
    }
  },
  [GlobalActions.changePurchaseOrderStatusResponse]: (state, action) => {
    if (action.payload.context === 'purchase-order-detail') {
      state.isChangingStatus = false;
      state.statusChangeSuccess = action.payload.success;
    }
  },
  [GlobalActions.confirmPurchaseOrderRequest]: (state, action) => {
    if (action.payload.context === 'purchase-order-detail') {
      state.isChangingStatus = true;
    }
  },
  [GlobalActions.confirmPurchaseOrderResponse]: (state, action) => {
    if (action.payload.context === 'purchase-order-detail') {
      state.isChangingStatus = false;
      state.statusChangeSuccess = action.payload.success;
    }
  },
  [GlobalActions.rejectPurchaseOrderRequest]: (state, action) => {
    if (action.payload.context === 'purchase-order-detail') {
      state.isChangingStatus = true;
    }
  },
  [GlobalActions.rejectPurchaseOrderResponse]: (state, action) => {
    if (action.payload.context === 'purchase-order-detail') {
      state.isChangingStatus = false;
      state.statusChangeSuccess = action.payload.success;
    }
  },
  [Actions.resetProductModifications]: (state, action) => {
    state.productItems = state.detail.items;
  },
  [Actions.resetModals]: (state, _action) => {
    state.statusChangeSuccess = false;
  },
  [Actions.deliveryLogRequest]: (state, _action) => {
    state.isFetchingLogs = true;
    state.deliveryLogs = [];
  },
  [Actions.deliveryLogResponse]: (state, action) => {
    state.isFetchingLogs = false;
    state.deliveryLogs = action.payload;
  },
  [Actions.downloadFileRequest]: (state, _action) => {
    state.snackbar.visible = true;
  },
  [Actions.downloadFileResponse]: (state, action) => {
    state.snackbar.visible = false;
    state.snackbar.error = action.error;
  },
  [Actions.rejectModerationReasonsRequest]: (state) => {
    state.rejectModerationReasons = [];
  },
  [Actions.rejectModerationReasonsResponse]: (state, action) => {
    state.rejectModerationReasons = action.payload.data.map(item => item['moderation_reason']);
  },
  [Actions.modifiedConfirmedModerationReasonsRequest]: (state) => {
    state.modifiedConfirmedModerationReasons = [];
  },
  [Actions.modifiedConfirmedModerationReasonsResponse]: (state, action) => {
    state.modifiedConfirmedModerationReasons = action.payload.data.map(item => item['moderation_reason']);
  },
});

export default purchaseOrderDetail;
