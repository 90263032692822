import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import theme from 'common/styles/extendeal-theme';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  Select,
  SelectProps,
  MenuItem, 
  ListItemText,
  Input,
  InputLabel,
  FormControlProps
} from '@material-ui/core';

interface Option {
  value: string | number;
  label: string;
}

export interface DropdownProps extends SelectProps {
  options: Array<Option>;
  inputId: string;
  label: string | undefined;
  formControlProps: FormControlProps;
}

const StyledSelect = styled(({ className, children, ...props }) => (
  <Select {...props} classes={{ root: className, select: 'select' }}>
    {children}
  </Select>
))`
  &.select {
    :focus {
      background-color: transparent;
    }
  }
`;

const StyledInput = styled(({ ...props }) => (
  <Input {...props} classes={{ input: 'input', underline: 'underline' }} />
))`
  &.input {
    font-size: ${rem('14px')};
    color: ${props => props.theme.colors.S['800']};

    ::placeholder {
      color: ${props => props.theme.colors.S['600']};
    }
  }

  &.underline {
    &::before {
      border-bottom: ${rem('1px')} solid ${props => props.theme.colors.S['600']};
    }

    &:hover::before {
      border-bottom-color: ${props => props.theme.colors.S['600']};
    }

    &::after {
      border-bottom-color: ${props => props.theme.colors.S['800']};
    }
  }
`;

const StyledMenuItem = styled(
  React.forwardRef((props: any, ref: any) => (
    <MenuItem ref={ref} {...props} classes={{ selected: 'selected' }} />
  ))
)`
  height: ${rem('32px')};
  min-height: ${rem('32px')};
  padding: 0 ${rem('16px')};

  &.selected {
    background-color: ${props => props.theme.colors.S['400']};
  }
`;

const StyledListItemText = styled(({ ...props }) => (
  <ListItemText {...props} classes={{ primary: 'primary' }} />
))`
  margin: 0;

  & .primary {
    color: ${props => props.theme.colors.S['800']};
    font-size: ${rem('14px')} !important;
  }
`;

const StyledInputLabel = styled(({ children, ...props }) => (
  <InputLabel {...props} classes={{ focused: 'focused' }}>
    {children}
  </InputLabel>
))`
  color: ${props => props.theme.colors.S['500']};

  &.focused {
    color: ${props => props.theme.colors.S['600']};
  }
`;

const MenuProps = {
  PaperProps: {
    style: {
      boxShadow: theme.shadows[2]
    }
  },
  MenuListProps: {
    style: {
      paddingTop: 12,
      paddingBottom: 12
    }
  }
};

export function Dropdown({ options, inputId, label, formControlProps, ...props }: DropdownProps) {
  return (
    <FormControl {...formControlProps}>
      {label !== undefined && <StyledInputLabel htmlFor={inputId}>{label}</StyledInputLabel>}
      <StyledSelect {...props} input={<StyledInput id={inputId} />} MenuProps={MenuProps}>
        {options.map(option => (
          <StyledMenuItem value={option.value} key={option.label}>
            <StyledListItemText>{option.label}</StyledListItemText>
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
}
