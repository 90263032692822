//@ts-nocheck

import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Menu } from 'components';
import { Button, MenuProps as MuiMenuProps } from '@material-ui/core';

interface MenuIconOption {
  label: string;
  onClick(item?: any): any;
}

export interface MenuIconProps {
  MenuProps: MuiMenuProps;
  options: Array<MenuIconOption>;
  horizontal: boolean;
  IconComponent: React.ReactNode;
}

const StyledButton = styled(({ active, horizontal, ...props }) => <Button {...props} />)`
  min-width: ${props => (props.horizontal ? rem('32px') : 0)};
  padding: ${rem('6px')} 0;
  color: ${props => props.theme.colors.S[800]};
  ${props =>
    props.horizontal &&
    css`
      min-height: ${rem('26px')};
      height: ${rem('26px')};

      span {
        height: 0;
      }
    `}

  ${props =>
    props.active &&
    css`
      background-color: ${props.theme.colors.S[300]};
    `}
`;

export function MenuIcon({ horizontal, MenuProps, options, IconComponent }: MenuIconProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledButton active={Boolean(anchorEl)} horizontal={horizontal} onClick={handleMenu}>
        {IconComponent}
      </StyledButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        style={{ top: 5 }}
        {...MenuProps}
      >
        {options.map(option => (
          <Menu.Item
            key={option.label}
            onClick={() => {
              option.onClick();
              handleClose();
            }}
          >
            {option.label}
          </Menu.Item>
        ))}
      </Menu>
    </>
  );
}
