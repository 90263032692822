import summary from 'features/summary/translations';
import purchaseOrderDetail from 'features/purchase-order-detail/translations';
import products from 'features/products/translations';
import addProduct from 'features/add-product/translations';
import shared from './shared';

export default {
  es: {
    ...shared.es,
    ...purchaseOrderDetail.es,
    ...summary.es,
    ...products.es,
    ...addProduct.es,
  }
};
