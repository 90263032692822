import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

export interface SpinnerProps {
  label: string;
  placement: 'vertical' | 'horizontal';
}

const Container = styled.div<{ placement: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props =>
    props.placement === 'vertical' &&
    css`
      flex-direction: column;
    `}
`;

const StyledCircularProgress = styled(({ ...props }) => (
  <CircularProgress {...props} classes={{ colorPrimary: 'colorPrimary' }} />
))`
  &.colorPrimary {
    color: ${props => props.theme.colors.G['900']};
  }
`;

const StaticCircularProgress = styled(({ ...props }) => (
  <CircularProgress {...props} variant="static" />
))`
  position: absolute;
  color: ${props => props.theme.colors.S['400']};
  z-index: -1;
`;

const Label = styled(Typography)<{ placement: string }>`
  color: ${props => props.theme.colors.S['700']};
  font-weight: 300;
  ${props =>
    props.placement === 'vertical'
      ? css`
          margin-top: ${rem('5px')};
          font-size: ${rem('12px')};
        `
      : css`
          margin-left: ${rem('10px')};
          font-size: ${rem('11px')};
        `}
`;

export function Spinner({ label, placement }: SpinnerProps) {
  return (
    <Container placement={placement}>
      <div>
        <StaticCircularProgress size={placement === 'horizontal' ? 32 : 50} value={100} />
        <StyledCircularProgress color="primary" size={placement === 'horizontal' ? 32 : 50} />
      </div>
      {label !== undefined && <Label placement={placement}>{label}</Label>}
    </Container>
  );
}

Spinner.defaultProps = {
  label: undefined,
  placement: 'vertical'
};
