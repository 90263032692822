import { createMuiTheme } from '@material-ui/core/styles';
import { ColorPartial } from '@material-ui/core/styles/createPalette';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    B: ColorPartial;
    G: ColorPartial;
    E: ColorPartial;
    Mi: ColorPartial;
    Lu: ColorPartial;
    Ra: ColorPartial;
    Ki: ColorPartial;
    neutral: ColorPartial;
  }

  interface PaletteOptions {
    B: ColorPartial;
    G: ColorPartial;
    E: ColorPartial;
    Mi: ColorPartial;
    Ma: ColorPartial;
    Lu: ColorPartial;
    Ra: ColorPartial;
    Ki: ColorPartial;
    neutral: ColorPartial;
  }
}

export default createMuiTheme({
  typography: {
    fontFamily: 'Source Sans Pro, sans-serif'
  },
  palette: {
    B: {
      A100: '#0A1827',
      900: '#0B2033',
      800: '#1e334a',
      700: '#2c4560',
      600: '#3b5775',
      500: '#486586',
      400: '#627a99',
      300: '#7b91ae',
      200: '#9db0c9',
      100: '#bed0e5',
      50: '#e3ecff'
    },
    G: {
      900: '#ff6300',
      800: '#ff8400',
      700: '#ff9600',
      600: '#ffa900',
      500: '#ffb700',
      400: '#ffc11b',
      300: '#ffcd47',
      200: '#ffda7d',
      100: '#ffe9b0',
      50: '#fff7e0'
    },
    E: {
      500: '#FF4800',
      400: '#FF6300',
      300: '#FF8400'
    },
    Mi: {
      600: '#2284c4',
      500: '#2c91d1',
      400: '#6dafd9',
      300: '#93c6e4',
      200: '#bbdcef',
      100: '#e3f1f8'
    },
    Ma: {
      600: '#d10322',
      500: '#de132a',
      400: '#ff3030',
      300: '#ee6d71',
      200: '#f69799',
      100: '#ffcbd2'
    },
    Lu: {
      600: '#007b2c',
      500: '#009e43',
      400: '#07ad4b',
      300: '#6ac682',
      200: '#97d5a5',
      100: '#c0e6c8'
    },
    Ra: {
      600: '#00878b',
      500: '#00b0bc',
      400: '#1dcad3',
      300: '#80e1e4',
      200: '#b2edee',
      100: '#e0f8f8'
    },
    Ki: {
      600: '#b5265c',
      500: '#e12f65',
      400: '#f44c7e',
      300: '#f995b4',
      200: '#fbbed2',
      100: '#fde5ed'
    },
    neutral: {
      900: '#333333',
      800: '#646464',
      700: '#8b8b8b',
      600: '#acacac',
      500: '#d1d1d1',
      400: '#e3e3e3',
      300: '#eeeeee'
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: '14px',
          cursor: 'pointer'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(215, 218, 221, 0.5)',
          borderRadius: '12px',
          border: '5px solid transparent',
          backgroundClip: 'padding-box'
        },
        '*::-webkit-scrollbar-button': {
          width: '0',
          height: '0',
          display: 'none'
        },
        '*::-webkit-scrollbar-corner': {
          'background-color': 'transparent'
        }
      }
    }
  }
});
