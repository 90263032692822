import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { rem, transitions } from 'polished';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import {
  Input,
  InputProps,
  FormControl,
  FormControlProps,
  IconButton,
  InputAdornment
} from '@material-ui/core';

export interface TrackingNumberProps {
  onChange(value: any): any;
  value: any;
  inputProps: InputProps;
  formControlProps: FormControlProps;
}

const StyledFormControl = styled(FormControl)``;

type StyledInputProps = InputProps & { visible?: boolean };

const StyledInput = styled(
  React.forwardRef(({ visible, ...props }: StyledInputProps, ref) => (
    <Input inputRef={ref} {...props} disableUnderline classes={{ input: 'input' }} />
  ))
)<StyledInputProps>`
  position: relative;
  height: ${rem('24px')};
  border-radius: ${rem('4px')};
  color: ${props => props.theme.colors.S[800]};
  font-weight: 600;
  font-size: ${rem('14px')};
  padding-left: ${rem('8px')};
  border: ${rem('1px')} solid transparent;

  ${props =>
    props.visible &&
    css`
       {
        box-shadow: ${props.theme.shadows[1]};
        border-color: ${props.theme.colors.S[700]};
        border-style: solid;
      }
    `};

  & .input {
    ::placeholder {
      color: ${props => props.theme.colors.S['800']};
      font-weight: 600;
      font-size: ${rem('14px')};
      opacity: 1;
    }
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  position: absolute;
  box-shadow: ${props => props.theme.shadows[1]};
  border: ${rem('1px')} solid ${props => props.theme.colors.S[700]};
  border-radius: ${rem('4px')};
  right: 0;
  bottom: ${rem('4px')};
  ${transitions('box-shadow .3s ease-in-out', 'all 1s ease')};
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: ${props => props.theme.colors.S[700]};
  font-size: ${rem('20px')};
  padding: ${rem('2px')};
  cursor: pointer;
  border-right: ${rem('1px')} solid ${props => props.theme.colors.S[700]};
  border-top-left-radius: ${rem('4px')};
  border-bottom-left-radius: ${rem('4px')};

  :hover {
    background-color: ${props => props.theme.colors.S[400]};
  }
`;

const StyledDoneIcon = styled(DoneIcon)`
  color: ${props => props.theme.colors.S[700]};
  font-size: ${rem('20px')};
  padding: ${rem('2px')};
  cursor: pointer;
  border-top-right-radius: ${rem('4px')};
  border-bottom-right-radius: ${rem('4px')};

  :hover {
    background-color: ${props => props.theme.colors.S[400]};
  }
`;

const StyledEditIcon = styled(EditIcon)`
  color: ${props => props.theme.colors.S[700]};
  font-size: ${rem('20px')};
  padding: ${rem('2px')};
`;

const StyledIconButton = styled(({ visible, children, ...props }) => (
  <IconButton {...props}>{children}</IconButton>
))`
  border-radius: 0;
  padding: ${rem('2px')};
  visibility: hidden;
  ${props =>
    props.visible &&
    css`
      visibility: visible;
    `}
  
  border-left: ${rem('1px')} solid ${props => props.theme.colors.S[700]};
  width: ${rem('24px')};
  height: ${rem('24px')};
  margin-right: 0;
`;

const useOutsideAlerter = (ref: any, callback: any) => {
  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

const useTrackingValue = () => {
  const [value, setValue] = useState('');

  const handleChange = (event: any)  => {
    const regex = new RegExp('^[0-9]*$');
    const v = event.target.value;
    if (v.length > 0 && regex.test(v)) {
      setValue(v);
    } else if (v.length === 0) {
      setValue('');
    }
  };

  return {
    handleChange,
    value,
    setValue
  };
};

const useEditing = (ref: any) => {
  const [isEditing, setEditing] = useState(false);

  const toggleEditing = () => {
    setEditing(!isEditing);
  };

  useEffect(() => {
    if (isEditing) {
      ref.current.focus();
    }
  }, [isEditing]);

  return {
    toggleEditing
  };
};

export function TrackingNumber({
  onChange,
  value,
  inputProps,
  formControlProps
}: TrackingNumberProps) {
  const [actionsVisibility, setActionsVisibility] = useState<boolean>(false);
  const [editButtonVisibility, setEditButtonVisibility] = useState<boolean>(false);
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);
  const number = useTrackingValue();
  const editing = useEditing(inputRef);

  const toggleActionsVisibility = () => setActionsVisibility(!actionsVisibility);

  const editButtonVisible = (visible: boolean) => setEditButtonVisibility(visible);

  const toggleOff = (val: any) => {
    setActionsVisibility(false);
    setEditButtonVisibility(false);
    editing.toggleEditing();
    number.setValue(val);
  };

  const onCancel = () => toggleOff(value);

  const onAccept = () => {
    toggleOff(number.value);
    onChange(number.value);
  };

  const onEdit = () => {
    editing.toggleEditing();
    toggleActionsVisibility();
  };

  const onMouseOver = () => editButtonVisible(true);

  const onMouseLeave = () => editButtonVisible(actionsVisibility);

  useOutsideAlerter(wrapperRef, () => toggleOff(value));

  return (
    <StyledFormControl ref={wrapperRef} {...formControlProps}>
      <StyledInput
        inputRef={inputRef}
        visible={editButtonVisibility}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        readOnly={!actionsVisibility}
        value={number.value}
        onChange={number.handleChange}
        placeholder="-"
        endAdornment={
          <InputAdornment position="end">
            <StyledIconButton
              visible={editButtonVisibility && !actionsVisibility}
              onClick={onEdit}
              size="small"
            >
              <StyledEditIcon />
            </StyledIconButton>
          </InputAdornment>
        }
        {...inputProps}
      />
      {actionsVisibility && (
        <ActionsContainer>
          <StyledCloseIcon onClick={onCancel} />
          <StyledDoneIcon onClick={onAccept} />
        </ActionsContainer>
      )}
    </StyledFormControl>
  );
}

export default TrackingNumber;
