import React from 'react';
import styled from 'styled-components';
import { rem, transitions } from 'polished';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  DialogContent,
  DialogContentProps,
  DialogActions,
  DialogActionsProps
} from '@material-ui/core';

export interface ModalProps extends DialogProps {
  children: React.ReactNode;
  loading?: boolean;
  LoadingComponent?: React.ReactNode;
  Title?: React.FC<DialogTitleProps>;
  Content?: React.FC<DialogContentProps>;
  Actions?: React.FC<DialogActionsProps>;
}

const StyledDialog = styled(
  React.forwardRef(({ children, ...props } : DialogProps, ref) => (
    <Dialog
      ref={ref}
      {...props}
      classes={{
        paper: 'paper',
        paperWidthSm: 'paperWidthSm',
        paperWidthMd: 'paperWidthMd',
        paperWidthLg: 'paperWidthLg'
      }}
    >
      {children}
    </Dialog>
  ))
)`
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.25);
  }

  .paper {
    box-shadow: ${props => props.theme.shadows[3]};
    overflow-y: hidden;
    ${transitions('all 1s ease')};
  }

  .paperWidthSm {
    max-width: ${rem('280px')};
    min-height: ${rem('196px')};
  }

  .paperWidthMd {
    max-width: ${rem('552px')};
    min-height: ${rem('280px')};
  }

  .paperWidthLg {
    max-width: ${rem('652px')};
    min-height: ${rem('520px')};
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  padding-top: ${rem('24px')};
  padding-bottom: 0;

  .MuiTypography-h6 {
    font-weight: 600;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  padding-top: ${rem('20px')};
  padding-bottom: ${rem('24px')};
  color: ${props => props.theme.colors.S['800']};
  font-size: ${rem('16px')};
`;

const StyledDialogActions = styled(DialogActions)`
  position: relative;
  height: ${rem('52px')};
  padding: ${rem('8px')};
`;

const LoadingOverlayContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  z-index: 1000;

  > div {
    height: 100%;
  }
`;

export function Modal({ children, loading, LoadingComponent, ...props }: ModalProps) {
  return (
    <StyledDialog {...props}>
      {loading && <LoadingOverlayContainer>{LoadingComponent}</LoadingOverlayContainer>}
      {children}
    </StyledDialog>
  );
}

function Title({ children, ...props }: DialogTitleProps) {
  return <StyledDialogTitle {...props}>{children}</StyledDialogTitle>;
}

function Content({ children, ...props }: DialogContentProps) {
  return <StyledDialogContent {...props}>{children}</StyledDialogContent>;
}

function Actions({ children, ...props }: DialogActionsProps) {
  return <StyledDialogActions {...props}>{children}</StyledDialogActions>;
}

Modal.Title = Title;
Modal.Content = Content;
Modal.Actions = Actions;

