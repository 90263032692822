import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@material-ui/core';

export interface TextFieldProps extends Omit<MuiTextFieldProps, 'variant'> {
  dirty?: boolean;
}

export const TextField = styled((props: TextFieldProps) => (
  <MuiTextField fullWidth variant="outlined" size="small" {...props} />
))`
  ${props => props.dirty &&
    css`.MuiInputBase-root::before {
    content: '';
    position: absolute;
    background-color ${props.theme.colors.G[600]};
    width: ${rem('6px')};
    height: ${rem('6px')};
    border-radius: ${rem('6px')};
    left: -10px;
  }`}

  input {
    color: ${props => props.theme.colors.S['800']};

    ::placeholder {
      color: ${props => props.theme.colors.S['400']};
      opacity: 1;
    }
  }

  .MuiFormLabel-root {
    color: ${props => props.theme.colors.S['600']};

    &.Mui-focused {
      color: ${props => props.theme.colors.Mi['400']};
    }

    &.Mui-error {
      color: ${props => props.theme.colors.Ma['400']};
    }
  }

  .MuiFormHelperText-root {
    font-size: ${rem('10px')};
    color: ${props => props.theme.colors.S['600']};

    &.Mui-error {
      color: ${props => props.theme.colors.Ma['400']};
    }
  }

  & .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.colors.S['400']};
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${props => props.theme.colors.Mi['400']};
      }
    }

    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${props => props.theme.colors.Ma['400']};
      }
    }

    &:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) {
      .MuiOutlinedInput-notchedOutline {
        border: 2px ${props => props.theme.colors.S['400']} solid;
      }
    }
  }
`;
