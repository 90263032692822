import { createAction } from '@reduxjs/toolkit';


export const confirmPurchaseOrderRequest = createAction('global/CONFIRM_PURCHASE_ORDER_REQUEST');
export const confirmPurchaseOrderResponse = createAction('global/CONFIRM_PURCHASE_ORDER_RESPONSE');
export const rejectPurchaseOrderRequest = createAction('global/REJECT_PURCHASE_ORDER_REQUEST');
export const rejectPurchaseOrderResponse = createAction('global/REJECT_PURCHASE_ORDER_RESPONSE');
export const changePurchaseOrderStatusRequest = createAction('global/CHANGE_PURCHASE_ORDER_STATUS_REQUEST');
export const changePurchaseOrderStatusResponse = createAction('global/CHANGE_PURCHASE_ORDER_STATUS_RESPONSE');
export const setCurrentUser = createAction('global/SET_CURRENT_USER');
export const setAuthToken = createAction('global/SET_AUTHORIZATION_TOKEN');
export const manageSession = createAction('global/MANAGE_SESSION');
export const userSignout = createAction('global/USER_SIGNOUT');
export const changeUserPasswordRequest = createAction('global/CHANGE_USER_PASSWORD_REQUEST');
export const changeUserPassswordResponse = createAction('global/CHANGE_USER_PASSWORD_RESPONSE');
export const resetChangePasswordModal = createAction('global/RESET_CHANGE_PASSWORD_MODAL');
export const toggleChangePasswordModal = createAction('global/TOGGLE_CHANGE_PASSWORD_MODAL');