import { rem } from 'polished';

export const boxShadow = (hOffset, vOffset, blur = 0, spread = 0, color = '#000', inset = false) =>
  `box-shadow: ${inset ? 'inset' : ''} ${rem(`${hOffset}px`)} ${rem(`${vOffset}px`)} ${rem(
    `${blur}px`
  )} ${rem(`${spread}px`)} ${color};`;

export const shadows = {
  1: '1px 2px 6px rgba(0, 0, 0, 0.2)',
  2: '1px 4px 17px rgba(0, 0, 0, 0.2)',
  3: '1px 10px 35px rgba(0, 0, 0, 0.2)',
  4: '0px 1px 3px rgba(0, 0, 0, 0.2)',
  5: '0px 3px 6px rgba(0, 0, 0, 0.2)'
};

export const zIndex = {
  sidebar: 1200
};

export const truncate = `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
