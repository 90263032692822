export const PO_STATUS_PENDING = 1;
export const PO_STATUS_VALIDATED = 2;
export const PO_STATUS_DISAPPROVED = 3;
export const PO_STATUS_REQUESTED = 4;
export const PO_STATUS_IN_PROGRESS = 5;
export const PO_STATUS_CONFIRMED = 6;
export const PO_STATUS_REJECTED = 7;
export const PO_STATUS_EXPIRED = 8;
export const PO_STATUS_RETURNED = 9;
export const PO_STATUS_READ = 10;
export const PO_STATUS_DELIVERED = 11;
export const PO_STATUS_NOT_DELIVERED = 12;
export const PO_STATUS_MODIFIED_CONFIRMED = 13;
