import React from 'react';
import styled from 'styled-components';
import { rem, rgba, lighten, darken } from 'polished';
import { ButtonBase, ButtonBaseProps } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const backgroundColor = '#1e334a';

const contentColor = 'white';

export interface HomeButtonProps extends ButtonBaseProps {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  label: string;
}

const StyledButton = styled(({ ...props }) => (
  <ButtonBase {...props} classes={{ disabled: 'disabled' }} />
))`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${rem('112px')};
  border-radius: ${rem('4px')};
  width: ${rem('180px')};
  background-color: ${backgroundColor};
  color: ${contentColor};
  fill: ${contentColor};
  padding: 0 ${rem('16px')};

  &:hover {
    background-color: ${lighten(0.02, backgroundColor)};
  }

  &:active {
    background-color: ${darken(0.04, backgroundColor)};
  }

  &.disabled {
    color: ${rgba(contentColor, 0.2)};
    fill: ${rgba(contentColor, 0.2)};
    background-color: ${rgba(backgroundColor, 0.5)};
  }
`;

const Label = styled(Typography)`
  font-size: ${rem('13px')};
  letter-spacing: ${rem('1px')};
  color: inherit;
  margin-top: ${rem('15px')};
`;

export function HomeButton({ icon, label, ...props }: HomeButtonProps) {
  const SvgIcon = icon;
  return (
    <StyledButton {...props}>
      <SvgIcon width={40} fill="currentColor" />
      <Label>{label}</Label>
    </StyledButton>
  );
}

export default HomeButton;
