// @ts-nocheck
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { rem, ellipsis, transitions, rgba } from 'polished';
import theme from 'common/styles/extendeal-theme';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

interface Level {
  keyName: string;
  onClick(item: any): any;
}

interface Item {
  label: string;
  icon: React.ReactNode;
}

interface IconProps {
  item: { icon: React.FC<React.SVGProps<SVGSVGElement>>; label: string };
  color: string;
}

export interface SecondarySidebarProps {
  items: Array<Item>;
  expandable: boolean;
  primaryLevel: Level;
  secondaryLevel: Level;
}

const StyledDrawer = styled(({ expanded, ...props }) => (
  <Drawer {...props} classes={{ paper: 'paper' }} />
))`
  position: absolute;
  background-color: white;
  top: 0;
  bottom: 0;
  box-shadow: ${props => props.theme.shadows[1]};
  z-index: ${props => props.theme.zIndex.sidebar - 1};

  ${props =>
    !props.expanded &&
    css`
      margin-left: ${rem('-123px')};
    `}

  ${transitions('margin-left 0.6s cubic-bezier(0.43, 0.02, 0.34, 0.96) 0.2s')}

  & .paper {
    position: relative;
    background-color: white;
    overflow-x: hidden;
    white-space: nowrap;
  }
`;

const StyledListItem = styled(({ hasSubItems, ...props }) => (
  <ListItem {...props} classes={{ selected: 'selected' }} />
))`
  height: ${rem('72px')};
  align-items: center;
  justify-content: center;
  padding: 0;

  &.selected {
    border-top: ${rem('1px')} solid ${props => props.theme.colors.S['500']};
    ${props =>
      !props.hasSubItems &&
      css`
        border-bottom: ${rem('1px')} solid ${props.theme.colors.S['500']};
      `}
    background-color: ${props => rgba(props.theme.colors.S['300'], 0.5)};
  }
`;

const StyledListItemText = styled(({ ...props }) => (
  <ListItemText {...props} classes={{ primary: 'primary', secondary: 'secondary' }} />
))`
  width: ${rem('123px')};
  padding-left: ${rem('16px')};
  ${ellipsis('123px')};

  & .primary {
    font-weight: 400;
    font-size: ${rem('13px')};
    color: ${props => props.theme.colors.S['800']};
  }

  & .secondary {
    font-weight: 300;
    font-size: ${rem('12px')};
    color: ${props => props.theme.colors.S['800']};
  }
`;

const StyledCollapsedList = styled(List)`
  background-color: ${props => rgba(props.theme.colors.S['300'], 0.5)};
  border-bottom: ${rem('1px')} solid ${props => props.theme.colors.S['500']};
`;

const StyledCollapsedListItem = styled(StyledListItem)`
  &.selected {
    border: none;
    background-color: ${props => rgba(props.theme.colors.S['300'], 1)};
  }
`;

const useNestedLevelSelection = level => {
  const [selected, setSelected] = useState('');

  const handleSelected = item => {
    if (item[level.keyName] !== selected) {
      setSelected(item[level.keyName]);
      level.onClick(item);
    }
  };

  return {
    selected,
    handleSelected,
    keyName: level.keyName
  };
};

const useExpandable = (expandable: boolean) => {
  const [expanded, setExpanded] = useState(false);
  const toggle = () => (expandable ? setExpanded(!expanded) : undefined);

  return {
    expanded,
    toggle
  };
};

const Icon = React.memo(({ item, color }: IconProps) => {
  const SvgIcon = item.icon;

  return (
    <SvgIcon
      key={item.label}
      style={{ opacity: 0.7, marginLeft: 12, marginRight: 12 }}
      fill={color !== undefined ? color : theme.colors.S['800']}
      width={28}
      height={28}
    />
  );
});

export function SecondarySidebar({
  items,
  expandable,
  primaryLevel,
  secondaryLevel
}: SecondarySidebarProps) {
  const expand = useExpandable(expandable);
  const primary = useNestedLevelSelection(primaryLevel);
  const secondary = useNestedLevelSelection(secondaryLevel);

  return (
    <StyledDrawer
      expanded={expand.expanded}
      onMouseOver={expand.toggle}
      onMouseOut={expand.toggle}
      variant="permanent"
    >
      {items.map((item, index) => {
        const hasSubItems = Object.prototype.hasOwnProperty.call(item, 'items');
        return (
          <>
            <StyledListItem
              key={item[primary.keyName].id}
              hasSubItems={hasSubItems}
              selected={item[primary.keyName] === primary.selected}
              button
              onClick={() => primary.handleSelected(item)}
            >
              <StyledListItemText primary="test" secondary="asdasdasd" />
              <Icon item={item} />
            </StyledListItem>
            {hasSubItems && item.items.length > 0 && (
              <Collapse
                in={item[primary.keyName] === primary.selected}
                timeout="auto"
                unmountOnExit
              >
                <StyledCollapsedList component="div" disablePadding>
                  {item.items.map(subItem => {
                    const selected = subItem[secondary.keyName] === secondary.selected;
                    return (
                      <StyledCollapsedListItem
                        key={subItem[secondary.keyName].id}
                        button
                        selected={selected}
                        onClick={() => secondary.handleSelected(subItem)}
                      >
                        <StyledListItemText primary={subItem.title} secondary={subItem.subtitle} />
                        <Icon
                          item={item}
                          color={selected ? theme.colors[(index + 1) % 20] : undefined}
                        />
                      </StyledCollapsedListItem>
                    );
                  })}
                </StyledCollapsedList>
              </Collapse>
            )}
          </>
        );
      })}
    </StyledDrawer>
  );
}
