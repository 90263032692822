import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Radio as MuiRadio, RadioProps as MuiRadioProps }from '@material-ui/core';

export type RadioProps = MuiRadioProps;

const StyledRadio = styled(({ ...props }: RadioProps) => (
  <MuiRadio {...props} classes={{ checked: 'checked', disabled: 'disabled' }} />
))`
  color: ${props => props.theme.colors.S['800']};
  
  : hover {
    color: ${props => props.theme.colors.Mi['400']};
    background-color: ${props => props.theme.colors.Mi['100']};
  }

  &.checked {
    color: ${props => props.theme.colors.Mi['400']};
  }

  &.disabled {
    color: ${props => props.theme.colors.S['500']};
  }

  & span svg {
    width: ${rem('20px')};
    height: ${rem('20px')};
  }
`;

export function Radio({ ...props }: RadioProps) {
  return <StyledRadio {...props} />;
}
