import React, { lazy, Suspense } from 'react';
import { Layout, PageSpinner } from 'components';
import { Route, Router, Switch } from 'react-router-dom';
import history from './history';
import ReactGA from 'react-ga';

const Landing = lazy(() => import('features/landing'));
const Summary = lazy(() => import('features/summary'));
const PurchaseOrderDetail = lazy(() => import('features/purchase-order-detail'));
const Products = lazy(() => import('features/products'));
const EditPrices = lazy(() => import('features/products/edit-prices'));
const AddProduct = lazy(() => import('features/add-product'));
const PriceList = lazy(() => import('features/products/priceList'));
const StepOne = lazy(() => import('features/products/priceList/step-one'));
const StepTwo = lazy(() => import('features/products/priceList/step-two'));
const StepThree = lazy(() => import('features/products/priceList/step-three'));
const PriceListDetail = lazy(() => import('features/products/priceList/PriceListDetail'));

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING);

history.listen((location, action) => {  
  ReactGA.pageview(location.pathname + location.search);  
});

function RestrictedRoute({ component: Component, ...rest }) {
  return <Route {...rest} component={Component} />;
}

export default function AppRouter() {
  return (
    <Router history={history}>
      <Switch>
        <Layout>
          <Suspense fallback={<PageSpinner />}>
            <Route exact path="/" component={Landing} />
            <RestrictedRoute exact path="/summary" component={Summary} />
            <RestrictedRoute exact path="/order/:id" component={PurchaseOrderDetail} />
            <RestrictedRoute exact path="/products" component={Products} />
            <RestrictedRoute exact path="/products/edit" component={EditPrices} />
            <RestrictedRoute exact path="/products/add/:id" component={AddProduct} />
            <RestrictedRoute exact path="/products/add" component={AddProduct} />
            <RestrictedRoute exact path="/products/price-list" component={PriceList} />
            <RestrictedRoute exact path="/products/price-list-detail/:id" component={PriceListDetail} />
            <RestrictedRoute exact path="/products/price-list/add-step-one" component={StepOne} />
            <RestrictedRoute exact path="/products/price-list/add-step-two" component={StepTwo} />
            <RestrictedRoute exact path="/products/price-list/add-step-three" component={StepThree} />
          </Suspense>
        </Layout>
      </Switch>
    </Router>
  );
}
