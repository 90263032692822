import i18n from 'i18next';
import { FullOrder, Order, FullProduct, FullSales, Product, Sales } from 'assets/icons';

export interface Route {
  label: string;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  activeIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  path: string;
  subRoutes?: Route[];
}

const routes: Route[] = [
  {
    label: i18n.t('summary:title'),
    icon: Order,
    activeIcon: FullOrder,
    path: '/summary',
    subRoutes: [{ label: i18n.t('summary:title'), path: '/summary' }]
  },
  // {
  //   label: i18n.t('products:title'),
  //   icon: Product,
  //   activeIcon: FullProduct,
  //   path: '/products',
  //   subRoutes: [{ label: 'Productos', path: '/products' }]
  // }
];

export default routes;
