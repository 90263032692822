import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps } from '@material-ui/core';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBox from '@material-ui/icons/CheckBox';

export type CheckboxProps = MuiCheckboxProps;

const StyledCheckbox = styled(
  React.memo(({ ...props }) => (
    <MuiCheckbox
      {...props}
      classes={{ checked: 'checked', disabled: 'disabled' }}
      checkedIcon={<CheckBox fontSize="small" />}
      icon={<CheckBoxOutlineBlank fontSize="small" />}
    />
  ))
)`
  color: ${props => props.theme.colors.S['800']};

  &.checked {
    color: ${props => props.theme.colors.Mi['400']};
  }

  &.disabled {
    color: ${props => props.theme.colors.S['500']};
  }

  &.MuiCheckbox-indeterminate {
    color: ${props => props.theme.colors.Mi['400']};
  }

  &.MuiIconButton-root:hover {
    background-color: ${props => rgba(props.theme.colors.Mi['400'], 0.1)};
  }
`;

export const Checkbox = React.memo(function Checkbox({ ...props }: CheckboxProps) {
  return <StyledCheckbox {...props} />;
});
