import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { productSubtotal, getItemTrace, withTaxPrice } from 'common/utils/helpers';
import {
  PO_STATUS_PENDING,
  PO_STATUS_VALIDATED,
  PO_STATUS_CONFIRMED,
  PO_STATUS_MODIFIED_CONFIRMED,
  PO_STATUS_READ
} from 'common/utils/constants';
import * as moment from 'moment';
import { formatMoney } from "../../../common/utils/helpers";

const defaultObject = {};

export const purchaseOrderSelector = state => state.purchaseOrderDetail.detail;
export const isFetchingSelector = state => state.purchaseOrderDetail.isFetching;
export const productItemsSelector = state => state.purchaseOrderDetail.productItems;
export const priceSymbolSelector = state =>
  get(purchaseOrderSelector(state), 'quotations.currency.symbol');
export const shippingCostSelector = state =>
  get(purchaseOrderSelector(state), 'quotations.shipping_cost');
export const isPendingSelector = state =>
  get(purchaseOrderSelector(state), 'status.id') === PO_STATUS_PENDING ||
  get(purchaseOrderSelector(state), 'status.id') === PO_STATUS_READ;
export const isValidatedSelector = state =>
  get(purchaseOrderSelector(state), 'status.id') === PO_STATUS_VALIDATED;
export const isConfirmedSelector = state => {
  const statusId = get(purchaseOrderSelector(state), 'status.id');
  return statusId === PO_STATUS_CONFIRMED || statusId === PO_STATUS_MODIFIED_CONFIRMED;
};
export const deliveryPointSelector = state => get(purchaseOrderSelector(state), 'delivery_point');
export const statusSelector = state => get(purchaseOrderSelector(state), 'status');
export const poNumberSelector = state => `#${purchaseOrderSelector(state).number}`;
export const deliverDateSelector = state => purchaseOrderSelector(state).date;
export const deliveryDateTimeSelector = state =>
  `${formattedDeliveryDateSelector(state)} - ${formattedDeliveryTimeRangeSelector(state)}`;
export const timeRangeSelector = state => {
  const poDetail = purchaseOrderSelector(state);
  return `${get(poDetail, 'delivery_point_time.from')} - ${get(
    poDetail,
    'delivery_point_time.to'
  )}`;
};
export const formattedDeliveryDateSelector = state => {
  const poDetail = purchaseOrderSelector(state);
  let date = moment(get(poDetail, 'date')).format('D MMM YYYY');
  return (
    date.slice(0, 2) + ' ' + date.charAt(3).toLocaleUpperCase() + date.slice(4).replace('.', '')
  );
};
export const formattedDeliveryTimeRangeSelector = state => {
  const poDetail = purchaseOrderSelector(state);
  return `${moment(get(poDetail, 'delivery_point_time.from'), 'HH:ii').format('H')} {to} ${moment(
    get(poDetail, 'delivery_point_time.to'),
    'HH:ii'
  ).format('H \\h\\s')}`;
};
export const commentSelector = state =>
  get(purchaseOrderSelector(state), 'delivery_comments[0].comment');

export const productsWithSubtotalSelector = createSelector(productItemsSelector, items =>
  items.map(item => {
    const lastTrace = getItemTrace({ trace: item.item_trace });

    return {
      ...item,
      subtotal: productSubtotal({
        aptFractionable: item.product.apt_fractionable,
        price: lastTrace.base_price,
        quantity: lastTrace.quantity,
        saleUnit: item.product.sales_unit
      })
    };
  })
);

export const totalSelector = createSelector(productsWithSubtotalSelector, items =>
  items.reduce((acc, item) => acc + item.subtotal, 0)
);

export const totalDiscount = createSelector(productsWithSubtotalSelector, items =>
  items.reduce((acc, item) => {
    let discount =
      (item.subtotal * get(item.volumeDiscount, 'volumeDiscountRules.new.discount')) / 100 || 0;
    return acc + discount;
  }, 0)
);

const subtotalWithDiscount = createSelector(productsWithSubtotalSelector, items => {
  const sub_total = items.reduce((acc, item) => acc + item.subtotal, 0);
  const discount = items.reduce(
    (acc, item) =>
      acc +
      ((item.subtotal * get(item.volumeDiscount, 'volumeDiscountRules.new.discount')) / 100 || 0),
    0
  );
  return sub_total - discount;
});

export const hasModifiedProductsSelector = state =>
  !isEqual(purchaseOrderSelector(state).items, productItemsSelector(state));
export const isChangingStatusSelector = state => state.purchaseOrderDetail.isChangingStatus;
export const statusChangeSuccessSelector = state => state.purchaseOrderDetail.statusChangeSuccess;
export const deliveryLogsSelector = state => state.purchaseOrderDetail.deliveryLogs;
export const markedAsReadSelector = state => !!get(purchaseOrderSelector(state), 'read.read');
export const clientSelector = state => get(purchaseOrderSelector(state), 'client') ?? defaultObject;
export const snackbarSelector = state => state.purchaseOrderDetail.snackbar;
export const readedBySelector = state => get(purchaseOrderSelector(state), 'read.user.name');
export const formattedAmountSelector = createSelector(
  totalSelector,
  priceSymbolSelector,
  shippingCostSelector,
  (total, priceSymbol, shippingCost) => withTaxPrice(total + shippingCost, priceSymbol)
);
export const clientNameSelector = createSelector(purchaseOrderSelector, po =>
  get(po, 'client.name')
);
export const anyItemHasBonusSelector = createSelector(productItemsSelector, items =>
  items.some(item => get(item.merchandiseBonus, 'merchandiseBonusRules.new.bonus') > 0)
);
export const anyItemHasDiscountSelector = createSelector(productItemsSelector, items =>
  items.some(item => get(item.volumeDiscount, 'volumeDiscountRules.new.discount') > 0)
);
export const hasShippingCost = createSelector(shippingCostSelector, shippingCost =>
  shippingCost > 0
);
export const formattedDiscount = createSelector(
  totalDiscount,
  priceSymbolSelector,
  (total, priceSymbol) => `${priceSymbol} ${formatMoney(total)}`
);
export const formattedShippingCost = createSelector(
  shippingCostSelector,
  priceSymbolSelector,
  (shippingCost, priceSymbol) => `${priceSymbol} ${formatMoney(shippingCost)}`
);
export const formattedSubtotalWithDiscount = createSelector(
  subtotalWithDiscount,
  priceSymbolSelector,
  shippingCostSelector,
  (total, priceSymbol, shippingCost) => withTaxPrice(total + shippingCost, priceSymbol)
);
export const formattedSubtotalNoDiscount = createSelector(
  totalSelector,
  priceSymbolSelector,
  (total, priceSymbol) => `${priceSymbol} ${formatMoney(total)}`
);
export const rejectModerationReasonsSelector = state => state.purchaseOrderDetail.rejectModerationReasons;
export const modifiedConfirmedModerationReasonsSelector = state => state.purchaseOrderDetail.modifiedConfirmedModerationReasons;
