import { apiInstance, objectToFormData } from 'common/utils/axios';
import { ResponseType, Product } from './types';
import imageCompression from 'browser-image-compression';

export interface ProductFilters {
  families: string;
  partners: string;
  priceList: string;
  partnerPriceList: string;
  search: string;
  id: string;
  partner_id: string;
  visibility: React.ReactText[];
  availability: React.ReactText[];
  merchandise_bonus: React.ReactText[];
}

export interface GetProductsRequest {
  page: number;
  sorts: string;
  filters: Partial<ProductFilters>;
}

export type PostProduct = Pick<
  Product,
  | 'id'
  | 'partner_sku'
  | 'ean_upc'
  | 'partner_id'
  | 'family_id'
  | 'name'
  | 'description'
  | 'brand_name'
  | 'base_price'
  | 'fractionable'
  | 'apt_fractionable'
  | 'available'
  | 'visible'
  | 'image'
  | 'measure_unit_id'
  | 'presentation_id'
  | 'sales_unit'
  | 'bulk_id'
  | 'bulk_presentation'
  | 'sell_and_validation_condition_id'
  | 'created_at'
  | 'updated_at'
>;

export type PatchProducts = Pick<Product, 'id' | 'available' | 'visible'>[];

const getCompressedFile = async (file: File | undefined) => {
  if (file) {
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 184,
      useWebWorker: true
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  }

  return '';
};

export const getProducts = async (request: Partial<GetProductsRequest>) => {
  const { data } = await apiInstance.get<ResponseType<Product[]>>('/products', { params: request });
  return data;
};

const buildProductFormData = async (request: PostProduct) => {
  const { image, ...rest } = request;
  const formData = objectToFormData(rest);
  if (image) {
    const compressedImage = await getCompressedFile(image);
    formData.append('image', compressedImage);
  }

  return formData;
};

export const createProduct = async (request: PostProduct) => {
  const formData = await buildProductFormData(request);
  const { data } = await apiInstance.post<ResponseType<Product>>('/products', formData, {
    headers: { 'content-type': 'multipart/form-data' }
  });
  return data;
};

export const updateProduct = async (id: string, request: PostProduct) => {
  const formData = await buildProductFormData(request);

  const response = await apiInstance.put(`/products/${id}`, formData, {
    headers: { 'content-type': 'multipart/form-data' }
  });

  return response.status === 200;
};

export const patchProducts = async (request: PatchProducts) => {
  const { data } = await apiInstance.request<ResponseType<{ count: number }>>({
    method: 'PATCH',
    url: '/products',
    data: request
  });

  return data;
};

export const deleteProducts = async (request: number[]) => {
  const { data } = await apiInstance.request<ResponseType<{ count: number }>>({
    method: 'DELETE',
    url: '/products',
    data: request
  });

  return data;
};

export const deleteProduct = async (id: number) => {
  const { data } = await apiInstance.request({
    method: 'DELETE',
    url: `/products/${id}`
  });

  return data;
};
