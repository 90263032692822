import { combineReducers } from '@reduxjs/toolkit';

import summary from 'features/summary/reducer';
import purchaseOrderDetail from 'features/purchase-order-detail/reducer';
import { productsReducer } from 'features/products/redux/slice';
import { snackbarReducer, globalReducer } from './global/reducer';

const rootReducer = combineReducers({
  global: globalReducer,
  summary,
  purchaseOrderDetail,
  products: productsReducer,
  snackbar: snackbarReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
