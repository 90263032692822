import React, { useState } from 'react';
import cn from 'classnames';
import { ThemeContext } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMenu, useRouter } from 'common/utils/hooks';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import { FullFreshDesk, FreshDesk, Profile, FullProfile } from 'assets/icons';
import { ListItem, Drawer, Hidden, makeStyles, Theme } from '@material-ui/core';
import { usePopupState, bindHover, bindMenu } from 'material-ui-popup-state/hooks';
import { Route } from 'common/router/routes';
import { shadows, zIndex } from 'common/styles/mixins';

export interface SidebarIconProps {
  active: boolean;
  color: string;
  SvgComponent: React.FC<React.SVGProps<SVGSVGElement>>;
}

interface SidebarSubMenu {
  anchorEl: any;
  routes: Route[];
  handleClose: () => void;
}

export interface MainSidebarProps {
  routes: Route[];
}

const useStyles = makeStyles<
  Theme,
  { visible?: boolean; active?: boolean; freshDeskActive?: boolean }
>(({ palette }) => ({
  toolbarSeparator: {
    minHeight: '48px'
  },
  drawer: {
    width: '56px',
    flexShrink: 0,
    boxShadow: shadows[1],
    '& .MuiPaper-root': {
      position: 'relative',
      width: '56px',
      backgroundColor: ({ visible }) => (visible ? '#04273F' : palette.B['900']),
      overflowX: 'hidden',
      whiteSpace: 'nowrap',
      borderRight: 'none'
    }
  },
  listItem: {
    height: '72px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    '&:hover': {
      backgroundColor: palette.B[700]
    },
    backgroundColor: ({ active }) => (active ? palette.B[700] : undefined)
  },
  userActionsContainer: {
    marginTop: 'auto',
    zIndex: zIndex.sidebar
  },
  freshDeskListItem: {
    position: 'absolute',
    overflow: 'visible',
    bottom: '32px',
    width: ({ freshDeskActive }) => (freshDeskActive ? '82px' : '62px'),
    backgroundColor: palette.neutral[400],
    boxShadow: shadows['1'],
    transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderTopRightRadius: '200px',
    borderBottomRightRadius: '200px',
    zIndex: zIndex.sidebar,
    justifyContent: 'flex-end',
    paddingRight: '16px',
    '&:hover': {
      width: '82px',
      backgroundColor: palette.neutral[400]
    }
  }
}));

function SidebarIcon({ active, SvgComponent, color }: SidebarIconProps) {
  return <SvgComponent style={{ opacity: active ? 1 : 0.7 }} fill={color} width={28} height={28} />;
}

function SidebarItem({ route, isActive }: { route: Route; isActive: boolean }) {
  const { activeIcon, icon, label, path, subRoutes } = route;
  const popupState = usePopupState({ variant: 'popover', popupId: `${label}-${path}` });
  const classes = useStyles({ active: popupState.isOpen });
  const [firstRoute, ...rest] = subRoutes ?? [];
  const theme = React.useContext(ThemeContext);
  const { push } = useRouter();
  const goTo = (route: string) => (e: any) => {
    push(route);
    popupState.close();
  };

  return (
    <>
      <ListItem
        className={classes.listItem}
        onClick={goTo(route.path)}
        // {...bindHover(popupState)}
        button
        key={label}
        title={label}
      >
        <SidebarIcon
          color="white"
          // @ts-ignore
          SvgComponent={isActive ? activeIcon : icon}
          active={isActive}
        />
      </ListItem>
      {/* <HoverMenu
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        style={{ marginLeft: 5, paddingLeft: 10, top: 8 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          style: {
            minWidth: 160
          }
        }}
      >
        <Menu.Item
          key={firstRoute.label}
          fontSize={16}
          color={theme.colors.B[900]}
          onClick={goTo(firstRoute.path)}
        >
          {firstRoute.label}
        </Menu.Item>
        {rest?.length > 0 && <div style={{ height: 8 }} />}
        {rest?.map(option => (
          <Menu.Item key={option.label} onClick={goTo(option.path)}>
            {option.label}
          </Menu.Item>
        ))}
      </HoverMenu> */}
    </>
  );
}

export function MainSidebar({ routes }: MainSidebarProps) {
  const location = useLocation();
  const { t } = useTranslation();
  const visible = location !== undefined && location.pathname !== '/';
  const profileMenu = useMenu();
  const [fcVisible, setFcVisible] = useState(false);
  const classes = useStyles({ visible, freshDeskActive: fcVisible });

  const toggleWidget = () => {
    // @ts-ignore
    const freshChatWidget = window.fcWidget;

    if (freshChatWidget) {
      const isOpen = freshChatWidget.isOpen();
      freshChatWidget.on('widget:closed', () => {
        setFcVisible(false);
      });

      freshChatWidget.on('widget:opened', () => {
        setFcVisible(true);
      });

      if (isOpen) {
        freshChatWidget.close();
      } else {
        // @ts-ignore
        document.getElementById('fc_frame').style.display = 'block';
        freshChatWidget.open();
      }
    }
  };

  return (
    <>
      <Hidden xsDown>
        <Drawer className={classes.drawer} variant="permanent">
          <div className={classes.toolbarSeparator} />
          {visible && (
            <>
              {routes.map(route => {
                const { activeIcon, icon, label, path } = route;
                // @ts-ignore
                const { from } = location.state || {};
                const isActive = location.pathname.includes(path) || path === from;

                if (path.includes('self-management')) {
                  return (
                    <a
                      key={label}
                      href={`${process.env.REACT_APP_SCI_HOST_URL}/proveedor/autogestion`}
                    >
                      <ListItem className={classes.listItem} button key={label} title={label}>
                        <SidebarIcon
                          color="white"
                          // @ts-ignore
                          SvgComponent={isActive ? activeIcon : icon}
                          active={isActive}
                        />
                      </ListItem>
                    </a>
                  );
                }

                return <SidebarItem {...{ route, isActive }} />;
              })}
            </>
          )}
          <div className={classes.userActionsContainer}></div>
        </Drawer>
        <ListItem
          className={cn(classes.listItem, classes.freshDeskListItem)}
          onClick={toggleWidget}
          button
        >
          <SidebarIcon active color="black" SvgComponent={fcVisible ? FullFreshDesk : FreshDesk} />
        </ListItem>
      </Hidden>
    </>
  );
}
