import { createAction } from '@reduxjs/toolkit';

export const detailRequest = createAction('purchase-order-detail/DETAIL_REQUEST');
export const detailResponse = createAction('purchase-order-detail/DETAIL_RESPONSE');
export const downloadFileRequest = createAction('purchase-order-detail/DOWNLOAD_FILE_REQUEST');
export const downloadFileResponse = createAction('purchase-order-detail/DOWNLOAD_FILE_RESPONSE');
export const updateProductRequest = createAction('purchase-order-detail/UPDATE_PRODUCT_REQUEST');
export const updateProductSuccess = createAction('purchase-order-detail/UPDATE_PRODUCT_SUCCESS');
export const resetProductModifications = createAction('purchase-order-detail/RESET_PRODUCTS_MODIFICATIONS');
export const deliveryLogRequest = createAction('purchase-order-detail/DELIVERY_LOG_REQUEST');
export const deliveryLogResponse = createAction('purchase-order-detail/DELIVERY_LOG_RESPONSE');
export const resetModals = createAction('purchase-order-detail/RESET_MODALS');
export const setIsEditing = createAction('purchase-order-detail/SET_IS_EDITING');
export const updateItemBonus = createAction('purchase-order-detail/UPDATE_ITEM_BONUS');
export const rejectModerationReasonsRequest = createAction('purchase-order-detail/REJECT_MODERATION_REASONS_REQUEST');
export const rejectModerationReasonsResponse = createAction('purchase-order-detail/REJECT_MODERATION_REASONS_RESPONSE');
export const modifiedConfirmedModerationReasonsRequest = createAction('purchase-order-detail/MODIFIED_CONFIRMED_MODERATION_REASONS_REQUEST');
export const modifiedConfirmedModerationReasonsResponse = createAction('purchase-order-detail/MODIFIED_CONFIRMED_MODERATION_REASONS_RESPONSE');