import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Spinner } from 'components';
import {
  Input,
  InputLabel,
  InputAdornment,
  FormHelperText,
  IconButton,
  FormControl
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

interface PasswordInputProps {
  id: string;
  value: string;
  show: boolean;
  onChange: (event: any) => void;
  onVisibilityChange: (event: any) => void;
  label: string;
  error?: boolean;
  helperText?: string;
}

interface InputValues {
  value: string;
  show: boolean;
}

interface ChangePasswordModalState {
  newPassword: InputValues;
  oldPassword: InputValues;
  repeatPassword: InputValues;
  [prop: string]: InputValues;
}

interface ConfirmRequest {
  current_password: string;
  password: string;
  password_confirmation: string;
}

export interface ChangePasswordModalProps {
  visible: boolean;
  onClose: () => void;
  onConfirm: (request: ConfirmRequest) => void;
  loading: boolean;
  finished: boolean;
  error: boolean;
}

const Label = styled.span`
  color: ${props => props.theme.colors.S[800]};
  font-size: ${rem('16px')};
`;

function PasswordInput({
  id,
  value,
  show,
  onChange,
  onVisibilityChange,
  label,
  error,
  helperText
}: PasswordInputProps) {
  return (
    <FormControl style={{ marginTop: '10px' }} error={error}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Input
        id={id}
        type={show ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={onVisibilityChange}>
              {show ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export function ChangePasswordModal({
  visible,
  onClose,
  onConfirm,
  loading,
  finished,
  error
}: ChangePasswordModalProps) {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const initialState = {
    newPassword: {
      value: '',
      show: false
    },
    oldPassword: {
      value: '',
      show: false
    },
    repeatPassword: {
      value: '',
      show: false
    }
  };
  const [values, setValues] = useState<ChangePasswordModalState>(initialState);

  const anyFieldEmpty = Object.values(values).some(x => x.value === null || x.value === '');
  const passwordDontMatch =
    values.newPassword.value !== '' &&
    values.repeatPassword.value !== '' &&
    values.newPassword.value !== values.repeatPassword.value;
  const newPasswordLengthError =
    values.newPassword.value !== '' && values.newPassword.value.length <= 6;

  const fields = [
    { id: 'oldPassword', label: t('shared:old_password') },
    {
      id: 'newPassword',
      label: t('shared:password'),
      error: newPasswordLengthError,
      helperText: t('shared:password_length_error')
    },
    {
      id: 'repeatPassword',
      label: t('shared:repeat_password'),
      error: passwordDontMatch,
      helperText: t('shared:password_match_error')
    }
  ];

  const handleChange = (prop: string) => (event: any) => {
    setValues({
      ...values,
      [prop]: { value: event.target.value, show: values[prop].show }
    });
  };

  const handleVisibility = (prop: string) => (event: any) => {
    setValues({ ...values, [prop]: { ...values[prop], show: !values[prop].show } });
  };

  const onClickConfirm = () => {
    onConfirm({
      current_password: values.oldPassword.value,
      password: values.newPassword.value,
      password_confirmation: values.repeatPassword.value
    });
    setValues(initialState);
  };

  const onCloseModal = () => {
    onClose();
    setValues(initialState);
  };

  return (
    <Modal
      fullWidth
      maxWidth="sm"
      open={visible}
      onClose={onCloseModal}
      loading={loading}
      LoadingComponent={<Spinner label={t('shared:changing_password')} />}
    >
      <Modal.Title>{t('shared:change_password')}</Modal.Title>
      <Modal.Content>
        {finished ? (
          <Label>{t(`shared:change_password_${error ? 'error' : 'success'}`)}</Label>
        ) : (
          fields.map(({ id, ...rest }) => (
            <PasswordInput
              key={id}
              id={id}
              value={values[id].value}
              show={values[id].show}
              onChange={handleChange(id)}
              onVisibilityChange={handleVisibility(id)}
              {...rest}
            />
          ))
        )}
      </Modal.Content>
      <Modal.Actions>
        {finished ? (
          <Button colorName={theme.colors.Mi} onClick={onCloseModal}>
            {t('shared:actions.close')}
          </Button>
        ) : (
          <>
            <Button colorName={theme.colors.S[700]} onClick={onCloseModal}>
              {t('shared:actions.cancel')}
            </Button>
            <Button
              colorName={theme.colors.Mi}
              onClick={onClickConfirm}
              disabled={anyFieldEmpty || passwordDontMatch || newPasswordLengthError}
            >
              {t('shared:actions.confirm')}
            </Button>
          </>
        )}
      </Modal.Actions>
    </Modal>
  );
}
