import { apiInstance } from 'common/utils/axios';
import { ResponseType, PartnerPriceList } from './types';

export interface GetPartnerPriceListRequest {
  partnerId: string;
  partnerPriceListId?: string;
}

export interface BulkUpdatePriceListItem {
  partner_price_list_id: string;
  product_id: number;
  base_price: number;
  modified?: boolean;
}

export interface BulkUpdatePriceListsRequest {
  partnerId: string;
  items: BulkUpdatePriceListItem[];
}

export interface poinstOfSales {
  partnerId: string;
}

export interface Filters {
  families?: string;
  partners?: string;
  priceList?: string;
  partnerPriceList?: string;
  search?: string;
  id?: string;
  partner_id?: string;
  visibility?: React.ReactText[];
  availability?: React.ReactText[];
  merchandise_bonus?: React.ReactText[];
  not_in_merchandise_bonus?: number,
  doesnt_have_merchandise_bonus?: boolean,
  not_in_volume_discount?: number,
  doesnt_have_volume_discount?: boolean
}

export interface getPriceListItems {
  partnerId: string;
  partnerPriceListId: string;
  filters?: Partial<Filters>;
}

export const getPartnerPriceList = async (request: GetPartnerPriceListRequest) => {
  const url = `/partners/${request.partnerId}/partner-price-lists${
    request.partnerPriceListId ? `/${request.partnerPriceListId}` : ''
  }`;
  const  { data }  = await apiInstance.get(url);
  return data;
};

export const bulkUpdatePriceLists = async ({ partnerId, items }: any) => {
  const { data } = await apiInstance.post(`/partners/${partnerId}/partner-price-lists/bulk/items`, {
    items
  });
  return data;
};

export const createPriceLists = async ({ partnerId, params }: any) => {
  const { data } = await apiInstance.post(`/partners/${partnerId}/partner-price-lists/`, 
    params
  );
  return data;
};

export const getPriceListItems = async ( partnerId: string, partnerPriceListId: string , filters: Partial<Filters>) => {
  const { data } = await apiInstance.get(`/partners/${partnerId}/partner-price-lists/${partnerPriceListId}/items`, { params: filters });
  return data;
};

export const updatePriceList = async ( partnerId: string, partnerPriceListId: string , items: any) => {
  const { data } = await apiInstance.put(`/partners/${partnerId}/partner-price-lists/${partnerPriceListId}`, items );
  return data;
}

export const poinstOfSales = async ( partnerId : poinstOfSales) => {
  const data  = await apiInstance.get(`/partners/${partnerId}/clients`);
  return data;
};

export const udpatePoinstOfSales = async ( partnerId : poinstOfSales, partnerPriceListId: string, items: any ) => {
  const data  = await apiInstance.put(`/partners/${partnerId}/price-lists/${partnerPriceListId}/clients`, items);
  return data;
};

export const deletePoinstOfSales = async ( partnerId : poinstOfSales, partnerPriceListId: string ) => {
  const data  = await apiInstance.delete(`/partners/${partnerId}/price-lists/${partnerPriceListId}`);
  return data;
};


