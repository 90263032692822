import React from 'react';
import styled, { css } from 'styled-components';
import { lighten, darken } from 'polished';
import { Fab, FabProps } from '@material-ui/core';

export interface FloatingButtonProps extends Omit<FabProps, 'color'> {
  color: string;
  active?: boolean;
  borderColor?: string;
}

const StyledButton = styled(({ color, ...props }: FloatingButtonProps) => (
  <Fab {...props} classes={{ disabled: 'disabled' }} />
))`
  ${props => css`
    background-color: ${props.color};
    box-shadow: ${props.theme.shadows['2']};
    color: white;

    ${props.borderColor && `border: 1px solid ${props.borderColor};`}

    &:hover {
      background-color: ${lighten(0.02, props.color)};
    }

    &:active {
      background-color: ${darken(0.04, props.color)};
      box-shadow: none;
    }

    &.disabled {
      background-color: ${props.theme.colors.S['400']};
      color: ${props.theme.colors.S['500']};
    }
  `}
`;

export function FloatingButton({ children, ...props }: FloatingButtonProps) {
  return <StyledButton {...props}>{children}</StyledButton>;
}
