import React from 'react';
import styled, { css } from 'styled-components';
import { rem, borderRadius, transitions, rgba, ellipsis } from 'polished';
import {
  Tab as MuiTab,
  Tabs as MuiTabs,
  TabProps as MuiTabProps,
  TabsProps as MuiTabsProps
} from '@material-ui/core';

export type TabProps = MuiTabProps;
export type TabsProps = MuiTabsProps;

export const Tabs = styled(
  React.memo(({ ...props }: MuiTabsProps) => (
    <MuiTabs {...props} classes={{ indicator: 'indicator' }} />
  ))
)`
  position: relative;
  min-height: ${rem('32px')};

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: ${rem('5px')};
    z-index: 0;
    box-shadow: inset 0 ${rem('-5px')} ${rem('5px')} ${rem('-5px')} #aeaeaf;
  }

  & .indicator {
    display: none;
  }
`;

const shadowTabColor = css`
  ${props => rgba(props.theme.colors.S['800'], 0.2)}
`;

export const Tab = styled(
  React.memo(({ ...props }: MuiTabProps) => (
    <MuiTab {...props} classes={{ selected: 'selected', wrapper: 'wrapper' }} />
  ))
)`
  min-height: ${rem('36px')};
  height: ${rem('36px')};
  ${borderRadius('top', rem('4px'))}
  margin: 0 ${rem('4px')};
  ${transitions('box-shadow .3s ease-in-out', 'all 0.2s ease')}
  color: ${props => props.theme.colors.S['800']};
  margin-top: ${rem('6px')};
  padding: 0 ${rem('16px')};
  font-weight: 300;
  font-size: ${rem('14px')};
  text-transform: initial;

  &:first-child {
    margin-left: ${rem('16px')};
  }

  &:last-child {
    margin-right: ${rem('16px')};
  }

  &:hover {
    box-shadow: 0 ${rem('-2px')} ${rem('6px')} 0 ${shadowTabColor};
  }

  & .wrapper {
    ${ellipsis('200px')};
  }

  &.selected {
    background-color: white;
    box-shadow: ${rem('-4px')} 0 ${rem('4px')} ${rem('-3px')} ${shadowTabColor}, ${rem(
  '4px'
)} 0 ${rem('4px')} ${rem('-3px')} ${shadowTabColor};
    border-bottom: none;
    font-weight: 600;

    &::before {
      content: '';
      height: ${rem('4px')};
      background-color: ${props => props.theme.colors.G['900']};
      ${borderRadius('top', rem('4px'))}
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: block;
    }
  }
`;
