import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { EmptyPlaceholder, EmptyPlaceholderProps } from 'components';
import noResultsPlaceholder from 'assets/images/product_not_found.svg';
import {
  Table,
  TableProps,
  TableBody,
  TableRow as MuiTableRow,
  TableRowProps as MuiTableRowProps,
  TableCell as MuiTableCell,
  TableCellProps as MuiTableCellProps,
  TableHead,
  TableSortLabel,
  Hidden
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export type OrderDirection = 'asc' | 'desc' | undefined;

export interface Column extends TableCellProps {
  key: string;
  name?: string;
  Component?: React.ReactNode;
  hidden?: boolean;
  backgroundColor?: string;
  sorteable?: boolean;
  sortKey?: string;
}

export interface TableCellProps extends MuiTableCellProps {
  contentType?: 'number' | 'genericTotal';
  truncate?: boolean;
}

export interface TableRowProps extends MuiTableRowProps {
  disabled?: boolean;
}

export interface ClassicTableProps extends TableProps {
  columns: Array<Column>;
  data: any;
  renderRow(item: any): void;
  loading: boolean;
  LoadingComponent?: React.ReactNode;
  emptyPlaceholderProps?: EmptyPlaceholderProps;
  TableEmptyComponent?: React.ReactNode;
  ToolbarComponent?: React.ReactNode;
  NoResultsComponent?: React.ReactNode;
  showToolbar?: boolean;
  search: string;
  noResultsText?: string;
  onColumnSort?: (
    event: React.MouseEvent<unknown>,
    orderBy: string,
    orderDirection: OrderDirection
  ) => void;
  sorts?: string;
}

const StyledTable = styled(Table)<{ columns: Array<Column> }>`
  ${props =>
    props.columns.map((column, index) => {
      const columnNumber = index + 1;
      return css`
        tr > *:nth-child(${(index += 1)}) {
          ${column.hidden && 'display: none;'}
          ${column.backgroundColor && `background-color: ${column.backgroundColor};`}
        }
      `;
    })}
`;

const StyledTableHead = styled(TableHead)`
  text-transform: uppercase;
  & .MuiTableCell-stickyHeader {
    background-color: white;
  }
  
    
`;

export const TableCell = styled(({ children, contentType, ...restProps }: TableCellProps) => (
  <MuiTableCell classes={{ head: 'head' }} {...restProps}>
    {children}
  </MuiTableCell>
))`
  font-size: ${rem('12px')};
  color: ${props => props.theme.colors.S['800']};
  height: ${rem('52px')};

  ${props =>
    props.contentType === 'number' &&
    css`
      color: ${props.theme.colors.B['800']};
      font-weight: 600;
    `}

  ${props =>
    props.contentType === 'genericTotal' &&
    css`
      font-weight: 600;
      font-size: ${rem('14px')};
    `}

  ${props =>
    props.truncate &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 0;
    `}

  &.head {
    & .MuiTableSortLabel-root{
      color: #666666
    }
  }

  & .text {
    cursor: text;
  }
`;

export const TableRow = styled(({ children, ...restProps }: TableRowProps) => (
  <MuiTableRow classes={{ hover: 'hover' }} {...restProps}>
    {children}
  </MuiTableRow>
))`
  &.hover {
    cursor: pointer;
  }
`;

const EmptyListContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

const NoResultsContainer = styled(EmptyListContainer)`
  margin-top: ${rem('90px')};
  height: auto;
`;

const ToolbarWrapper = styled.div<{ visible: boolean }>`
  position: sticky;
  visibility: ${props => (props.visible ? 'initial' : 'hidden')};
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export function ClassicTable({
  columns,
  data,
  renderRow,
  loading,
  LoadingComponent,
  TableEmptyComponent,
  ToolbarComponent,
  NoResultsComponent,
  showToolbar = false,
  search,
  emptyPlaceholderProps,
  noResultsText,
  onColumnSort,
  sorts,
  ...restProps
}: ClassicTableProps) {
  const emptyData = data === undefined || data.length === 0;
  const orderBy = sorts && sorts.includes(':') ? sorts.split(':')[0] : undefined;
  const orderDirection =
    sorts && sorts.includes(':') ? (sorts.split(':')[1] as OrderDirection) : undefined;

  if (loading && LoadingComponent) {
    return <EmptyListContainer>{LoadingComponent}</EmptyListContainer>;
  }

  if (emptyData && !search) {
    return (
      <EmptyListContainer>
        {emptyPlaceholderProps !== undefined ? (
          <EmptyPlaceholder {...emptyPlaceholderProps} />
        ) : (
          TableEmptyComponent
        )}
      </EmptyListContainer>
    );
  }

  const handleRequestSort = (orderBy: string, orderDirection: OrderDirection) => (
    event: React.MouseEvent<unknown>
  ) => {
    if (onColumnSort) {
      onColumnSort(event, orderBy, orderDirection === 'asc' ? 'desc' : 'asc');
    }
  };

  return (
    <>
      <ToolbarWrapper visible={showToolbar}>{ToolbarComponent}</ToolbarWrapper>
      <StyledTable columns={columns} {...restProps}>
        <Hidden xsDown>
          <StyledTableHead>
            <MuiTableRow hidden={!showToolbar}>
              {columns.map(
                ({ name, Component, key, sorteable, sortKey, ...restColumnCellProps }, index) =>
                  (name !== undefined || Component) && (
                    <TableCell
                      key={index}
                      sortDirection={
                        sorteable && orderBy !== undefined && orderBy === sortKey
                          ? orderDirection
                          : false
                      }
                      {...restColumnCellProps}
                    >
                      {sorteable ? (
                        <TableSortLabel
                          active={orderBy !== undefined ? orderBy === sortKey : true}
                          direction={
                            orderBy !== undefined && orderBy === sortKey ? orderDirection : 'desc'
                          }
                          IconComponent={ExpandMoreIcon}
                          onClick={handleRequestSort(sortKey || '', orderDirection)}
                        >
                          {name !== undefined ? name : Component}
                        </TableSortLabel>
                      ) : name !== undefined ? (
                        name
                      ) : (
                        Component
                      )}
                    </TableCell>
                  )
              )}
            </MuiTableRow>
          </StyledTableHead>
        </Hidden>
        <TableBody>{data.map((item: any) => renderRow(item))}</TableBody>
      </StyledTable>
      {emptyData && search && (
        <NoResultsContainer>
          {noResultsText !== undefined ? (
            <EmptyPlaceholder image={noResultsPlaceholder} title={noResultsText} />
          ) : (
            NoResultsComponent
          )}
        </NoResultsContainer>
      )}
    </>
  );
}
