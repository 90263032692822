import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${rem('52px')};
  background-color: ${props => props.theme?.colors?.G[500] || "#FFB700"};
`;

const Message = styled.span`
  display: block;
  padding-left: ${rem('16px')};
  font-size: ${rem('14px')};
`;

export interface AlertBannerProps {
  message: string;
  rightComponent: React.ReactNode;
}

export function AlertBanner({ message, rightComponent }: AlertBannerProps) {
  return (
    <Wrapper>
      <Message>{message}</Message>
      {rightComponent}
    </Wrapper>
  );
}
