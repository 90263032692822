// @ts-nocheck
import React, { useContext, useState, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Modal, Button, Radio, Textarea, Spinner, Box } from 'components';
import { PoHeaderExtract } from './po-header-extract';

// TODO typecheck this component

// interface ReasonProps {
//   value: string | number;
//   label: string;
//   selected: boolean;
// }

// interface ReasonSectionProps {
//   onChange(): void;
//   selected: boolean;
//   error: boolean;
// }

// interface TextAreaSectionProps {
//   onChange(): void;
// }

const Label = styled.span<{ error?: boolean }>`
  color: ${props => (props.error ? props.theme.colors.Ma[400] : props.theme.colors.S[800])};
  font-size: ${rem('16px')};
`;

const ErrorMessage = styled(Label)`
  flex: 1;
  padding-left: ${rem('8px')};
`;

let moderationReasons = [];

function Reason({ value, label, selected }) {
  return (
    <Box display="flex" alignItems="center">
      <Radio value={value} checked={selected == value} />
      <Label>{label}</Label>
    </Box>
  );
}

function ReasonsSection({ onChange, selected, error, reasons }) {
  const { t } = useTranslation();

  return (
    <Box paddingY="8px">
      <Label error={error}>{t('summary:modals.reject.reason_section_title')}</Label>
      <RadioGroup onChange={onChange}>
        <Grid container>
          {reasons.map(reason => (
            <Grid item xs={6} key={reason.label}>
              <Reason label={t(reason.label)} value={reason.value} selected={selected} />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </Box>
  );
};

function TextAreaSection({ onChange }) {
  const { t } = useTranslation();

  return (
    <Box key="textarea" paddingY="8px">
      <Textarea
        key="textarea"
        fullWidth
        placeholder={t('summary:modals.reject.textarea_placeholder')}
        label={t('summary:modals.reject.textarea_placeholder')}
        multiline
        rows={6}
        rowsMax={6}
        onChange={onChange}
      />
    </Box>
  );
};

const useValue = initialValue => {
  const [value, setValue] = useState();
  const onChange = event => setValue(event.target.value);
  const clear = () => setValue(initialValue);

  return {
    value,
    onChange,
    clear
  };
};

export function RejectModal({ visible, onClose, onReject, extract, loading, success, reasons }) {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const comment = useValue('');
  const reason = useValue(undefined);
  const [error, setError] = useState(false);

  moderationReasons = reasons?.map(reason => {
    return { label: 'summary:modals.reject.reasons.' + reason.name, value: reason.id }
  });

  const clearState = () => {
    reason.clear();
    comment.clear();
    setError(false);
  };

  const onClickReject = () => {
    const { value: reasonVal } = reason;

    if (reasonVal) {
      clearState();
      onReject({ comment: comment.value, reason: reasonVal });
    } else {
      setError(true);
    }
  };

  const onClickClose = () => {
    clearState();
    onClose();
  };

  useEffect(() => {
    setError(false);
  }, [comment.value, reason.value]);

  return (
    <Modal
      fullWidth
      maxWidth={success ? 'sm' : 'md'}
      open={visible}
      onClose={onClickClose}
      loading={loading}
      LoadingComponent={<Spinner label={t('summary:modals.reject.loading_message')} />}
    >
      <Modal.Title>{t('summary:modals.reject.title')}</Modal.Title>
      <Modal.Content>
        {success ? (
          <Label>{t('summary:modals.reject.success.message', { po: extract[0].value })}</Label>
        ) : (
          <>
            {' '}
            <PoHeaderExtract extract={extract} />
            <ReasonsSection onChange={reason.onChange} selected={reason.value} error={error} reasons={moderationReasons} />
            <TextAreaSection onChange={comment.onChange} />
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        {success ? (
          <Button colorName={theme.colors.Mi} onClick={onClose}>
            {t('summary:modals.close_button')}
          </Button>
        ) : (
          <>
            {error && <ErrorMessage error>{t('summary:modals.reject.error.message')}</ErrorMessage>}
            <Button colorName={theme.colors.S[700]} onClick={onClickClose}>
              {t('summary:modals.cancel_button')}
            </Button>
            <Button colorName={theme.colors.Ma} onClick={onClickReject}>
              {t('summary:modals.reject.button')}
            </Button>
          </>
        )}
      </Modal.Actions>
    </Modal>
  );
};
