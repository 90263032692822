import React from 'react'
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {Box} from 'components';
import { makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    progress: {
        float: "right",
        position: "relative",
        top: "23px",
        right: "24px",
    },
    circle: {
        strokeLinecap: "round"
    },
    progressNumber:{
        letterSpacing: "3px",
    },
    progressBox:{
        position:"absolute",
        left:"15px",
        bottom: "22px",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
    },
    bottom: {
        color: "#D1D1D1",
        width: "53px !important",
        height: "53px !important",
    },
    top: {
        color: "#07AD4B",
        animationDuration: "550ms",
        position: "absolute",
        left: 0,
        width: "53px !important",
        height: "53px !important",
    },
  }));

type CircularProgressProps = {
    step: string; 
    value: number  
}   

export const CircularProgres = ({step, value}: CircularProgressProps) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.progress}>
                <CircularProgress
                    variant="determinate"
                    className={classes.bottom}
                    size={40}
                    thickness={4}
                    value={100}
                />
                <CircularProgress
                    variant="static"
                    disableShrink
                    className={classes.top}
                    classes={{
                        circle: classes.circle
                    }}
                    size={40}
                    value={value}
                    thickness={4}            
                />
                <Box className={classes.progressBox}>
                <Typography
                    variant="caption"
                    component="div"
                    className={classes.progressNumber}                    
                >{step}</Typography>
                </Box>
            </div>
        </>
    )
}

 
