import React from 'react';
import styled from 'styled-components';
import * as SS from 'styled-system';
import { rem } from 'polished';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

export interface FilterChipProps extends React.HTMLAttributes<HTMLDivElement>, SS.MarginProps {
  label: string;
  value: string | number;
  onDismiss: () => void;
}

const FilterChipContainer = styled.div`
  display: inline-flex;
  height: ${rem('32px')};
  background-color: ${props => props.theme.colors.S[300]};
  padding: ${rem('7px')} ${rem('12px')};
  border-radius: ${rem('200px')};
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  ${SS.margin}
`;

const FilterChipLabel = styled.span`
  color: ${props => props.theme.colors.S[800]};
  font-size: ${rem('14px')};
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: ${props => props.theme.colors.S[600]};
  font-size: ${rem('17px')};
`;

const StyledIconButton = styled(IconButton)`
  margin-left: ${rem('12px')};
  padding: 0;
`;

export function FilterChip({ label, onDismiss, ...props }: FilterChipProps) {
  return (
    <FilterChipContainer {...props}>
      <FilterChipLabel>{label}</FilterChipLabel>
      <StyledIconButton onClick={onDismiss}>
        <StyledCloseIcon />
      </StyledIconButton>
    </FilterChipContainer>
  );
}
