import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { TextField, TextFieldProps as MuiTextFieldProps } from '@material-ui/core';

export type TextAreaProps = MuiTextFieldProps;

const StyledTextField = styled(({ textareaCustom, ...props }) => (
  <TextField
    classes={{ root: 'root' }}
    InputProps={{
      classes: {
        input: 'input',
        inputMultiline: 'multiline',
        error: 'error',
        disabled: 'disabled',
        focused: 'focused',
        notchedOutline: 'notched-outline'
      }
    }}
    InputLabelProps={{
      classes: { root: 'label', focused: 'label-focused', error: 'label-error' }
    }}
    FormHelperTextProps={{
      classes: { root: 'helper-text', error: 'helper-text-error' }
    }}
    {...props}
  />
))`
  color: ${props => props.theme.colors.S[800]};

  font-size: ${props => props.textareaCustom ? '16px' : '14px'};
  
  & .MuiOutlinedInput-notchedOutline {
    border: ${rem('1px')} solid ${props => props.textareaCustom ? props.theme.colors.S[600] : props.theme.colors.S[500]};

    :hover {
      border-color: ${props => props.textareaCustom ? props.theme.colors.S[600] : props.theme.colors.S[500]};
    }
  }

  & .input {
    ::placeholder {
      color: ${props => props.textareaCustom ? props.theme.colors.S[600] : props.theme.colors.S[500]};
    }
  }

  & .MuiOutlinedInput-root {
    padding: ${rem('16px')};
  }
  
  & .focused {
    & .MuiOutlinedInput-notchedOutline {
      border: ${rem('2px')} solid ${props => props.textareaCustom ? props.theme.colors.Mi[400] : props.theme.colors.Mi[500]};
    }
  }

  & .error {
    & .MuiOutlinedInput-notchedOutline {
      border: ${rem('2px')} solid ${props => props.theme.colors.Ma[400]};
    }
  }

  & .disabled {
    & .MuiOutlinedInput-notchedOutline {
      border: ${rem('1px')} solid ${props => props.theme.colors.S[500]};
    }

    background-color: ${props => props.theme.colors.S[300]};
  }

  & .label {
    color: ${props => props.theme.colors.S[500]};
    
    :hover {
      border: ${rem('1px')} solid ${props => props.theme.colors.S[700]};
    }
  }

  & .label-focused {
    color: ${props => props.theme.colors.Mi[400]};
  }

  & .label-error {
    color: ${props => props.textareaCustom ? props.theme.colors.S[600] : props.theme.colors.S[400]};
  }

  & .helper-text {
    color: ${props => props.textareaCustom ? props.theme.colors.S[600] : props.theme.colors.S[500]};
  }

  & .helper-text-error {
    color: ${props => props.theme.colors.Ma[400]};
  }
`;


export function Textarea(props: TextAreaProps & { textareaCustom?: boolean }) {
  const { textareaCustom } = props;
  return (
    <StyledTextField
      multiline
      variant="outlined"
      {...props}
      textareaCustom={ textareaCustom !== undefined }
    />
  );
}
