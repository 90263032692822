import React, { useContext, createContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { rem } from 'polished';
import { TextField, IconButton, TextFieldProps, InputAdornment } from '@material-ui/core';
import { Search, Close } from '@material-ui/icons';
import { Icon } from 'components';

type SearchBoxVariant = 'standard' | 'outlined';

export interface SearchBoxProps {
  variant?: SearchBoxVariant;
  value: string;
  placeholder: string;
  onClear(): void;
  onSearch?(val: string | undefined): void;
}

const StyledTextField = styled(({ inputProps, ...props }) => (
  <TextField
    {...props}
    InputProps={{
      classes: { input: 'input' },
      ...inputProps
    }}
  />
))`
  & .input {
    font-size: ${rem('15px')};
    color: ${props => props.theme.colors.S['800']};
    padding: ${rem('8px')} ${rem('8px')};

    ::placeholder {
      color: ${props => props.theme.colors.S['600']};
    }
  }

  & .MuiOutlinedInput-root {
    height: ${rem('40px')};

    & .MuiOutlinedInput-input {
      padding: 0 ${rem('16px')};
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.colors.S['400']};
    }

    &:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.colors.S['400']};
    }
  }

  & .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }

  & .MuiOutlinedInput-adornedStart {
    padding-left: 0;
  }

  & .Mui-focused {
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.colors.Mi['400']};
    }
  }

  & .MuiInput-underline {
    &::before {
      border-bottom: ${rem('1px')} solid ${props => props.theme.colors.S['600']};
    }

    &:hover::before {
      border-bottom-color: ${props => props.theme.colors.S['600']};
    }

    &::after {
      border-bottom-color: ${props => props.theme.colors.S['800']};
    }
  }
`;

const StyledIconButton = styled(IconButton)<{ transparent?: boolean }>`
  ${props =>
    props.transparent &&
    css`
      &:hover {
        background-color: transparent;
      }
    `}
  border-radius: initial;
  border-top-right-radius: ${rem('4px')};
  border-bottom-right-radius: ${rem('4px')};
  padding: ${rem('9px')};

  &:hover {
    background-color: ${props => props.theme.colors.Mi[100]};
  }

  &:active {
    background-color: ${props => props.theme.colors.Mi[200]};
  }

  ${props =>
    props.transparent &&
    css`
      &:hover {
        background-color: transparent;
      }

      &:active {
        background-color: transparent;
      }
    `}
`;

type SearchContextProps = {
  variant: SearchBoxVariant;
  value: string;
  onSearch(val: string | undefined): void;
  onClear(): void;
};

const SearchBoxContext = createContext<Partial<SearchContextProps>>({
  value: ''
});

function SearchIconButton() {
  const theme = useContext(ThemeContext);
  const { onSearch, value } = useContext(SearchBoxContext);
  const onClickSearch = (val: string | undefined) => (_e: any) => {
    if (onSearch) {
      onSearch(val);
    }
  };

  return (
    <StyledIconButton disableRipple disabled={value?.length === 0} onClick={onClickSearch(value)}>
      <Icon Component={Search} color={theme.colors.Mi[400]} />
    </StyledIconButton>
  );
}

function StartAdornment() {
  const { variant } = useContext(SearchBoxContext);
  if (variant === undefined || variant === 'standard') {
    return (
      <InputAdornment position="start">
        <Icon Component={Search} />
      </InputAdornment>
    );
  }
  return null;
}

function EndAdornment() {
  const { variant, onClear, value } = useContext(SearchBoxContext);
  if (value) {
    return (
      <>
        {value.length > 0 && (
          <InputAdornment position="end">
            <StyledIconButton transparent disableRipple aria-label="clear" onClick={onClear}>
              <Icon Component={Close} />
            </StyledIconButton>
          </InputAdornment>
        )}
        {variant === 'outlined' ? <SearchIconButton /> : null}
      </>
    );
  }else{
    return (
      <>
        {variant === 'outlined' ? <SearchIconButton /> : null}
      </>
    );
  }

  return null;
}

export const SearchBox = React.memo(function SearchBox({
  value,
  onClear,
  variant,
  onSearch,
  ...props
}: SearchBoxProps & TextFieldProps) {
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (onSearch) {
        onSearch(value);
      }
    }
  };

  return (
    <SearchBoxContext.Provider value={{ variant, value, onSearch, onClear }}>
      <StyledTextField
        {...props}
        variant={variant}
        fullWidth
        value={value}
        inputProps={{
          startAdornment: <StartAdornment />,
          endAdornment: <EndAdornment />
        }}
        onKeyPress={variant === 'outlined' ? handleKeyPress : undefined}
      />
    </SearchBoxContext.Provider>
  );
});
