import i18n from 'i18next';
import moment from 'moment';
import 'moment/locale/es';
import { initReactI18next } from 'react-i18next';
import resources from './resources';

i18n.use(initReactI18next).init({
  resources: { ...resources },
  lng: 'es',
});

moment.locale(i18n.language);

export default i18n;
