/* eslint-disable camelcase */
import find from 'lodash/find';
import { SELL_BY_UNIT_VALIDATE_BY_WEIGHT, SELL_BY_WEIGHT_VALIDATE_BY_WEIGHT } from '../constants';

export const productSubtotal = ({ aptFractionable, price, saleUnit, quantity }) => {
  if (aptFractionable) {
    return (price / saleUnit) * quantity;
  }

  return price * quantity;
};

export const getItemTrace = ({ trace, status }) => {
  let itemTrace;

  if (trace) {
    if (status) {
      itemTrace = find(trace, { delivery_status_id: status });
    } else {
      itemTrace = trace[trace.length - 1];
    }
  }

  return itemTrace;
};

export const getSpecifications = ({
  bulk_presentation,
  bulk,
  presentations,
  sales_unit = "",
  measure_unit = "",
  presentation = "",
  measureUnit
}) => {
  const specifications = [];

  if (bulk) {
    specifications.push(`${bulk.description}: ${bulk_presentation}`);
  }

  if (presentations) {
    specifications.push(
      `${presentations.description} x ${sales_unit ?? presentation} ${
        measureUnit ? measureUnit.unit : measure_unit
      }`
    );
  }

  return specifications.length > 0 ? specifications.join(' ') : presentation;
};

export const getFullPresentation = ({ item }) => {
  return item.mainData.product_full_presentation;
};

export const getMeasureUnit = ({ item, quantity }) =>
   item.product.fractionable && /\d/.test(item.mainData.requested_measure_unit)
    ? item.product.bulk_id && /^\d+$/.test(item.product.bulk_presentation)
      ? item.mainData.requested_measure_unit.replace(
          /\d+/,
          item.product.bulk_presentation * quantity
        )
      : item.mainData.requested_measure_unit.replace(/\d+/, item.product.sales_unit * quantity)
    : item.mainData.requested_measure_unit;
