import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import * as SS from 'styled-system';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@material-ui/core';

export interface ButtonProps extends MuiButtonProps, SS.SpaceProps {
  children: React.ReactNode;
  colorName: any;
  isConfirmModified?: string;
}

const StyledButton = styled(
  React.forwardRef<HTMLButtonElement, ButtonProps>(({ colorName, isConfirmModified,...props }, ref) => (
    <MuiButton
      {...props}
      ref={ref}
      classes={{ contained: 'contained', outlined: 'outlined', text: 'text', disabled: 'disabled' }}
    />
  ))
)`
  box-shadow: none;
  font-weight: 600;
  letter-spacing: 0;
  white-space: nowrap;
  ${SS.space}

  &.contained {
    color: white;
    box-shadow: none;
    background-color: ${props => props.colorName[500]};

    &:hover {
      background-color: ${props => props.colorName[300]};
    }

    &:active {
      background-color: ${props => props.colorName[500]};
      box-shadow: none;
    }
  }

  &.text {
    color: ${props => props.isConfirmModified ? props.isConfirmModified : props.colorName[800]};
    background-color: white;

    &:hover {
      background-color: ${props => props.isConfirmModified ? props.isConfirmModified : props.colorName[300]};
      color: ${props => props.isConfirmModified ? 'white' : props.colorName[800]};
    }

    &:active {
      background-color: ${props => props.colorName[400]};
    }
  }

  &.outlined {
    color: ${props => props.colorName[400]};
    background-color: white;
    border: ${rem('1px')} solid ${props => props.colorName[400]};

    &:hover {
      background-color: ${props => props.colorName[100]};
    }

    &:active {
      background-color: ${props => props.colorName[200]};
    }
  }

  &.disabled {
    color: ${props => props.theme.colors.S['400']};
    background-color: ${props =>
      props.variant === 'contained' ? props.theme.colors.S['300'] : 'white'};
    border-color: ${props => props.theme.colors.S['400']};
  }
`;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, isConfirmModified,...props }, ref) => (
    <StyledButton ref={ref} isConfirmModified={isConfirmModified} {...props}>
      {children}
    </StyledButton>
  )
);
