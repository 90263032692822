import React from 'react';
import styled from 'styled-components';
import { Spinner } from './spinner';

const SpinnerWrapper = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export function PageSpinner() {
  return (
    <SpinnerWrapper>
      <Spinner label="Cargando..." />
    </SpinnerWrapper>
  );
}
