// @ts-nocheck
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';
import InputAdornment from '@material-ui/core/InputAdornment';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddCircle from '@material-ui/icons/AddCircle';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import i18n from "../common/language/i18n";

export interface QuantityInputProps {
  fractionable: boolean;
  measureUnit: string;
  initialValue: string;
  onChange(val: any): any;
}

const Container = styled.div`
  display: block;
  margin: ${rem('10px')} ${rem('0px')};
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTextField = styled(({ fractionable, ...props }) => <TextField {...props} />)`
  background-color: white;
  border-radius: 4px;

  & .MuiOutlinedInput-input {
    width: ${props => rem(props.fractionable ? '55px' : '34px')};
    height: ${rem('24px')};
    padding: ${rem('1px')} ${rem('2px')};
    text-align: ${props => (props.fractionable ? 'right' : 'center')};
    color: ${props => props.theme.colors.S['800']};
    font-size: ${rem('14px')};
  }

  div {
    padding-right: ${rem('4px')};
    margin: 0;

    p {
      font-size: ${rem('14px')};
      color: ${props => props.theme.colors.S[800]};
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  padding: ${rem('4px')};
  margin: ${rem('2px')};
  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`;

const StyledMessage = styled.div`
  margin: ${rem('7px')} ${rem('0px')} ${rem('0px')} ${rem('0px')};
`;

const StyledIcon = css`
  color: ${props => props.theme.colors.B['900']};
  font-size: ${rem('18px')};
`;

const StyledAddCircle = styled(AddCircle)`
  ${StyledIcon}
`;

const StyledRemoveCircle = styled(RemoveCircle)`
  ${StyledIcon}
`;

const MAX_FRACTIONABLE_NUMBER = 999.99;
const MAX_NON_FRACTIONABLE_NUMBER = 100000;

export function FractionableNumber(props: NumberFormatProps & { maxNumber?: number }) {
  const { inputRef, onChange, id, maxNumber, ...other } = props;
  const { i18n } = useTranslation();

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      id={`qtyInput-${id}`}
      thousandSeparator={i18n.language === 'en' ? ',' : '.'}
      onValueChange={values => {
        // @ts-ignore
        onChange({
          // @ts-ignore
          target: {
            value: values.value
          }
        });
      }}
      isAllowed={values => {
        const { formattedValue, floatValue } = values;
        const max = maxNumber !== undefined ? maxNumber : MAX_FRACTIONABLE_NUMBER;
        return formattedValue === '' || floatValue <= max;
      }}
      decimalSeparator={i18n.language === 'en' ? '.' : ','}
      decimalScale={2}
      fixedDecimalScale
      isNumericString
      allowNegative={false}
    />
  );
}

export function NonFractionableNumber({ inputRef, onChange, ...props }: NumberFormatProps) {
  return (
    <NumberFormat
      {...props}
      getInputRef={inputRef}
      onValueChange={values => {
        // @ts-ignore
        onChange({
          // @ts-ignore
          target: {
            value: values.value
          }
        });
      }}
      isAllowed={(values: NumberFormatValues) => {
        const { formattedValue, value } = values;
        // @ts-ignore
        return formattedValue === '' || value <= MAX_NON_FRACTIONABLE_NUMBER;
      }}
      decimalScale={0}
      isNumericString
      allowNegative={false}
    />
  );
}

export function QuantityInput({
  fractionable,
  initialValue,
  measureUnit,
  onChange,
  minValue,
  maxValue,
  itemId
}: QuantityInputProps) {
  const [quantity, setQuantity] = useState(initialValue);

  const MIN_VALUE = minValue ?? 0;
  const MAX_VALUE = fractionable
    ? MAX_FRACTIONABLE_NUMBER
    : (maxValue ?? MAX_NON_FRACTIONABLE_NUMBER);

  const quantityValue = fractionable
    ? parseFloat(quantity)
    : parseInt(quantity);

  const stepInterval = fractionable ? 0.5 : 1;
  const decreaseDisabled = quantity == MIN_VALUE;
  const increaseDisabled = quantityValue == MAX_VALUE;

  // @ts-ignore
  const setValue = value => {
    // @ts-ignore
    setQuantity(value);
    onChange(value);
  };

  const onDecrease = () => {
    const subtraction = quantityValue - stepInterval;

    if (subtraction > MIN_VALUE) {
      setValue(subtraction);
    } else {
      setValue(MIN_VALUE);

      if (MIN_VALUE > 0) {
        setMinQuantityMessage(itemId);
      }
    }
  };

  const onIncrease = () => {
    const sum = quantityValue + stepInterval;

    if (sum < MAX_VALUE) {
      setValue(sum);
    } else {
      setValue(MAX_VALUE);
      setMaxQuantityMessage(itemId);
    }
  };

  const onBlur = () => {
    if (!quantity) {
      setValue(initialValue);
    }
  };

  const setMinQuantityMessage = (elemId, message) => {
    const input = document.getElementById("msgItem"+elemId) as HTMLInputElement
    input.textContent = `${i18n.t('shared:min_quantity')}: ${MIN_VALUE} ${measureUnit}`;
  };

  const setMaxQuantityMessage = (elemId, message) => {
    const input = document.getElementById("msgItem"+elemId) as HTMLInputElement
    input.textContent = `${i18n.t('shared:max_quantity')}: ${MAX_VALUE} ${measureUnit}`;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value < MIN_VALUE) {
      setValue(MIN_VALUE);

      if (MIN_VALUE > 0) {
        setMinQuantityMessage(itemId);
      }

      return;
    }

    if (e.target.value > MAX_VALUE) {
      setValue(MAX_VALUE);
      setMaxQuantityMessage(itemId);
      return;
    }

    setValue(e.target.value);
  };

  return (
    <Container>
      <StyledContainer>
        <StyledIconButton onClick={onDecrease} disabled={decreaseDisabled}>
          <StyledRemoveCircle fontSize="inherit" color="inherit" />
        </StyledIconButton>
        <StyledTextField
          fractionable={fractionable}
          variant="outlined"
          value={quantity}
          onChange={handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">{measureUnit}</InputAdornment>,
            inputComponent: fractionable ? FractionableNumber : NonFractionableNumber
          }}
          onBlur={onBlur}
        />
        <StyledIconButton onClick={onIncrease} disabled={increaseDisabled}>
          <StyledAddCircle fontSize="inherit" color="inherit" />
        </StyledIconButton>
      </StyledContainer>
      <StyledMessage id={'msgItem'+itemId}>
      </StyledMessage>
    </Container>
  );
}
