import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, ThemeContext } from 'styled-components';
import * as SS from 'styled-system';
import { rem } from 'polished';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { useMenu } from 'common/utils/hooks';
import { Icon, FilterChip, FilterChipProps, Box, Button } from 'components';
import Popover from '@material-ui/core/Popover';

export interface FilterGroupProps extends SS.SpaceProps {
  label: string;
  filters: Array<FilterChipProps>;
  onClear: () => void;
}

const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    width: ${rem('440px')};
    box-shadow: ${props => props.theme.shadows[2]};
    margin-top: ${rem('8px')};
    min-height: ${rem('102px')};
    overflow: hidden;
  }
`;

const FilterGroupWrapper = styled.div`
  display: flex;
  height: ${rem('32px')};
  padding: ${rem('7px')} 0;
  border-radius: ${rem('200px')};
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.S[300]};
  ${SS.space}

  &:hover {
    background-color: ${props => props.theme.colors.S[400]};
    cursor: pointer;
  }
`;

const Counter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${rem('32px')};
  height: ${rem('32px')};
  border-radius: ${rem('16px')};
  background-color: ${props => props.theme.colors.S[600]};
  color: white;
`;

const Label = styled.span`
  color: ${props => props.theme.colors.S[800]};
  font-size: ${rem('14px')};
  padding: 0 ${rem('12px')};
`;

export function FilterGroup({ label, filters, onClear, ...props }: FilterGroupProps) {
  const menu = useMenu();
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();

  const handleClearFilters = () => {
    menu.handleClose();
    onClear();
  };

  const handleDismiss = (callback: () => void) => () => {
    menu.handleClose();
    callback();
  };

  const renderFilters = (margin = false) =>
    filters.map(filter => (
      <FilterChip
        {...filter}
        onDismiss={handleDismiss(filter.onDismiss)}
        marginRight="10px"
        marginBottom={margin ? '10px' : undefined}
      />
    ));

  if (filters.length <= 2) {
    return (
      <Box display="inline-flex" alignItems="center" justifyContent="center">
        {renderFilters()}
      </Box>
    );
  }

  return (
    <>
      <FilterGroupWrapper {...props} onClick={menu.handleMenu}>
        <Counter>{filters.length}</Counter>
        <Label>{label}</Label>
        {menu.open ? (
          <Icon Component={KeyboardArrowUp} pr={12} fontSize="26px" />
        ) : (
          <Icon Component={KeyboardArrowDown} pr={12} fontSize="26px" />
        )}
      </FilterGroupWrapper>
      <StyledPopover
        open={menu.open}
        anchorEl={menu.anchorEl}
        onClose={menu.handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box marginX="16px" flexWrap="wrap" display="flex" marginTop="10px">
          {renderFilters(true)}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          height="52px"
          padding="8px"
        >
          <Button variant="text" colorName={theme.colors.Mi} onClick={handleClearFilters}>
            {t('shared:actions.clean_filters')}
          </Button>
        </Box>
      </StyledPopover>
    </>
  );
}
