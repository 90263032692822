/* eslint-disable no-restricted-syntax */
// @ts-nocheck
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useLocation, useHistory, useRouteMatch, Prompt } from 'react-router-dom';
import useSWR, { ConfigInterface } from 'swr';
import { useAlert } from 'context';
import queryString from 'query-string';
import get from 'lodash/get';
import {
  ResponseType,
  Presentation,
  MeasureUnit,
  Bulk,
  SellAndValidationCondition,
  Family,
  Product
} from 'api';

export const useModal = () => {
  const [visibility, setVisibility] = useState(false);
  const toggle = useCallback(() => setVisibility(!visibility), [visibility]);

  return {
    visibility,
    toggle
  };
};

export const useInputValue = initialValue => {
  const [value, setValue] = useState(initialValue);
  const onChange = useCallback(event => setValue(event.target.value), [value]);
  const clear = () => setValue('');

  return {
    value,
    onChange,
    clear,
    setValue
  };
};

export const usePagination = metadata => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRow, setCurrentRow] = useState(20);
  const totalItems = get(metadata, 'pagination.total');
  const pageSize = get(metadata, 'pagination.per_page');
  const totalPages = get(metadata, 'pagination.total_pages');
  const onRowChange = useCallback(row => setCurrentRow(row), [currentRow]);
  const onPageChange = useCallback(page => setCurrentPage(page), [currentPage]);
  const reset = () => setCurrentPage(1);

  return {
    currentPage,
    currentRow,
    pageSize,
    totalPages,
    totalItems,
    onRowChange,
    onPageChange,
    reset
  };
};

export const useSorts = value => {
  const [sorts, setSorts] = useState(value);
  const reset = () => setSorts(undefined);
  const getSorts = () => sorts;
  const onSortChange = useCallback(value => setSorts(value));

  return {
    getSorts,
    onSortChange,
    reset
  };
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};

export const useMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    handleMenu,
    handleClose,
    anchorEl,
    open: Boolean(anchorEl)
  };
};

export const useScript = (url, options) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;

    for (const key in options) {
      if (Object.prototype.hasOwnProperty.call(options, key)) {
        script[key] = options[key];
      }
    }

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export const useRouter = () => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      back: history.goBack,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search),
        ...params
      },
      match,
      location,
      history
    };
  }, [params, match, location, history]);
};

export const usePrompt = (isBlocking, options = {}) => {
  const [state, setState] = useState({ confirm: false, location: '' });
  const { push } = useRouter();
  const { show } = useAlert();
  const { confirm, location } = state;

  const handleBlockedNavigation = location => {
    if (!confirm) {
      show({
        ...options,
        onConfirm: () => {
          setState({ ...state, confirm: true, location });
        }
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (confirm && location) {
      push(location.pathname);
    }
  }, [confirm, location]);

  const component = <Prompt when={isBlocking} message={handleBlockedNavigation} />;

  return component;
};

// @ts-check
export const usePresentations = () => {
  const { data, error } = useSWR<ResponseType<Presentation[]>>(`/products/presentations?rows=30`);

  return {
    presentations: data,
    isLoading: !error && !data,
    error
  };
};

export const useMeasureUnits = () => {
  const { data, error } = useSWR<ResponseType<MeasureUnit[]>>(`/products/measure-units`);

  return {
    measureUnits: data,
    isLoading: !error && !data,
    error
  };
};

export const useBulks = () => {
  const { data, error } = useSWR<ResponseType<Bulk[]>>(`/products/bulks`);

  return {
    bulks: data,
    isLoading: !error && !data,
    error
  };
};

export const useFamilies = () => {
  const { data, error } = useSWR<ResponseType<Family[]>>(`/products/families?sorts=name:asc`);

  return {
    families: data,
    isLoading: !error && !data,
    error
  };
};

export const useSellAndValidationConditions = () => {
  const { data, error } = useSWR<ResponseType<SellAndValidationCondition[]>>(
    `/products/conditions`
  );

  return {
    sellAndValidationConditions: data,
    isLoading: !error && !data,
    error
  };
};

export const useProduct = (id: string, shouldFetch?: boolean, config?: ConfigInterface) => {
  const { data, error, mutate: mutateProduct } = useSWR<ResponseType<Product>>(
    shouldFetch !== undefined ? (shouldFetch ? `/products/${id}` : null) : `/products/${id}`,
    config
  );

  return {
    product: data,
    isLoading: !error && !data,
    error,
    mutateProduct
  };
};

export const usePartnerPriceLists = (partnerId: string | number, config?: ConfigInterface) => {
  const { data, error, mutate: mutatePartnerPriceLists } = useSWR<ResponseType<PartnerPriceList[]>>(
    () => `/partners/${partnerId}/partner-price-lists`,
    config
  );

  return {
    partnerPriceLists: data,
    isLoading: !error && !data,
    error,
    mutatePartnerPriceLists
  };
};

export const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = value => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);

      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
};
