import React from 'react';
import styled from 'styled-components';

export interface ResponsiveImageProps {
  width: number;
  height: number;
  src: string;
}

const Container = styled.div<{ width: number }>`
  position: relative;
  max-width: 100%;
  width: ${props => `${props.width}px`};
  margin: 24px;
`;

const Padding = styled.div<{ width: number; height: number }>`
  padding-bottom: ${props => `${(props.height / props.width) * 100}%`};
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export function ResponsiveImage({ src, width, height }: ResponsiveImageProps) {
  return (
    <Container width={width}>
      <Padding width={width} height={height} />
      <Image src={src} />
    </Container>
  );
}
