import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import * as SS from 'styled-system';

export interface IconProps extends SS.SpaceProps, SS.LayoutProps, SS.FontSizeProps {
  Component: React.ElementType;
  color?: string;
}

export const Icon = styled(({ Component, color, ...props }: IconProps) => (
  <Component color={color} {...props} />
))`
  font-size: ${rem('20px')};
  fill: currentColor;
  color: ${props => props.color ?? props.theme.colors.S[800]};
  ${SS.space}
  ${SS.fontSize}
  ${SS.layout}
`;
