import { makeActionCreator } from 'common/utils/helpers';

const PURCHASE_ORDERS_REQUEST = 'summary/PURCHASE_ORDERS_REQUEST';
const PURCHASE_ORDERS_RESPONSE = 'summary/PURCHASE_ORDERS_RESPONSE';
const PURCHASE_ORDERS_FILTERS_REQUEST = 'summary/PURCHASE_ORDERS_FILTERS_REQUEST';
const PURCHASE_ORDERS_FILTERS_RESPONSE = 'summary/PURCHASE_ORDERS_FILTERS_RESPONSE';
const PURCHASE_ORDERS_DOWNLOAD_FILE = 'summary/PURCHASE_ORDERS_DOWNLOAD_FILE';
const RESET_MODALS = 'summary/RESET_MODALS';
const SET_TAB = 'summary/SET_TAB';
const SET_FILTER_CURRENT = 'summary/SET_FILTER_CURRENT';
const FILLTERS_ALL_PURCHASE_ORDERS = 'summary/FILLTERS_ALL_PURCHASE_ORDERS';
const SELECT_ALL_PURCHASE_ORDERS = 'summary/SELECT_ALL_PURCHASE_ORDERS';
const SELECT_PURCHASE_ORDER = 'summary/SELECT_PURCHASE_ORDER';
const CLEAR_ALL_SELECTED = 'summary/CLEAR_ALL_SELECTED';
const DOWNLOAD_BULK_PDF_REQUEST = 'summary/DOWNLOAD_BULK_PDF_REQUEST';
const DOWNLOAD_BULK_PDF_RESPONSE = 'summary/DOWNLOAD_BULK_PDF_RESPONSE';

const SET_VISIBLE_COLUMNS = 'summary/SET_VISIBLE_COLUMNS';

export const purchaseOrdersRequest = makeActionCreator(PURCHASE_ORDERS_REQUEST, 'request');
export const purchaseOrdersResponse = makeActionCreator(PURCHASE_ORDERS_RESPONSE, 'response');
export const resetModals = makeActionCreator(RESET_MODALS, '');
export const purchaseOrdersFiltersRequest = makeActionCreator(PURCHASE_ORDERS_FILTERS_REQUEST, '');
export const purchaseOrdersFiltersResponse = makeActionCreator(PURCHASE_ORDERS_FILTERS_RESPONSE, 'response');
export const purchaseOrdersDownloadFile = makeActionCreator(PURCHASE_ORDERS_DOWNLOAD_FILE, 'request');

const setTab = makeActionCreator(SET_TAB, 'position');
const setFiltersCurrent = makeActionCreator(SET_FILTER_CURRENT, 'filtersCurrent');
const filtersAllpurchaseOrders = makeActionCreator(FILLTERS_ALL_PURCHASE_ORDERS, 'filter');
const selectAllPurchaseOrders = makeActionCreator(SELECT_ALL_PURCHASE_ORDERS, '');
const selectPurchaseOrder = makeActionCreator(SELECT_PURCHASE_ORDER, 'id');
const clearAllSelectedPurchaseOrders = makeActionCreator(CLEAR_ALL_SELECTED, '');
const downloadBulkPDFRequest = makeActionCreator(DOWNLOAD_BULK_PDF_REQUEST, '');

const setVisibleColumns = makeActionCreator(SET_VISIBLE_COLUMNS,'column');

export const ActionsTypes = {
  PURCHASE_ORDERS_REQUEST,
  PURCHASE_ORDERS_RESPONSE,
  PURCHASE_ORDERS_FILTERS_REQUEST,
  PURCHASE_ORDERS_FILTERS_RESPONSE,
  PURCHASE_ORDERS_DOWNLOAD_FILE,
  RESET_MODALS,
  SET_TAB,
  SET_FILTER_CURRENT,
  FILLTERS_ALL_PURCHASE_ORDERS,
  SELECT_ALL_PURCHASE_ORDERS,
  SELECT_PURCHASE_ORDER,
  CLEAR_ALL_SELECTED,
  DOWNLOAD_BULK_PDF_REQUEST,
  DOWNLOAD_BULK_PDF_RESPONSE,
  SET_VISIBLE_COLUMNS
};

export const Actions = {
  purchaseOrdersRequest,
  purchaseOrdersResponse,
  purchaseOrdersFiltersRequest,
  purchaseOrdersFiltersResponse,
  purchaseOrdersDownloadFile,
  resetModals,
  setTab,
  setFiltersCurrent,
  filtersAllpurchaseOrders,
  selectAllPurchaseOrders,
  selectPurchaseOrder,
  clearAllSelectedPurchaseOrders,
  downloadBulkPDFRequest,
  setVisibleColumns
};
