/* eslint-disable camelcase */
import { call, put, takeLatest, cancelled, delay, takeEvery, select } from 'redux-saga/effects';
import axios from 'axios';
import i18n from 'common/language/i18n';
import { saveAs } from 'file-saver';
import { getFileName } from 'common/utils/helpers';
import { ActionsTypes, Actions } from '../actions';
import { getPurchaseOrders, getPurchaseOrdersFilters, downloadPurchaseOrders } from './SummaryAPI';
import { downloadBulkPDF, downloadBulkXLS } from 'api';
import * as GlobalSelectors from 'common/redux/global/selectors';
import * as Selectors from '../selectors';
import {
  PO_STATUS_PENDING,
  PO_STATUS_CONFIRMED,
  PO_STATUS_MODIFIED_CONFIRMED,
  PO_STATUS_READ,
  PO_STATUS_REJECTED,
  PO_STATUS_RETURNED,
  PO_STATUS_VALIDATED,
  PO_STATUS_NOT_DELIVERED,
  PO_STATUS_DELIVERED
} from 'common/utils/constants';
import { showSnackbar } from 'common/redux/global/reducer/snackbarSlice';

function* fetchPurchaseOrdersWorker(action) {
  yield delay(500);
  const source = axios.CancelToken.source();
  try {
    const response = yield call(getPurchaseOrders, action.request, source.token);
    const { data, meta } = response.data;
    yield put(Actions.purchaseOrdersResponse({ data, meta }));
  } catch (error) {
    console.log(error);
  } finally {
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

function* fetchFiltersWorker() {
  try {
    const response = yield call(getPurchaseOrdersFilters);
    const { data } = response.data;
    const allowedStatus = [
      PO_STATUS_PENDING,
      PO_STATUS_CONFIRMED,
      PO_STATUS_MODIFIED_CONFIRMED,
      PO_STATUS_READ,
      PO_STATUS_REJECTED,
      PO_STATUS_RETURNED,
      PO_STATUS_VALIDATED,
      PO_STATUS_NOT_DELIVERED,
      PO_STATUS_DELIVERED
    ];
    
    if (data) {
      const clients = data.clients.map(client => ({ label: client.name, value: client.id }));
      const zones = data.zones.map(zone => ({ label: zone.name, value: zone.value }));
      const delivery_point_times = data.delivery_point_times.map((times, index) => ({
        label: `${times.from} - ${times.to}`,
        value: index
      }));
      const status_id = data.order_delivery_status
        .map(status => ({
          label: i18n.t(`shared:status.${status.name}`, { context: 'female' }),
          value: status.id
        }))
        .filter(status => allowedStatus.includes(status.value));

      const filters = Object.assign({}, { clients }, { delivery_point_times }, { status_id }, { zones });

      yield put(Actions.purchaseOrdersFiltersResponse(filters));
    }
  } catch (error) {
    console.log(error);
  }
}

function* downloadFileWorker(action) {
  try {
    const currentUser = yield select(GlobalSelectors.currentUserSelector);
    const {user: { email }} = currentUser;
    const { type, filters } = action.request;

    yield call(downloadPurchaseOrders, type, filters);

    yield put(
      showSnackbar({
        message: i18n.t(`summary:download_pdf_success`, { email }),
        error: false
      })
    );
  } catch (error) {
    yield put(
      showSnackbar({
        message: i18n.t(`shared:system_error`),
        error: true
      })
    );
  }
}

function* downloadBulkPDFWorker(action) {
  try {
    const currentUser = yield select(GlobalSelectors.currentUserSelector);
    const {
      user: { email }
    } = currentUser;
    const selectedPuchaseOrders = yield select(Selectors.selectedPurchaseOrdersSelector);
    yield call(downloadBulkPDF, { ids: selectedPuchaseOrders });

    yield put(
      showSnackbar({
        message: i18n.t(`summary:download_pdf_success`, { email }),
        error: false
      })
    );
  } catch (error) {
    yield put(showSnackbar({ message: i18n.t(`shared:system_error`), error: true }));
  }
}

function* summarySaga() {
  yield takeLatest(ActionsTypes.PURCHASE_ORDERS_REQUEST, fetchPurchaseOrdersWorker);
  yield takeEvery(ActionsTypes.PURCHASE_ORDERS_FILTERS_REQUEST, fetchFiltersWorker);
  yield takeEvery(ActionsTypes.PURCHASE_ORDERS_DOWNLOAD_FILE, downloadFileWorker);
  yield takeEvery(ActionsTypes.DOWNLOAD_BULK_PDF_REQUEST, downloadBulkPDFWorker);
}

export default summarySaga;
