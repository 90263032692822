// @ts-nocheck
import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';
import { rem } from 'polished';
import { Modal, Button, ModalProps } from 'components';
import { useModal } from 'common/utils/hooks';

export const Label = styled.span`
  white-space: pre-line;
`;

const StyledModalContent = styled(props => <Modal.Content {...props} />)`
  line-height: ${rem('18px')};
`;

interface AlertProviderProps {
  children: React.ReactNode;
}

export interface AlertProps extends Omit<ModalProps, 'children' | 'open'> {
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  body: string;
  action?: string;
  bodyComponent?: React.ReactNode;
}

function Alert({
  visible,
  onClose,
  onConfirm,
  title,
  body,
  action,
  bodyComponent,
  ...props
}: AlertProps) {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <Modal fullWidth maxWidth="sm" open={visible} onClose={onClose} {...props}>
      <Modal.Title>{title}</Modal.Title>
      <Modal.Content>
        <Label>{body}</Label>
        {bodyComponent}
      </Modal.Content>
      <Modal.Actions>
        <Button colorName={theme.colors.Mi} onClick={onClose} variant="outlined">
          {t('shared:actions.cancel')}
        </Button>
        <Button colorName={theme.colors.Mi} onClick={onConfirm} variant="contained">
          {action ? action : t('shared:actions.accept')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

type AlertState = {
  title: string;
  body: string;
  action?: string;
  onConfirm: () => void;
  AlertProps?: AlertProps;
};

const initialState = {
  title: '',
  body: '',
  action: undefined,
  onConfirm: () => {}
};

export interface AlertProviderValue {
  show(options: AlertState);
}

const AlertContext = createContext<AlertProviderValue>(null);

export function AlertProvider({ children }: AlertProviderProps) {
  const alert = useModal();
  const [state, setState] = useState<AlertState>(initialState);

  const show = ({ title, body, action, onConfirm, ...props }) => {
    alert.toggle();
    setState({ ...state, title, body, action, onConfirm, ...props });
  };

  const onConfirm = () => {
    state.onConfirm();
    alert.toggle();
  };

  return (
    <>
      <AlertContext.Provider value={{ show }}>{children}</AlertContext.Provider>
      <Alert
        visible={alert.visibility}
        onClose={alert.toggle}
        bodyComponent={state?.AlertProps?.bodyComponent}
        {...{ ...state, onConfirm }}
      />
    </>
  );
}

export function useAlert() {
  const context = useContext(AlertContext);

  if (context === undefined) {
    throw new Error('useAlert must be used within a AlertProvider');
  }

  return context;
}
