import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from '@material-ui/core';

export interface SwitchProps extends MuiSwitchProps {
  trackColor?: string;
}

const StyledSwitch = styled(({ trackColor, ...props }: SwitchProps) => (
  <MuiSwitch
    {...props}
    classes={{ switchBase: 'switchBase', thumb: 'thumb', track: 'track', checked: 'checked' }}
  />
))`
  width: ${rem('55px')};
  height: ${rem('40px')};
  padding: ${rem('12px')};

  & .switchBase {
    padding: ${rem('11px')};
    color: ${props =>
      props.trackColor !== undefined ? props.trackColor : props.theme.colors.Mi[400]};
  }

  & .thumb {
    width: ${rem('18px')};
    height: ${rem('18px')};
    color: white;
    box-shadow: ${rem('1px')} ${rem('1px')} ${rem('4px')} ${props => props.theme.colors.S[700]};
  }

  & .track {
    opacity: 0.6;
    background-color: ${props => props.theme.colors.S[700]};
  }

  & .checked + .track {
    background-color: ${props =>
      props.trackColor !== undefined ? props.trackColor : props.theme.colors.Mi[400]};
    opacity: 0.6;
  }
`;

export function Switch(props: SwitchProps) {
  return <StyledSwitch {...props} />;
}

export default Switch;
