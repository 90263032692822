import { apiInstance } from 'common/utils/axios';

export const getPurchaseOrders = async (request, token) =>
  apiInstance.get('/deliveries', {
    cancelToken: token,
    params: request,
  });

export const changePurchaseOrderStatus = async (id, request) =>
  apiInstance({
    method: 'PATCH',
    url: `/deliveries/${id}`,
    data: request,
  });

export const confirmPurchaseOrder = async (id, request) =>
  apiInstance({
    method: 'POST',
    url: `/deliveries/${id}/confirm`,
    data: request,
  });

export const rejectPurchaseOrder = async (id, request) =>
  apiInstance({
    method: 'POST',
    url: `/deliveries/${id}/deny`,
    data: request,
  });

export const downloadPurchaseOrders = async (type, request) =>
  apiInstance({
    method: 'POST',
    url: '/deliveries/partners/export',
    data: request
  });

export const getPurchaseOrdersFilters = async () => apiInstance.get('/deliveries/filters');
