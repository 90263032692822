import { call, put, takeEvery, select } from 'redux-saga/effects';
import {
  changePurchaseOrderStatus,
  confirmPurchaseOrder,
  rejectPurchaseOrder
} from 'features/summary/sagas/SummaryAPI';
import * as Actions from '../actions';
import { authTokenSelector, currentUserSelector } from '../selectors';
import { getEntity, changePassword } from './GlobalAPI';

function* changePurchaseOrderStatusWorker(action) {
  try {
    const { id, context, ...rest } = action.payload;
    const response = yield call(changePurchaseOrderStatus, id, rest);
    const {
      data: { status }
    } = response.data;
    if (status && status.id) {
      yield put(
        Actions.changePurchaseOrderStatusResponse({ success: status.id == rest.state, context })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* confirmPurchaseOrderWorker(action) {
  try {
    const { id, context, ...rest } = action.payload;
    const response = yield call(confirmPurchaseOrder, id, rest);
    const {
      data: { status }
    } = response.data;
    if (status && status.id) {
      yield put(
        Actions.confirmPurchaseOrderResponse({ success: status.id === rest.status_id, context })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* rejectPurchaseOrderWorker(action) {
  try {
    const { id, context, ...rest } = action.payload;
    const response = yield call(rejectPurchaseOrder, id, rest);
    const {
      data: { status }
    } = response.data;
    if (status && status.id) {
      yield put(
        Actions.rejectPurchaseOrderResponse({ success: status.id === rest.status_id, context })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* manageSessionWorker(action) {
  try {
    const { token } = action.payload;
    const authToken = yield select(authTokenSelector);
    if (!token && !authToken) {
      yield put(Actions.userSignout());
    } else {
      if (!authToken) {
        yield put(Actions.setAuthToken(token));
      }

      const currentUser = yield select(currentUserSelector);
      if (!currentUser) {
        const response = yield call(getEntity);
        const { data } = response;
        yield put(Actions.setCurrentUser(data));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* userSignoutWorker(action) {
  try {
    window.location.href = `${process.env.REACT_APP_SCI_HOST_URL}/salir`;
    yield put(Actions.setAuthToken(undefined));
    localStorage.removeItem('state');
  } catch (error) {
    console.log(error);
  }
}

function* changeUserPasswordWorker(action) {
  try {
    const response = yield call(changePassword, action.payload);
    console.log(response);
    yield put(Actions.changeUserPassswordResponse(response.status === 200));
  } catch (error) {
    yield put(Actions.changeUserPassswordResponse(false));
  }
}

function* globalSaga() {
  yield takeEvery(Actions.changePurchaseOrderStatusRequest.toString(), changePurchaseOrderStatusWorker);
  yield takeEvery(Actions.confirmPurchaseOrderRequest.toString(), confirmPurchaseOrderWorker);
  yield takeEvery(Actions.rejectPurchaseOrderRequest.toString(), rejectPurchaseOrderWorker);
  yield takeEvery(Actions.manageSession.toString(), manageSessionWorker);
  yield takeEvery(Actions.userSignout.toString(), userSignoutWorker);
  yield takeEvery(Actions.changeUserPasswordRequest.toString(), changeUserPasswordWorker);
}

export default globalSaga;
