import React from 'react';
import styled from 'styled-components';
import { rem, rgba } from 'polished';
import { FullDeliveryPoint } from 'assets/icons';
import Typography from '@material-ui/core/Typography';
import { ButtonBase, ButtonBaseProps } from '@material-ui/core';

export interface DeliveryPointButtonProps extends ButtonBaseProps {
  title: string;
  subtitle: string;
  color: string;
}

const StyledButton = styled(({ color, ...props }) => (
  <ButtonBase {...props} classes={{ disabled: 'disabled' }} />
))`
  display: flex;
  border-radius: ${rem('4px')};
  height: ${rem('64px')};
  width: ${rem('312px')};
  background-color: white;
  margin: ${rem('8px')} 0;
  box-shadow: ${props => props.theme.shadows['1']};
  justify-content: flex-start;

  &:hover {
    background-color: ${props => rgba(props.color, 0.1)};
  }

  &:active {
    background-color: ${props => rgba(props.color, 0.1)};
    box-shadow: none;
  }

  &.disabled {
    background-color: ${props => props.theme.colors.S['400']};
    box-shadow: none;
  }
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Typography)`
  font-size: ${rem('14px')};
  font-weight: 600;
  color: ${props => props.theme.colors.B['800']};
  text-align: left;
  line-height: 1;
`;

const Subtitle = styled(Typography)`
  font-size: ${rem('13px')};
  color: ${props => props.theme.colors.B['800']};
  padding-top: ${rem('8px')};
  font-weight: 300;
  line-height: 1;
`;

const IconContainer = styled.div`
  position: relative;
  margin: ${rem('16px')};
`;

const DeliveryPointInitialLetter = styled.span`
  position: absolute;
  top: ${rem('5px')};
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  font-size: ${rem('12px')};
  bottom: 0;
  right: 0;
  left: 0;
`;

export function DeliveryPointButton({
  title,
  subtitle,
  color,
  ...props
}: DeliveryPointButtonProps) {
  return (
    <StyledButton color={color} {...props}>
      <IconContainer>
        <FullDeliveryPoint width={32} fill={color} />
        {title.length > 0 && (
          <DeliveryPointInitialLetter>{title.charAt(0)}</DeliveryPointInitialLetter>
        )}
      </IconContainer>
      <DetailContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </DetailContainer>
    </StyledButton>
  );
}
