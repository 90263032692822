// @ts-nocheck
import { createReducer } from "@reduxjs/toolkit";
import * as Actions from "../actions";

const initialState = {
  token: undefined,
  currentUser: undefined,
  changePassword: {
    isChanging: false,
    finished: false,
    error: false,
    visible: false
  }
};

const globalReducer = createReducer(initialState, {
  [Actions.setAuthToken]: (state, action) => {
    state.token = action.payload;
  },
  [Actions.setCurrentUser]: (state, action) => {
    state.currentUser = action.payload;
  },
  [Actions.changeUserPasswordRequest]: (state, action) => {
    state.changePassword.isChanging = true;
  },
  [Actions.changeUserPassswordResponse]: (state, action) => {
    state.changePassword.isChanging = false;
    state.changePassword.error = !action.payload;
    state.changePassword.finished = true;
  },
  [Actions.resetChangePasswordModal]: (state, action) => {
    state.changePassword.isChanging = false;
    state.changePassword.error = false;
    state.changePassword.finished = false;
  },
  [Actions.toggleChangePasswordModal]: (state, action) => {
    state.changePassword.visible = !state.changePassword.visible;
  },
});

export { globalReducer };