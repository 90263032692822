import React from 'react';
import {
  StatusRequested,
  StatusAprooved,
  StatusConfirmed,
  StatusDelivered,
  StatusDisapproved,
  StatusEliminated,
  StatusExpired,
  StatusModifiedConfirmed,
  StatusNotDelivered,
  StatusRead,
  StatusRejected,
  StatusReturned,
  StatusSent,
  StatusShipped,
  StatusValidated
} from 'assets/icons';
import theme from 'common/styles/extendeal-theme';
import i18n from 'common/language/i18n';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { rem } from 'polished';

export interface StatusBadgeProps {
  status:
    | 'requested'
    | 'aprooved'
    | 'disapproved'
    | 'expired'
    | 'sent'
    | 'read'
    | 'eliminated'
    | 'confirmed'
    | 'modified_confirmed'
    | 'rejected'
    | 'shipped'
    | 'not_delivered'
    | 'delivered'
    | 'validated';
  variant?: 'default' | 'uniform';
}

interface StatusIconProps {
  Svg: React.FC<React.SVGProps<SVGSVGElement>>;
  color: string;
}

const Container = styled.div<Omit<StatusBadgeProps, 'status'> & { color: string }>`
  position: relative;
  display: flex;
  height: ${rem('20px')};
  color: ${props => (props.variant === 'uniform' ? props.color : props.theme.colors.S['800'])};
  font-size: ${rem('12px')};
  align-items: center;
`;

const StatusLabel = styled(Typography)`
  font-weight: 400;
`;

const StatusIcon = ({ Svg, color }: StatusIconProps) => <Svg fill={color} height="20" />;

const colors = {
  requested: theme.colors.S['800'],
  aprooved: theme.colors.Lu['400'],
  disapproved: theme.colors.Ma['400'],
  expired: theme.colors.Ma['400'],
  sent: theme.colors.S['800'],
  read: theme.colors.Mi['400'],
  eliminated: theme.colors.Ma['400'],
  confirmed: theme.colors.Lu['400'],
  modified_confirmed: theme.colors.Lu['400'],
  rejected: theme.colors.Ma['400'],
  shipped: theme.colors.S['800'],
  not_delivered: theme.colors.Ma['400'],
  delivered: theme.colors.S['800'],
  validated: theme.colors.Lu['400'],
  returned: theme.colors.Ma['400']
};

const states = {
  requested: {
    label: i18n.t('shared:status.requested', { context: 'female' }),
    icon: <StatusIcon Svg={StatusRequested} color={colors.requested} />
  },
  aprooved: {
    label: i18n.t('shared:status.aprooved', { context: 'female' }),
    icon: <StatusIcon Svg={StatusAprooved} color={colors.aprooved} />
  },
  disapproved: {
    label: i18n.t('shared:status.disapproved', { context: 'female' }),
    icon: <StatusIcon Svg={StatusDisapproved} color={colors.disapproved} />
  },
  expired: {
    label: i18n.t('shared:status.expired', { context: 'female' }),
    icon: <StatusIcon Svg={StatusExpired} color={colors.expired} />
  },
  sent: {
    label: i18n.t('shared:status.sent', { context: 'female' }),
    icon: <StatusIcon Svg={StatusSent} color={colors.sent} />
  },
  read: {
    label: i18n.t('shared:status.read', { context: 'female' }),
    icon: <StatusIcon Svg={StatusRead} color={colors.read} />
  },
  eliminated: {
    label: i18n.t('shared:status.eliminated', { context: 'female' }),
    icon: <StatusIcon Svg={StatusEliminated} color={colors.eliminated} />
  },
  confirmed: {
    label: i18n.t('shared:status.confirmed', { context: 'female' }),
    icon: <StatusIcon Svg={StatusConfirmed} color={colors.confirmed} />
  },
  modified_confirmed: {
    label: i18n.t('shared:status.modified_confirmed', { context: 'female' }),
    icon: <StatusIcon Svg={StatusModifiedConfirmed} color={colors.modified_confirmed} />
  },
  rejected: {
    label: i18n.t('shared:status.rejected', { context: 'female' }),
    icon: <StatusIcon Svg={StatusRejected} color={colors.rejected} />
  },
  shipped: {
    label: i18n.t('shared:status.shipped', { context: 'female' }),
    icon: <StatusIcon Svg={StatusShipped} color={colors.shipped} />
  },
  ordered: {
    label: i18n.t('shared:status.ordered', { context: 'female' }),
    icon: <StatusIcon Svg={StatusSent} color={colors.shipped} />
  },
  not_delivered: {
    label: i18n.t('shared:status.not_delivered', { context: 'female' }),
    icon: <StatusIcon Svg={StatusNotDelivered} color={colors.rejected} />
  },
  delivered: {
    label: i18n.t('shared:status.delivered', { context: 'female' }),
    icon: <StatusIcon Svg={StatusDelivered} color={colors.delivered} />
  },
  validated: {
    label: i18n.t('shared:status.validated', { context: 'female' }),
    icon: <StatusIcon Svg={StatusValidated} color={colors.validated} />
  },
  returned: {
    label: i18n.t('shared:status.returned', { context: 'female' }),
    icon: <StatusIcon Svg={StatusReturned} color={colors.returned} />
  }
};

export function StatusBadge({ status, variant }: StatusBadgeProps) {
  const { icon, label } = states[status] || {};
  return (
    <Container variant={variant} color={colors[status]}>
      {typeof variant !== typeof undefined && variant === 'uniform' ? (
        <>
          <StatusLabel
            variant="inherit"
            color="inherit"
            style={{ fontSize: '16px', paddingRight: '8px' }}
          >
            {label}
          </StatusLabel>
          {icon}
        </>
      ) : (
        <>
          {icon}
          <StatusLabel
            variant="inherit"
            style={{ paddingLeft: '8px', color: colors[status] }}
          >
            {label}
          </StatusLabel>
        </>
      )}
    </Container>
  );
}

StatusBadge.Colors = colors;
