//  @ts-nocheck
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { AppThunk } from 'common/redux/store';
import { partnerIdSelector } from 'common/redux/global/selectors';
import { showSnackbar } from 'common/redux/global/reducer/snackbarSlice';
import { ResponseType, Product, PartnerPriceList, PriceList } from 'api/types';
import * as ProductAPI from 'api/product';
import * as PriceListAPI from 'api/partner-price-list';
import isNumber from 'lodash/isNumber';
import { IsMobile } from 'common/utils/helpers';

interface Filters {
  visibility: React.ReactText[];
  availability: React.ReactText[];
  merchandise_bonus: React.ReactText[];
  [key: string]: React.ReactText[];
}

interface ProductsState {
  isFetching: boolean;
  products: Product[];
  dataPriceList: PriceList;
  dataLinked: any;
  metadata: any;
  selectedProducts: number[];
  selectedPriceList: number[];
  selectedPriceListDetail: number[];
  error: string | null;
  visibleColumns: React.ReactText[];
  filters: Filters;
  lastRequest: Partial<ProductAPI.GetProductsRequest>;
  isFetchingPriceLists: boolean;
  priceLists: PartnerPriceList[];
  priceListsDetail: PartnerPriceList[];
  updatedPrices: PriceListAPI.BulkUpdatePriceListItem[];
  isSaving: boolean;
  sinceIndex: number;
  toIndex: number;
  visiblePriceLists: React.ReactText[];
  searchMobilePreviousState: boolean;
}

const initialState: ProductsState = {
  isFetching: false,
  products: [],
  dataPriceList: {},
  dataLinked:[],
  metadata: {},
  selectedProducts: [],
  selectedPriceList: [],
  selectedPriceListDetail: [],
  error: null,
  visibleColumns: [],
  filters: {
    visibility: [],
    availability: [],
    merchandise_bonus: []
  },
  lastRequest: {},
  isFetchingPriceLists: false,
  priceLists: [],
  priceListsDetail: [],
  updatedPrices: [],
  isSaving: false,
  sinceIndex: 0,
  toIndex: 3,
  visiblePriceLists: [],
  searchMobilePreviousState: true
};

function startFetching(state: ProductsState) {
  state.isFetching = true;
}

function fetchingFailed(state: ProductsState, action: PayloadAction<string>) {
  state.isFetching = false;
  state.error = action.payload;
}

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    getProductsStart: startFetching,
    getProductsSuccess: (
      state,
      {
        payload
      }: PayloadAction<{
        request?: Partial<ProductAPI.GetProductsRequest>;
        response: ResponseType<Product[]>;
      }>
    ) => {
      const { request, response } = payload;
      const { data, meta } = response;
      state.isFetching = false;
      state.metadata = meta;
      const userSearchedLasTime = !request?.filters?.search && state.searchMobilePreviousState;
      if (IsMobile() && userSearchedLasTime) {
        //mobile
        data.map(item => {
          state.products.push(item);
        });
      } else {
        //desktop
        state.products = data;
      }
      state.searchMobilePreviousState = !request?.filters?.search;
      if (request) {
        state.lastRequest = request;
      }
    },
    getProductsFailure: fetchingFailed,
    updateProductsSuccess: (state, { payload }: PayloadAction<ProductAPI.PatchProducts>) => {
      state.products = state.products.map(product => {
        const newProduct = payload.find(p => product.id === p.id);
        if (newProduct) {
          return { ...product, ...newProduct };
        }
        return product;
      });
    },
    deleteProductsSuccess: state => {
      state.selectedProducts = initialState.selectedProducts;
    },
    selectAllProducts: state => {
      state.selectedProducts =
        state.selectedProducts.length > 0 ? [] : state.products.map(product => product.id);
    },
    selectAllPriceList: state => {
      state.selectedPriceList =
        state.selectedPriceList.length > 0 ? [] : state.priceLists.map(priceList => priceList.id);
    },
    selectAllPriceListDetails: state => {
      state.selectedPriceListDetail =
        state.selectedPriceListDetail.length > 0 ? [] : state.priceListsDetail.map(priceList => priceList.id);
    },
    clearSelection: state => {
      state.selectedProducts = [];
    },
    clearSelectionPriceList: state => {
      state.selectedPriceList = [];
    },
    selectProduct: (state, { payload }: PayloadAction<Pick<Product, 'id'>>) => {
      const { id: productId } = payload;
      const selectedIndex = state.selectedProducts.indexOf(productId);
      let newSelected;

      if (selectedIndex >= 0) {
        newSelected = state.selectedProducts.filter(selected => selected !== productId);
      } else {
        newSelected = state.selectedProducts.concat(productId);
      }

      state.selectedProducts = newSelected;
    },
    selectPriceList: (state, { payload }: PayloadAction<Pick<PriceList, 'id'>>) => {
      const { id: priceListId } = payload;
      const selectedIndex = state.selectedPriceList.indexOf(priceListId);
      let newSelected;

      if (selectedIndex >= 0) {
        newSelected = state.selectedPriceList.filter(selected => selected !== priceListId);
      } else {
        newSelected = state.selectedPriceList.concat(priceListId);
      }

      state.selectedPriceList = newSelected;
    },    
    setVisibleColumns: (state, { payload }: PayloadAction<React.ReactText[]>) => {
      state.visibleColumns = payload;
    },
    setFilters: (
      state,
      { payload }: PayloadAction<{ filter: string; selected: React.ReactText[] }>
    ) => {
      const { filter, selected } = payload;
      state.filters = { ...state.filters, [filter]: selected };
    },
    setDataPriceList: (
      state,
      { payload }: PayloadAction<PriceList>
    ) => {
      const { name, minAmount, prices } = payload;
      state.dataPriceList = { ...state.dataPriceList, name , minAmount, prices};
    },
    setDataLinked: (
      state,
      { payload }: PayloadAction<any[]>
    ) => {
      state.dataLinked = [...payload];
    },
    clearFilters: state => {
      state.filters = initialState.filters;
    },
    deleteFilter: (
      state,
      { payload }: PayloadAction<{ filterGroupKey: string; selected: string }>
    ) => {
      const { filterGroupKey, selected } = payload;
      state.filters = {
        ...state.filters,
        [filterGroupKey]: state.filters[filterGroupKey].filter(f => f !== selected)
      };
    },
    getPriceListsStart: state => {
      state.isFetchingPriceLists = true;
      state.visiblePriceLists = [];
      state.sinceIndex = 0;
      state.toIndex = 3;
    },
    getPriceListSuccess: (state, { payload }: PayloadAction<ResponseType<PartnerPriceList[]>>) => {
      state.priceLists = payload.data;
      state.isFetchingPriceLists = false;
      const prices = state.products
        .map(x => {
          if (x.partnerPriceListItems && state.selectedProducts.includes(x.id)) {
            return x.partnerPriceListItems.map(x => ({
              base_price: x.base_price,
              product_id: x.product_id,
              partner_price_list_id: x.partner_price_list_id
            }));
          } else {
            return [];
          }
        })
        .flat();

      state.updatedPrices = Object.assign(
        {},
        ...prices.map(s => ({ [`${s.product_id}_${s.partner_price_list_id}`]: s }))
      );
    },
    updatePrice: (state, { payload }: PayloadAction<PriceListAPI.BulkUpdatePriceListItem>) => {
      const { partner_price_list_id, product_id } = payload;
      const key = `${product_id}_${partner_price_list_id}`;
      state.updatedPrices[key] = { ...payload, modified: true };
    },
    startSaving: state => {
      state.isSaving = true;
    },
    saveFinished: state => {
      state.isSaving = false;
      Object.keys(state.updatedPrices).forEach(
        key => (state.updatedPrices[key] = { ...state.updatedPrices[key], modified: undefined })
      );
    },
    onBack: state => {
      if (state.sinceIndex > 0) {
        state.sinceIndex = state.sinceIndex -= 1;
        state.toIndex = state.toIndex -= 1;
      }
    },
    onNext: state => {
      const toIndexLimit =
        state.visiblePriceLists.length > 0
          ? state.visiblePriceLists.length
          : state.priceLists.length;
      if (toIndexLimit !== state.toIndex) {
        state.sinceIndex = state.sinceIndex += 1;
        state.toIndex = state.toIndex += 1;
      }
    },
    setVisiblePriceLists: (state, { payload }: PayloadAction<React.ReactText[]>) => {
      state.visiblePriceLists = payload;
    }
  }
});

export const {
  getProductsStart,
  getProductsFailure,
  clearSelection,
  clearSelectionPriceList,
  deleteProductsSuccess,
  getProductsSuccess,
  selectAllProducts,
  selectAllPriceList,
  selectAllPriceListDetails,
  selectProduct,
  selectPriceList,
  updateProductsSuccess,
  setVisibleColumns,
  setFilters,
  setDataPriceList,
  setDataLinked,
  clearFilters,
  deleteFilter,
  getPriceListsStart,
  getPriceListSuccess,
  updatePrice,
  startSaving,
  saveFinished,
  onBack,
  onNext,
  setVisiblePriceLists
} = productsSlice.actions;

export const { reducer: productsReducer } = productsSlice;

export const fetchProducts = (
  request: Partial<ProductAPI.GetProductsRequest>
): AppThunk => async dispatch => {
  try {
    dispatch(getProductsStart());
    const response = await ProductAPI.getProducts(request);
    dispatch(getProductsSuccess({ response, request }));
  } catch (err) {
    dispatch(getProductsFailure(err.toString()));
  }
};

export const updateProduct = (request: ProductAPI.PatchProducts): AppThunk => async dispatch => {
  try {
    const response = await ProductAPI.patchProducts(request);
    const { count } = response.data;
    if (count > 0) {
      dispatch(updateProductsSuccess(request));
      dispatch(showSnackbar({ message: i18n.t(`products:snackbar.update_success`, { count }) }));
    }
  } catch (err) {
    dispatch(showSnackbar({ message: i18n.t(`products:snackbar.update_error`), error: true }));
  }
};

export const deleteProducts = (request: number[]): AppThunk => async (dispatch, getState) => {
  try {
    const response = await ProductAPI.deleteProducts(request);
    const { count } = response.data;

    if (count > 0) {
      const products = await ProductAPI.getProducts(getState().products.lastRequest);
      dispatch(showSnackbar({ message: i18n.t(`products:snackbar.delete_success`, { count }) }));
      dispatch(getProductsSuccess({ response: products }));
      dispatch(deleteProductsSuccess());
    }
  } catch (err) {
    dispatch(showSnackbar({ message: i18n.t(`products:snackbar.delete_error`), error: true }));
  }
};

export const fetchPriceLists = (
  request: PriceListAPI.GetPartnerPriceListRequest
): AppThunk => async dispatch => {
  try {
    dispatch(getPriceListsStart());
    const response = await PriceListAPI.getPartnerPriceList(request);
    dispatch(getPriceListSuccess(response));
  } catch (err) {}
};

export const saveChanges = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(startSaving());
    const items = Object.keys(getState().products.updatedPrices)
      .map(key => {
        const price = getState().products.updatedPrices[key];
        if (price.base_price && isNumber(price.base_price)) {
          return price;
        } else {
          return { ...price, base_price: 0 };
        }
      })
      .filter(price => price.modified);
    const partnerId = partnerIdSelector(getState());
    await PriceListAPI.bulkUpdatePriceLists({ items, partnerId });
    dispatch(showSnackbar({ message: i18n.t(`products:snackbar.save_success`) }));
  } catch (err) {
    dispatch(showSnackbar({ message: i18n.t(`shared:system_error`), error: true }));
  }
  dispatch(saveFinished());
};
