import { createSelector } from '@reduxjs/toolkit';

export const purchaseOrdersSelector = state => state.summary.purchaseOrders;
export const isFetchingSelector = state => state.summary.isFetching;
export const isChangingStatusSelector = state => state.summary.isChangingStatus;
export const statusChangeSuccessSelector = state => state.summary.statusChangeSuccess;
export const metadataSelector = state => state.summary.metadata;
export const filtersSelector = state => state.summary.filters;
export const tabSelector = state => state.summary.tabs;
export const filtersCurrentSelector = state => state.summary.filtersCurrent;
export const filtersAllpurchaseOrdersSelector = state => state.summary.filtersAllpurchaseOrders;
export const selectedPurchaseOrdersSelector = state => state.summary.selectedPurchaseOrders;
export const indeterminatedSelectionSelector = createSelector(
  selectedPurchaseOrdersSelector,
  purchaseOrdersSelector,
  (selected, list) => selected.length > 0 && selected.length < list.length
);
export const allSelectionSelector = createSelector(
  selectedPurchaseOrdersSelector,
  purchaseOrdersSelector,
  (selected, list) => list.length > 0 && selected.length === list.length
);
export const snackbarSelector = state => state.summary.snackbar;
export const visibleColumnsSelector = state => state.summary.visibleColumns;
