import * as GlobalActions from 'common/redux/global/actions';
import { ActionsTypes, Actions } from '../actions';
import moment from 'moment';

const initialState = {
  isFetching: false,
  purchaseOrders: [],
  selectedPurchaseOrders: [],
  metadata: {},
  isChangingStatus: false,
  statusChangeSuccess: false,
  filters: {},
  tabs: 0,
  filtersCurrent: undefined,
  filtersAllpurchaseOrders: {
    delivery_dates: {
      from: moment()
        .subtract(15, 'd')
        .format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD')
    }
  },
  snackbar: {
    visible: false,
    error: false,
    message: ""
  },
  visibleColumns: [],
};

export default function summary(state = initialState, action = {}) {
  switch (action.type) {
    case ActionsTypes.PURCHASE_ORDERS_REQUEST:
      return {
        ...state,
        isFetching: true,
        purchaseOrders: []
      };
    case ActionsTypes.PURCHASE_ORDERS_RESPONSE:
      return {
        ...state,
        isFetching: false,
        purchaseOrders: action.response.data,
        metadata: action.response.meta
      };
    case GlobalActions.changePurchaseOrderStatusRequest.toString():
      if (action.payload.context === 'summary') {
        return {
          ...state,
          isChangingStatus: true
        };
      }
    case GlobalActions.changePurchaseOrderStatusResponse.toString():
      if (action.payload.context === 'summary') {
        return {
          ...state,
          isChangingStatus: false,
          statusChangeSuccess: action.payload.success
        };
      }
    case GlobalActions.confirmPurchaseOrderRequest.toString():
      if (action.payload.context === 'summary') {
        return {
          ...state,
          isChangingStatus: true
        };
      }
    case GlobalActions.confirmPurchaseOrderResponse.toString():
      if (action.payload.context === 'summary') {
        return {
          ...state,
          isChangingStatus: false,
          statusChangeSuccess: action.payload.success
        };
      }
    case GlobalActions.rejectPurchaseOrderRequest.toString():
      if (action.payload.context === 'summary') {
        return {
          ...state,
          isChangingStatus: true
        };
      }
    case GlobalActions.rejectPurchaseOrderResponse.toString():
      if (action.payload.context === 'summary') {
        return {
          ...state,
          isChangingStatus: false,
          statusChangeSuccess: action.payload.success
        };
      }
    case ActionsTypes.RESET_MODALS:
      return {
        ...state,
        statusChangeSuccess: false
      };
    case ActionsTypes.PURCHASE_ORDERS_FILTERS_RESPONSE:
      return {
        ...state,
        filters: action.response
      };
    case ActionsTypes.SET_TAB: {
      return {
        ...state,
        tabs: action.position,
      };
    }
    case ActionsTypes.SET_FILTER_CURRENT: {
      return {
        ...state,
        filtersCurrent: action.filtersCurrent
      };
    }
    case ActionsTypes.FILLTERS_ALL_PURCHASE_ORDERS: {
      return {
        ...state,
        filtersAllpurchaseOrders: action.filter
      };
    }
    case ActionsTypes.SELECT_ALL_PURCHASE_ORDERS: {
      return {
        ...state,
        selectedPurchaseOrders:
          state.selectedPurchaseOrders.length > 0 ? [] : state.purchaseOrders.map(po => po.id)
      };
    }
    case ActionsTypes.SELECT_PURCHASE_ORDER: {
      const poId = action.id;
      const selectedIndex = state.selectedPurchaseOrders.indexOf(poId);
      let newSelected;

      if (selectedIndex >= 0) {
        newSelected = state.selectedPurchaseOrders.filter(selected => selected !== poId);
      } else {
        newSelected = state.selectedPurchaseOrders.concat(poId);
      }

      return {
        ...state,
        selectedPurchaseOrders: newSelected
      };
    }
    case ActionsTypes.CLEAR_ALL_SELECTED: {
      return {
        ...state,
        selectedPurchaseOrders: []
      };
    }
    case ActionsTypes.DOWNLOAD_BULK_PDF_RESPONSE: {
      return {
        ...state,
        snackbar: {
          visible: true,
          error: action.response.error,
          message: action.response.message
        } 
      };
    }
    case ActionsTypes.DOWNLOAD_BULK_XLS_RESPONSE: {
      return {
        ...state,
        snackbar: {
          visible: true,
          error: action.response.error,
          message: action.response.message
        }
      };
    }
    case ActionsTypes.SET_VISIBLE_COLUMNS:{
      return {
        ...state,
        visibleColumns:action.column
      }
    }
    default:
      return state;
  }
}
