import { configureStore, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { ThunkAction } from 'redux-thunk';
import rootReducer, { RootState } from './rootReducer';
import rootSaga from './sagas';
import { loadState, saveState } from 'common/utils/helpers';

const sagaMiddleware = createSagaMiddleware();

const middleware = [...getDefaultMiddleware(), sagaMiddleware, logger];

const preloadedState = loadState();

const store = configureStore({
  reducer: rootReducer,
  middleware,
  preloadedState
});

store.subscribe(() => {
  saveState({ global: store.getState().global, summary: store.getState().summary });
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default store;
