import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import * as SS from 'styled-system';
import { rem, rgba } from 'polished';
import { Button, Checkbox, Box } from 'components';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import theme from 'common/styles/extendeal-theme';
import {
  Input,
  InputAdornment,
  MenuProps as MuiMenuProps,
  ListItemText,
  MenuItem,
  Select
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const StyledSelect = styled(({ isOpen, children, ...props }) => (
  <Select {...props} inputProps={{ classes: { root: 'input' } }}>
    {children}
  </Select>
))`
  color: ${props => props.theme.colors.S['800']};
  font-size: ${rem('14px')};
  font-weight: 600;
  height: ${rem('36px')};
  min-height: ${rem('36px')};
  max-width: ${rem('250px')};
  border-radius: ${rem('4px')};
  margin: 0 ${rem('8px')};
  ${SS.space}

  ${props =>
    props.isOpen &&
    css`
      background-color: ${rgba(props.theme.colors.S['800'], 0.25)};
    `}

  &:hover {
    background-color: ${props => rgba(props.theme.colors.S['800'], 0.1)};
  }

  & .input {
    padding: ${rem('8px')} ${rem('24px')} ${rem('8px')} ${rem('8px')};
    background-color: transparent;
  }

  & .MuiSelect-icon {
    width: 16px;
    right: 2px;
  }
`;

const StyledCheckbox = styled(props => <Checkbox {...props} />)`
  margin-right: ${rem('5px')};
  margin-left: ${rem('-5px')};
  padding: ${rem('3px')};
`;

const StyledListItemText = styled(({ ...props }) => (
  <ListItemText {...props} classes={{ primary: 'primary' }} />
))`
  & .primary {
    color: ${props => props.theme.colors.S['800']};
    font-size: ${rem('14px')} !important;
  }
`;

const StyledMenuItem = styled(
  React.forwardRef<any, any>(({ ...props }: any, ref: any) => (
    <MenuItem ref={ref} {...props} classes={{ selected: 'selected' }} />
  ))
)`
  height: ${rem('40px')};
  min-height: ${rem('40px')};

  &:hover {
    background-color: ${props => props.theme.colors.S['400']};
  }

  &.selected {
    background-color: transparent;
  }
`;

interface FilterOption {
  value: any;
  label: string;
  disabled?: boolean;
  checked?: boolean
}

export interface FilterMenuProps extends SS.SpaceProps {
  options: Array<FilterOption>;
  onChange(selected: Array<string | number>): any;
  defaultOption: FilterOption;
  selectedOptions?: any;
  controlled?: boolean;
  MenuProps?: Omit<MuiMenuProps, 'open'>;
  hideTitle: boolean;
  currentSelected?: any;
  IconComponent?: React.ReactNode;
  onFilter?(options: Array<any>): void;
  onClearFilters?(): void;
  shouldReorder?: boolean;
  displayAllOption?: boolean;
}

const defaultMenuProps = {
  PaperProps: {
    style: {
      maxHeight: 336,
      maxWidth: 400,
      width: 250,
      marginTop: 10,
      boxShadow: theme.shadows[2]
    }
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  getContentAnchorEl: null
};

export function FilterMenu({
  options = [],
  onChange,
  defaultOption,
  margin,
  hideTitle = false,
  IconComponent,
  MenuProps = {},
  controlled = false,
  onClearFilters,
  onFilter,
  currentSelected,
  selectedOptions,
  shouldReorder = true,
  displayAllOption = true,
  ...props
}: FilterMenuProps) {
  const [selected, setSelected] = useState<Array<any>>([]);
  const [orderedOptions, setOrderedOptions] = useState<Array<FilterOption>>(
    []
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [allChecked, setAllChecked] = useState<boolean>(!controlled);
  const { t } = useTranslation();

  const toggleAll = (checked: boolean) => {
    if (checked) {
      setSelected(options.map(option => option.value));
    } else {
      setSelected([]);
    }
  };

  const onCheckAll = () => {
    setAllChecked(!allChecked);
    toggleAll(!allChecked);
    if (!allChecked) {
      onChange([]);
    }
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>, index: any) => {
    if (index.props.children[0].props.disabled) {
      return;
    }
    if (index.props.value === 'all') {
      onCheckAll();
    } else {
      const optionsSelected = event.target.value as string[];
      setSelected(optionsSelected);
      if (!controlled) {
        let orderedOptionsCopy = [...orderedOptions]
        if (optionsSelected.length === 1){
          orderedOptionsCopy.map(item => {
            if (item.label === optionsSelected[0]){
                return item.disabled = true
            }else{
              return item
            }
          })
        }else{
          orderedOptionsCopy.map(item => {
            return item.disabled = false
          })
        }
        setOrderedOptions(orderedOptionsCopy)
        onChange(optionsSelected);
        setAllChecked(optionsSelected.length === options.length);
      }
    }
  };

  const reorder = () => {
    const checkedFirst = options.map(option => ({
      ...option,
      checked: selected.includes(option.value),
      disabled: orderedOptions.find(item => item.label === option.label)?.disabled
    }));

    setOrderedOptions(orderBy(checkedFirst, ['checked', 'label'], ['desc', 'asc']));
  };

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
    if (shouldReorder) {
      reorder();
    }
  };

  const onClose = () => {
    toggleIsOpen();

    if (controlled) {
      setSelected(selectedOptions);
    } else {
      if (!allChecked && selected.length === 0) {
        toggleAll(true);
        setAllChecked(true);
      }
    }
  };

  const onClickFilter = (e: React.MouseEvent<HTMLElement>) => {
    if (onFilter) {
      onFilter(selected.filter(s => s));
      toggleIsOpen();
      e.stopPropagation();
    }
  };

  const onClickClearFilters = (e: React.MouseEvent<HTMLElement>) => {
    if (onClearFilters) {
      onClearFilters();
      toggleIsOpen();
      e.stopPropagation();
    }
  };

  const getValue = () =>
    selected
      .map(value => {
        const option = find(options, { value });
        return option ? option.label : '';
      })
      .join(', ');

  useEffect(() => {
    if (controlled) {
      setSelected(selectedOptions);
    } else if (currentSelected?.length > 0) {
      setSelected(currentSelected);
      setAllChecked(false);
    } else {
      toggleAll(true);
    }

    if (shouldReorder) {
      reorder();
    } else {
      // @ts-ignore
      setOrderedOptions(options)
    }
  }, [selectedOptions]);

  return (
    <StyledSelect
      displayEmpty
      multiple
      open={controlled ? isOpen : undefined}
      value={selected}
      onChange={handleChange}
      input={
        <Input
          id="select-multiple-checkbox"
          startAdornment={
            IconComponent ? (
              <InputAdornment position="end">{IconComponent}</InputAdornment>
            ) : (
              undefined
            )
          }
          disableUnderline
          title={selected.length > 0 && !hideTitle ? getValue() : undefined}
        />
      }
      onOpen={toggleIsOpen}
      onClose={onClose}
      MenuProps={{ ...defaultMenuProps, ...MenuProps }}
      renderValue={() => {
        if (hideTitle) {
          return defaultOption.label;
        }
        return `${defaultOption.label}: ${allChecked ? defaultOption.value : getValue()}`;
      }}
      isOpen={isOpen}
      IconComponent={isOpen ? KeyboardArrowUpIcon : KeyboardArrowDownIcon}
      {...props}
    >
      {!displayAllOption ? null : !controlled ? (
        <StyledMenuItem value="all">
          <StyledCheckbox checked={allChecked} />
          <StyledListItemText primary={defaultOption.value} />
        </StyledMenuItem>
      ) : null}
      {orderedOptions.map(option => (
        <StyledMenuItem key={option.value} value={option.value}>
          <StyledCheckbox
            checked={selected.indexOf(option.value) > -1 || allChecked}
            disabled={option?.disabled}
          />
          <StyledListItemText primary={option.label} />
        </StyledMenuItem>
      ))}
      {controlled && (
        <Box display="flex" justifyContent="space-around" paddingTop="8px">
          <Button
            variant="text"
            colorName={theme.colors.S}
            onClick={onClickClearFilters}
            disabled={selected.filter(s => s).length === 0}
          >
            {t('shared:actions.clean_filters')}
          </Button>
          <Button variant="outlined" colorName={theme.colors.Mi} onClick={onClickFilter}>
            {t('shared:actions.filter')}
          </Button>
        </Box>
      )}
    </StyledSelect>
  );
}
