import React from 'react';
import { Controller, Control } from 'react-hook-form';
import { MenuProps } from '@material-ui/core';
import { Menu } from './menu';
import { TextField } from './text-field';
import theme from 'common/styles/extendeal-theme';

const defaultMenuProps: Omit<MenuProps, 'open'> = {
  PaperProps: {
    style: {
      maxHeight: 336,
      maxWidth: 400,
      width: 250,
      marginTop: 10,
      boxShadow: theme.shadows[2]
    }
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  getContentAnchorEl: null
};

type Option = {
  label: string;
  value: string | number;
};

export interface SelectInputProps {
  register: any;
  control: Control;
  options: Option[];
  name: string;
  label: string;
  defaultValue: string;
  defaultLabel: string;
  error: boolean;
  onFocus?: () => void;
  dirty?: boolean;
}

export const SelectInput = React.memo(function SelectInput({
  register,
  control,
  options = [],
  name,
  label,
  defaultValue = '',
  defaultLabel,
  error,
  onFocus,
  dirty
}: SelectInputProps) {
  return (
    <Controller
      {...{ name, control, defaultValue }}
      rules={register}
      as={
        <TextField
          fullWidth
          select
          onFocus={onFocus}
          label={label}
          SelectProps={{ MenuProps: { ...defaultMenuProps }, displayEmpty: true }}
          InputLabelProps={{ shrink: true }}
          error={error}
          dirty={dirty}
        >
          <Menu.Item key="default" value="">
            {defaultLabel}
          </Menu.Item>
          {options.map(option => (
            <Menu.Item key={option.value} value={option.value}>
              {option.label}
            </Menu.Item>
          ))}
        </TextField>
      }
    />
  );
});
