import { apiInstance } from 'common/utils/axios';

export const getPurchaseOrder = async id => apiInstance.get(`/deliveries/${id}`);

export const getDeliveryLogs = async id => apiInstance.get(`/deliveries/${id}/delivery-logs`);

export const markAsRead = async request => apiInstance.get(`/deliveries/read/${request.token}/${request.userId}`);

export const updateItemBonus = async ({
  partnerId,
  partnerPriceListId,
  merchandiseBonusId,
  merchandiseBonusRuleId,
  merchandiseBonusRuleOrderItemId,
  bonus
}) =>
  apiInstance({
    method: 'PATCH',
    url: `/partners/${partnerId}/partner-price-lists/${partnerPriceListId}/merchandise-bonus/${merchandiseBonusId}/rules/${merchandiseBonusRuleId}/order-items/${merchandiseBonusRuleOrderItemId}`,
    data: {
      bonus
    }
  });

export const getModerationReasonsByStatus = async (deliveryStatusId) => apiInstance.get(`/deliveries/status/${deliveryStatusId}/moderation-reasons`);
