import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  body {
    font-family: 'Source Sans Pro', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
    overflow: hidden;
    backface-visibility: hidden;
    will-change: overflow;
  }

  .shadow-scroll {
    background:
    linear-gradient(white 0%, hsla(0,0%,100%, 0)),
    linear-gradient(hsla(0,0%,100%,0) 10px, white 0%) bottom,
    radial-gradient(at top, rgb(227,227,227), transparent 90%), 
    radial-gradient(at bottom, rgb(227,227,227), transparent 90%) bottom;
    background-repeat: no-repeat;
    background-size: 100% 20px, 100% 20px, 100% 10px, 100% 10px;
    background-attachment: local, local, scroll, scroll;
    background-color: white;
  }

  a {
    text-decoration: none;
  }

  a:-webkit-any-link {
    appearance: none;
  }

  #fc_frame.fc-widget-normal.fc_l2r, #fc_frame.fc_l2r {
    left: 72px !important;
    bottom: 8px !important;
  }
`;
