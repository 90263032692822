import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Box } from 'components';
import Grid from '@material-ui/core/Grid';

export const Value = styled.span`
  padding: ${rem('8px')} 0;
  color: ${props => props.theme.colors.S[800]};
  font-size: ${rem('16px')};
`;

export const Label = styled.span`
  padding: 0;
  font-weight: 200;
`;

interface FieldProps {
  label: string;
  value: string | number;
}

export interface PoHeaderExtractProps {
  extract: any;
}

function Field({ label, value }: FieldProps) {
  return (
    <Box display="flex" flexDirection="column">
      <Label>{label}</Label>
      <Value>{value}</Value>
    </Box>
  );
}

export function PoHeaderExtract({ extract }: PoHeaderExtractProps) {
  return (
    <Grid container>
      {extract.map((field: FieldProps) => (
        <Grid item xs={6} key={field.label}>
          <Field label={field.label} value={field.value} />
        </Grid>
      ))}
    </Grid>
  );
}
