import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as SS from 'styled-system';
import { Button, Box } from 'components';
import find from 'lodash/find';
import clsx from 'clsx';
import theme from 'common/styles/extendeal-theme';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import {
  Input,
  InputAdornment,
  MenuProps as MuiMenuProps,
  ClickAwayListener, 
  Popper,
  FormControlLabel,
  Paper,
  List,
  IconButton,
} from '@material-ui/core';
import {  makeStyles, Theme, createStyles , withStyles} from "@material-ui/core/styles";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  paper: {
    border: "1px solid",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  popper:{
    display:"absolute",
    zIndex: 999
  },
  listbox:{
    overflowX: "auto",
    maxHeight: "292px", 
    display: "grid",
    paddingLeft: "15px" 
  }, 
  label:{
    fontSize: "14px !important"
  },
  option:{
    paddingLeft: "5px !important",
    height:"32px",
    font: "normal normal normal 14px/15px Source Sans Pro",
    letterSpacing: "-0.14px",
    color: "#666666",
  },
  btnBox:{
    padding: "0px 8px 8px 9px"
  },
  inputBtn: {   
    color: "#646464",
    fontSize: "14px",
    fontWeight: 600,
    height: "36px",
    width: "148px",
    maxWidth: "250px",
    borderRadius: "4px",
    cursor: "pointer",
    backgroundColor: "transparent",
    margin: "0 8px",
    padding: "8px 8px 8px 8px",
    "&:hover,&:focus": {
      backgroundColor: "rgba(100,100,100,0.1)"
    },
    "& svg": {
      width: 16,
      height: 16
    }, 
    "& > input":{
      width: "0px !important"
    } 
  },
  inputRoot:{
    padding: "0 !important",
    width: "148px !important",
    margin: "0 8px",
    height: "36px !important",
    border: "2px solid #6DAFD9",
    borderRadius: "4px",
    '&:hover,&:focus': {
      border: "2px solid #6DAFD9 !important",  
    },
     "& > input":{
      padding: "9px 10px"
    },
  },
  icon: {
    width: 14,
    height: 14,
    border: "#707070",
    borderRadius: "1px",
    boxShadow: 'inset 0 0 0 1px #707070, inset 0 -1px 0 #707070',
    backgroundColor: 'white',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: 'white',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },checkedIcon: {
    backgroundColor: '#6DAFD9',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 14,
      height: 14,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#6DAFD9',
    }   
  },
  clearBtn:{
    marginRight: "12px"
  },
  empty:{
    width: "182px",
    height: "44px",
    textAlign: "center",
    font: "normal normal normal 16px/22px Source Sans Pro",
    letterSpacing: "0px",
    color: "#8B8B8B"
  }
})
);

function StyledCheckbox(props: CheckboxProps) {
  const classes = useStyles();

  return (
    <Checkbox
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      color="default"
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
}

interface FilterOption {
  value: any;
  label: string;
  disabled?: boolean;
  checked?: boolean
}

export interface FilterMenuSearchProps extends SS.SpaceProps {
  options: Array<FilterOption>;
  onChange(selected: Array<string | number>): any;
  defaultOption: FilterOption;
  selectedOptions?: any;
  controlled?: boolean;
  MenuProps?: Omit<MuiMenuProps, 'open'>;
  hideTitle: boolean;
  currentSelected?: any;
  IconComponent?: React.ReactNode;
  onFilter?(options: Array<any>): void;
  onClearFilters?(): void;
  shouldReorder?: boolean;
  displayAllOption?: boolean;
}

export function FilterMenuSearch({
  options = [],
  onChange,
  defaultOption,
  margin,
  hideTitle = false,
  IconComponent,
  MenuProps = {},
  controlled = false,
  onClearFilters,
  onFilter,
  currentSelected,
  selectedOptions,
  shouldReorder = true,
  displayAllOption = true,
  ...props
}: FilterMenuSearchProps) {
  const [selected, setSelected] = useState<Array<any>>([]);
  const [inputValue, setinputValue] = useState<String>("");
  const [listOptions, setListOptions] = useState<Array<any>>([...options]);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);  
  const { t } = useTranslation();
  const classes = useStyles(); 
 

  const handleKeyUp = (event:any) => {
    if (event.target.value.length){
      setinputValue(event.target.value)
      const list = options?.filter(opt => opt?.label?.toUpperCase()?.includes(event?.target?.value?.toUpperCase()))
      setListOptions(list);       
    }else{
      setListOptions([...options])
      setinputValue("")
    }
  };

  const onClickFilter = (e:any) => {
    if (onFilter) {
      onFilter(selected.filter(s => s));
      e.stopPropagation();
    }
  };

  const onClickClearFilters = (e: any) => {
    if (onClearFilters) {
      onClearFilters();      
      e.stopPropagation();
    }
  };

  const getValue = () =>
    selected
      .map(value => {
        const option = find(options, { value });
        return option ? option.label : '';
      })
      .join(', ');

  useEffect(() => {
    if (controlled) {
      setSelected(selectedOptions);
    } else if (currentSelected?.length > 0) {
      setSelected(currentSelected);     
    } 
  }, [selectedOptions]);

  const ListOptions = (data: any) => {
    if(!data?.length){
      return  (<div className={classes.empty}>
      {t('shared:empty_search')} 
    </div>)
    }

    return(
      [...data].map((option) => {
        const labelId = `checkbox-list-label-${option}`;                  
           return (
            <FormControlLabel className={classes.option} key={option.value} 
              control={
                <StyledCheckbox 
                id={`${option.value}`}                
                  style={{ marginRight: 8 }}
                  onChange={(event:any) => {              
                    let values = [...selected]
                    if (values.includes(parseInt(event.target.id))){
                      values = values.map(v => {if (v !== parseInt(event.target.id)) return v})
                    }else{
                      values.push(parseInt(event.target.id))
                    }
                    setSelected(values)              
                  }}
                  checked={selected.filter(value=> value === option?.value).length > 0 }
                  /> 
                } 
              label={option.label}   
              classes={{
                label: classes.label
              }} 
            />   
        );
      })
    );
}

  const handleClickAway = (event: any) => {
    if (event.target.id === `${defaultOption.label}select-multiple-checkbox` || event.target.id === `${defaultOption.label}-ClearBtn`) return
    if (open){
      setSelected(selectedOptions)
      const input = document.querySelectorAll(`#${defaultOption.label}select-multiple-checkbox`)[0]  as HTMLInputElement  
      input.value = ""
      setListOptions([...options])  
      setinputValue("")
    }
    setOpen(false);
  };

  const handleClick = (event: any) => {
    if (event.target.id === `${defaultOption.label}select-multiple-checkbox` || event.target.id === `${defaultOption.label}-ClearBtn`) return
    setAnchorEl(event.currentTarget);
    setOpen(!open);
    const input = document.querySelectorAll(`#${defaultOption.label}select-multiple-checkbox`)[0]  as HTMLInputElement  
    input?.focus()
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div style={{display:"inline-flex"}}>
        <Input
          className={!open ? classes.inputBtn: classes.inputRoot}
          type="text"
          autoComplete="off"
          id={`${defaultOption.label}select-multiple-checkbox`}         
          onClick={handleClick}
          onKeyUp={handleKeyUp}
          placeholder={`${t('shared:actions.search')} ${defaultOption.label}`}
          disableUnderline                   
          startAdornment={!open ? <span style={{width: "100%"}}>{defaultOption.label}</span>: undefined}
          endAdornment={!open ? <KeyboardArrowDownIcon/> :  inputValue?.length ?  <InputAdornment id={`${defaultOption.label}-ClearBtn`} position="end">
            <IconButton
              edge="end"
              size="small"
              style={{ right: "8px"}}
              id={`${defaultOption.label}-ClearBtn`}
              onClick={()=>{
                setinputValue("")
                const input = document.querySelectorAll(`#${defaultOption.label}select-multiple-checkbox`)[0]  as HTMLInputElement  
                input.value = ""
                input.focus()
                setListOptions([...options])
              }}
            >
              <ClearIcon id={`${defaultOption.label}-ClearBtn`} fontSize="small"/>
            </IconButton>
          </InputAdornment> : null
          }
          title={selected.length > 0 && !hideTitle ? getValue() : undefined}
        />
        {open ? (
          <div>
            <Popper
              className={classes.popper}
              placement="bottom-start"
              id={"simple-popper"}
              open={open}
              anchorEl={anchorEl}              
            >
              <Paper>
                <List className={classes.listbox}>
                  { ListOptions(listOptions)}                  
                </List>
                <Box  id={"box-Btns"} justifyContent="space-around" className={classes.btnBox}>
                <Button  
                      className={classes.clearBtn}
                      variant="text"
                      colorName={theme.colors.S}
                      onClick={onClickClearFilters}
                      disabled={selected.filter(s => s).length === 0}
                    >
                      {t('shared:actions.clean_filters')}
                    </Button>
                    <Button  id={`${defaultOption.label}filterBtn`} variant="outlined" colorName={theme.colors.Mi} onClick={onClickFilter}>
                      {t('shared:actions.filter')}
                    </Button>
                </Box> 
              </Paper>
            </Popper>
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
}
