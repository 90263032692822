import React from 'react';
import { SWRConfig } from 'swr';
import { StylesProvider } from '@material-ui/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import GlobalStyle from 'common/styles/styles';
import muiTheme from 'common/styles/mui-theme';
import extendealTheme from 'common/styles/extendeal-theme';
import './common/language/i18n';
import AppRouter from 'common/router';
import store from 'common/redux/store';
import { AlertProvider } from 'context';
import { apiFetcher } from 'common/utils/axios';

export default function App() {
  return (
    <Provider store={store}>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={muiTheme}>
          <GlobalStyle />
          <ThemeProvider theme={extendealTheme}>
            <SWRConfig
              value={{
                fetcher: apiFetcher,
                revalidateOnFocus: false
              }}
            >
              <AlertProvider>
                <AppRouter />
              </AlertProvider>
            </SWRConfig>
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </Provider>
  );
}
