import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { userNameSelector, roleIdentifierSelector } from 'common/redux/global/selectors';
import { useDispatch, useSelector } from 'react-redux';
import routes, { Route } from 'common/router/routes';
import { useLocation } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import { HelpOutline, LockOutline, Logout } from 'assets/icons';
import { useRouter } from 'common/utils/hooks';
import * as GlobalActions from 'common/redux/global/actions';

const useStyles = makeStyles({
  list: {
    width: '288px'
  },
  userWrapper: {
    width: '100%',
    height: '110px',
    backgroundColor: '#0B2033',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px'
  },
  username: {
    marginTop: '24px',
    lineHeight: '28px',
    fontSize: '20px',
    color: 'white'
  },
  userRole: {
    marginTop: '4px',
    fontSize: '16px',
    lineHeight: '22px',
    color: 'white'
  },
  listWrapper: {
    padding: 0,
    margin: 8
  },
  listItem: {
    margin: '8px 0',
    borderRadius: '4px',
    color: '#333333',
    paddingLeft: '8px'
  },
  nestedListItemLabel: {
    '& > span': {
      fontSize: '14px !important'
    }
  }
});

interface MobileDrawerProps {
  onClose: () => void;
  onOpen: () => void;
  open: boolean;
}

function RouteListItem({ route, onClose }: { route: Route; onClose: () => void }) {
  const classes = useStyles();
  const { push } = useRouter();
  const location = useLocation();
  const { path, subRoutes } = route;
  const showCollapsible = subRoutes && subRoutes?.length > 1;
  const isActive = location.pathname.includes(path);
  const SVGComponent = isActive ? route.activeIcon : route.icon;
  const [open, setOpen] = React.useState(false);

  const toggleCollapsible = () => setOpen(prev => !prev);

  const goTo = (route: string) => (e: any) => {
    push(route);
    onClose();
  };

  return (
    <>
      <ListItem
        className={classes.listItem}
        button
        key={route.label}
        onClick={showCollapsible ? toggleCollapsible : goTo(route.path)}
      >
        <ListItemIcon>
          {/* @ts-ignore */}
          <SVGComponent fill="#1E334A" width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary={route.label} />
        {showCollapsible ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItem>
      {showCollapsible ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subRoutes?.map(route => (
              <ListItem
                key={route.label}
                className={classes.listItem}
                onClick={goTo(route.path)}
                button
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText
                  className={classes.nestedListItemLabel}
                  primary={`•  ${route.label}`}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      ) : null}
    </>
  );
}

export function MobileDrawer({ onClose, onOpen, open }: MobileDrawerProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userName = useSelector(userNameSelector);
  const role = useSelector(roleIdentifierSelector);
  const userActions = [
    {
      label: 'Ayuda',
      onClick: () => {
        window.open(process.env.REACT_APP_PORTAL_SUPPORT_URL);
        onClose();
      },
      SVGComponent: HelpOutline
    },
    {
      label: 'Cambiar contraseña',
      onClick: () => {
        dispatch(GlobalActions.toggleChangePasswordModal());
        onClose();
      },
      SVGComponent: LockOutline
    },
    {
      label: 'Salir',
      onClick: () => {
        dispatch(GlobalActions.userSignout());
        onClose();
      },
      SVGComponent: Logout
    }
  ];

  return (
    <SwipeableDrawer anchor="left" {...{ open, onClose, onOpen }}>
      <div className={classes.userWrapper}>
        <span className={classes.username}>{userName}</span>
        <span className={classes.userRole}>{role}</span>
      </div>
      <div className={classes.list} role="presentation">
        <List className={classes.listWrapper}>
          {routes.map(route => (
            <RouteListItem key={route.label} {...{ route, onClose }} />
          ))}
        </List>
        <Divider />
        <List className={classes.listWrapper}>
          {userActions.map(({ SVGComponent, label, onClick }) => {
            return (
              <ListItem className={classes.listItem} button key={label} onClick={onClick}>
                <ListItemIcon>
                  {/* @ts-ignore */}
                  <SVGComponent fill="#1E334A" width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary={label} />
              </ListItem>
            );
          })}
        </List>
      </div>
    </SwipeableDrawer>
  );
}
