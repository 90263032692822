import React from 'react';
import { ResponsiveImage, Box } from 'components';
import styled from 'styled-components';
import { rem } from 'polished';
import Typography from '@material-ui/core/Typography';

const EmptyListTitle = styled(Typography)`
  color: ${props => props.theme.colors.S[700]};
  margin-top: ${rem('12px')};
  font-size: ${rem('20px')};
  text-align: center;
  white-space: pre-line;
`;

const HelpLink = styled.a`
  color: ${props => props.theme.colors.Mi[600]};
  margin-top: ${rem('48px')};
  font-size: ${rem('16px')};
  text-align: center;
  text-decoration: underline;
`;

export interface EmptyPlaceholderProps {
  image: string;
  title: string;
  link?: string;
  subtitle?: string;
}

export const EmptyPlaceholder = React.memo(function EmptyPlaceholder({ image, title, link, subtitle }: EmptyPlaceholderProps) {
  return (
    <Box
      display="flex"
      flex={1}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <ResponsiveImage src={image} width={100} height={100} />
      <EmptyListTitle>{title}</EmptyListTitle>
      {link && <HelpLink target={"_blank"} href={link}>{subtitle}</HelpLink>}
    </Box>
  );
});
