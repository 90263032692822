// @ts-nocheck
import React, { useContext, useState, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Modal, Button, Radio, Textarea, Spinner, Box, CounterBox } from 'components';
import { PoHeaderExtract } from './po-header-extract';
import { MR_WITHOUT_STOCK, MR_OTHER_REASON } from 'common/utils/constants';

const Label = styled.span`
  font-size: ${rem('16px')};
  color: ${props => (props.error ? props.theme.colors.Ma[400] : props.theme.colors.S[800])};
`;

const LabelTitle = styled.span`
  font-size: ${rem('20px')};
  font-weight: 600;
`;

const Span = styled.span<{ colorState: string, visible: boolean }>`
    top: 542px;
    left: 382px;
    width: 27px;
    height: 14px;
    text-align: left;
    font-size: ${rem('10px')};
    letter-spacing: 0px;
    color: ${props => props.colorState};
    margin-left: 16px;
    maring-top: 4px;
    position: aboslute;
    display: ${props => props.visible ? 'initial' : 'none'}
`;

let moderationReasons = [];

const Reason = ({ value, label, selected }) => {
  return (
    <Box display="flex" alignItems="center">
      <Radio value={value} checked={selected == value} />
      <Label>{label}</Label>
    </Box>
  );
}

const ReasonsSection = ({ onChange, selected, reasons, error }) => {
  const { t } = useTranslation();

  return (
    <Box marginBottom="17px" marginTop="8px">
      <Label error={error}>{t('summary:modals.change_confirm.reason_section_title')}</Label>
      <RadioGroup onChange={onChange}>
        <Grid container>
          {reasons.map(reason => (
            <Grid item xs={6} key={reason.label}>
              <Reason label={t(reason.label)} value={reason.value} selected={selected} />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </Box>
  );
};

const CounterCharaterOfTextarea = ({ onChange, limitCharater, colorState, error }) => {
  let counter = onChange.value ? onChange.value.length : 0;
  return (
    <Span colorState={ colorState } visible={ !error }>{counter}/{limitCharater}</Span>
  );
};

const TextAreaSection = ({ onChange, disabled, limitCharater, error }) => {
  const { t } = useTranslation();
  return (
    <Box key="textarea">
      <Textarea
        key="textarea"
        onChange={onChange}
        disabled={disabled}
        error={error}
        fullWidth
        placeholder={t('summary:modals.change_confirm.textarea_placeholder')}
        label={t('summary:modals.change_confirm.textarea_placeholder')}
        multiline
        rows={6}
        rowsMax={6}
        inputProps={{
          maxLength: limitCharater
        }}
        textareaCustom={ true }
      />
    </Box>
  );
};

const useValue = initialValue => {
  const [value, setValue] = useState();
  const onChange = event => setValue(event.target.value);
  const clear = () => setValue(initialValue);

  return {
    value,
    onChange,
    clear
  };
};

export function ConfirmChangeModal({ visible, onClose, onConfirm, extract, loading, success, reasons }) {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const comment = useValue('');
  const reason = useValue(undefined);
  const [error, setError] = useState(false);

  moderationReasons = reasons?.map(reason => {
    return { label: 'summary:modals.change_confirm.reasons.' + reason.name, value: reason.id }
  });

  const clearState = () => {
    reason.clear();
    comment.clear();
    setError(false);
  };

  const onClickChange = () => {

    if (reason.value === undefined) {
      setError(true);
      return;
    }

    if (
      parseInt(reason.value) === MR_OTHER_REASON
      && (comment.value === undefined || comment.value.trim() === "")
    ) {
      setError(true);
      return;
    }

    clearState();
    onConfirm({ comment: comment.value, reason: parseInt(reason.value) });
  };

  const onClickClose = () => {
    clearState();
    onClose();
  };

  const getStatusEdit = () => {
    if (error) {
      return '#FF3030';
    }

    if (reason.value === undefined || parseInt(reason.value) === MR_WITHOUT_STOCK) {
      return '#D1D1D1';
    }

    if (parseInt(reason.value) === MR_OTHER_REASON && comment.value === undefined) {
      return '#ACACAC';
    }

    return '#646464';
  };

  useEffect(() => {
    setError(false);
  }, [comment.value, reason.value]);

  return (
    <Modal
      LoadingComponent={<Spinner label={t('summary:modals.change_confirm.loading_message')} />}
      maxWidth={success ? 'sm' : 'lg'}
      onClose={onClickClose}
      loading={loading}
      open={visible}
      fullWidth
    >
      <Modal.Title>
        {success ? (
          <LabelTitle>{t('summary:modals.change_confirm.success.title')}</LabelTitle>
        ) : (
          <>{t('summary:modals.change_confirm.title')}</>
        )}
      </Modal.Title>

      <Modal.Content>
        {success ? (
          <Label>{t('summary:modals.change_confirm.success.message', { po: extract[0].value })}</Label>
        ) : (
          <>
            {' '}
            <PoHeaderExtract extract={extract} />

            <ReasonsSection onChange={reason.onChange} selected={reason.value} error={error && ! reason.value} reasons={moderationReasons} />

            <TextAreaSection
              onChange={comment.onChange}
              disabled={parseInt(reason.value) !== MR_OTHER_REASON}
              limitCharater={160}
              error={error && parseInt(reason.value) === MR_OTHER_REASON}
            />

            <CounterCharaterOfTextarea
              onChange={comment}
              limitCharater={160}
              colorState={getStatusEdit()}
              error={error}
            />

            {(error && parseInt(reason.value) !== MR_OTHER_REASON) && <Span colorState={ getStatusEdit() } visible={ reason.value === undefined }>{t('summary:modals.change_confirm.error.empty_reason')}</Span>}
            {(error) && <Span colorState={ getStatusEdit() } visible={ parseInt(reason.value) === MR_OTHER_REASON && (comment.value === undefined || comment.value.trim() === "") }>{t('summary:modals.change_confirm.error.empty_comment')}</Span>}
          </>
        )}
      </Modal.Content>

      <Modal.Actions>
        {success ? (
          <Button
            colorName={theme.colors.Mi}
            onClick={onClose}
            isConfirmModified="#8BC8EA"
          >
            {t('summary:modals.close_button')}
          </Button>
        ) : (
          <>
            <Button colorName={theme.colors.S[700]} onClick={onClickClose}>
              {t('summary:modals.cancel_button')}
            </Button>
            <Button
              onClick={onClickChange}
              colorName={theme.colors.S}
              isConfirmModified="#00BD63"
            >
              {t('summary:modals.change_confirm.button')}
            </Button>
          </>
        )}
      </Modal.Actions>
    </Modal>
  );
};