import React from 'react';
import { CssBaseline, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ChangePasswordModal, Header, MainSidebar, Snackbar } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import {
  roleIdentifierSelector,
  isLoggedInSelector,
  currentUserSelector,
  visibleChangePasswordSelector,
  isChangingPasswordSelector,
  finishedChangePasswordSelector,
  errorChangePasswordSelector
} from 'common/redux/global/selectors';
import * as GlobalActions from 'common/redux/global/actions';
import { useRouter } from 'common/utils/hooks';
import routes from 'common/router/routes';
import { FullManagement, Management } from 'assets/icons';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    minHeight: '100vh'
  },
  mainContent: {
    flex: 1,
    overflow: 'hidden'
  },
  headerSeparator: {
    height: '48px'
  }
});
export interface LayoutProps {
  children: React.ReactNode;
}

export function Layout({ children }: LayoutProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { query } = useRouter();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const currentUser = useSelector(currentUserSelector);
  const visibleChangePassword = useSelector(visibleChangePasswordSelector);
  const isChangingPassword = useSelector(isChangingPasswordSelector);
  const finishedChangePassword = useSelector(finishedChangePasswordSelector);
  const errorChangePassword = useSelector(errorChangePasswordSelector);

  const manageSession = () => {
    // @ts-ignore
    dispatch(GlobalActions.manageSession({ token: query.token }));
  };


  React.useLayoutEffect(() => {
    manageSession();    
  }, []);

  if (!currentUser || !isLoggedIn) {
    return null;
  }

  const onCloseChangePasswordModal = () => {
    dispatch(GlobalActions.toggleChangePasswordModal());
    setTimeout(() => dispatch(GlobalActions.resetChangePasswordModal()), 1000);
  };

  const changePasswordRequest = (request: any) =>
    // @ts-ignore
    dispatch(GlobalActions.changeUserPasswordRequest(request));

  if (!routes.find(r => r.path === '/self-management')) {
    routes.push({
      label: 'Autogestión',
      icon: Management,
      activeIcon: FullManagement,
      path: '/self-management'
    });
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header />
      <MainSidebar routes={routes} />
      <main className={classes.mainContent}>
        <div className={classes.headerSeparator} />
        {children}
        <Snackbar />
      </main>
      <ChangePasswordModal
        visible={visibleChangePassword}
        onClose={onCloseChangePasswordModal}
        onConfirm={changePasswordRequest}
        loading={isChangingPassword}
        finished={finishedChangePassword}
        error={errorChangePassword}
      />
    </div>
  );
}
