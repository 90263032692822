import React, { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { rem } from 'polished';
import {
  Menu as MuiMenu,
  MenuItem,
  MenuItemProps as MuiMenuItemProps,
  MenuProps as MuiMenuProps,
  Divider as MuiDivider,
  DividerProps as MuiDividerProps,
  ListItemText
} from '@material-ui/core';

export interface MenuProps extends MuiMenuProps {
  children: React.ReactNode;
}

export interface MenuItemProps extends Omit<MuiMenuItemProps, 'onClick'> {
  children?: React.ReactNode;
  onClick?: () => void;
}

const StyledMenu = styled(MuiMenu)``;

const StyledMenuItem = styled(
  React.forwardRef(
    (props: { onClick?: () => void; fontSize?: number; color: string }, ref: any) => (
      <MenuItem ref={ref} {...props} classes={{ selected: 'selected' }} />
    )
  )
)`
  height: ${rem('32px')};
  min-height: ${rem('32px')};
  padding: 0 ${rem('16px')};

  ${props =>
    props.onClick
      ? css`
          :hover {
            background-color: ${props.theme.colors.S['400']};
          }
        `
      : css`
          pointer-events: none;
        `}

  &.selected {
    background-color: ${props => props.theme.colors.S['400']};
  }

  & .MuiListItemText-root > span {
    ${props =>
      props.fontSize &&
      css`
        font-size: ${rem(`${props.fontSize}px`)} !important;
      `}
    ${props =>
      props.color &&
      css`
        color: ${props.color} !important;
      `}
  }
`;

const StyledListItemText = styled(({ ...props }) => (
  <ListItemText {...props} classes={{ primary: 'primary' }} />
))`
  margin: 0;

  & .primary {
    color: ${props => props.theme.colors.S['800']};
    font-size: ${rem('14px')} !important;
  }
`;

const StyledDivider = styled(MuiDivider)`
  margin: ${rem('8px')} ${rem('0px')};
  background-color: ${props => props.theme.colors.S['400']};
`;

export function Menu({ children, ...props }: MenuProps) {
  const theme = useContext(ThemeContext);

  const PaperStyle = {
    boxShadow: theme.shadows[2]
  };

  return (
    <StyledMenu
      PaperProps={{
        style: PaperStyle
      }}
      {...props}
    >
      {children}
    </StyledMenu>
  );
}

const Item = React.forwardRef(({ onClick, children, ...props }: any, ref: any) => (
  <StyledMenuItem ref={ref} onClick={onClick} {...props}>
    <StyledListItemText>{children}</StyledListItemText>
  </StyledMenuItem>
));

function Divider({ ...props }: MuiDividerProps) {
  return <StyledDivider {...props} />;
}

Menu.Item = Item;
Menu.Divider = Divider;
